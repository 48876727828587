import styled from 'styled-components';
import { AiFillCloseCircle } from 'react-icons/ai';

export const BackgroundContainer = styled.div`
  background-color: rgba(176, 176, 176, 0.7);
  position: absolute;
  display: flex;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  cursor: auto;

  @media (max-height: 780px) {
    padding-top: 5rem;
  }
`;

export const CardPerfil = styled.div`
  width: 26rem;
  height: 33rem;
  background-color: var(--secondary);
  border-radius: 5px;
  opacity: 1;
  display: flex;

  @media (max-width: 435px) {
    width: 22rem;
    height: 40rem;
  }
  @media (max-height: 635px) {
    transform: scale(0.8);
    height: 27rem;
  }
`;

export const ContainerInfo = styled.div`
  background-color: var(--blue-card);
  border-radius: 50px 50px 5px 5px;
  height: 60%;
  width: 100%;
  align-self: flex-end;
  padding: 2rem;
  display: flex;
  align-items: flex-end;

  @media (max-width: 435px) {
    border-radius: 40px 40px 5px 5px;
    padding-bottom: 1rem;
  }

  div.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 435px) {
      width: 100%;
    }
    div.information {
      display: flex;
      align-items: center;
      width: 50%;
      height: auto;
      justify-content: flex-start;
      flex-direction: row;
      margin-top: 0.5rem;
      @media (max-width: 435px) {
        width: 100%;
      }
      div.icon {
        width: 30px;
      }

      div.text {
        width: 70%;
        color: var(--light-gray);
        line-height: 2rem;
        font-size: 1.1rem;
        margin-left: 1rem;
        word-wrap: normal;
        @media (max-width: 435px) {
          width: 80%;
        }
      }
    }
  }
`;

export const Image = styled.div`
  background-color: white;
  border-radius: 15px;
  width: 20rem;
  height: 18rem;
  position: absolute;
  top: calc(50% - 14rem);
  left: calc(50% - 10rem);

  @media (max-width: 435px) {
    width: 18rem;
    top: calc(50% - 17rem);
    left: calc(50% - 9rem);
  }

  @media (max-height: 780px) {
    top: calc(50% - 12rem);
  }

  @media (max-height: 635px) {
    height: 13rem;
    top: calc(50% - 10rem);
  }
`;

export const CloseButton = styled(AiFillCloseCircle)`
  fill: var(--danger);
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  top: calc(50% - 18rem);
  right: calc(50% - 15rem);

  @media (max-width: 435px) {
    position: fixed;
    right: calc(50% - 13rem);
  }

  @media (max-width: 370px) {
    position: fixed;
    top: calc(50% - 19rem);
    right: calc(50% - 10rem);
  }

  @media (max-height: 780px) {
    top: calc(50% - 15rem);
  }
`;
