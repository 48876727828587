import styled from 'styled-components';
import { AiFillCloseCircle } from 'react-icons/ai';

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  background-color: var(--blue-card);
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 17rem;
  height: 100%;
`;

export const ContainerGifts = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  flex-wrap: wrap;
`;

export const CloseButton = styled(AiFillCloseCircle)`
  fill: var(--danger);
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const Gift = styled.div`
  padding: 0 1rem;
  border-radius: 5px;
  margin: 0 1rem;
  background-color: var(--dark-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: all 400ms;

  :hover {
    background-color: var(--light-gray);
  }

  span {
    color: var(--graphite);
    font-size: 1.3rem;
    margin: 1rem 0;
  }
`;

export const ImageGift = styled.img`
  width: 5rem;
  height: 5rem;
`;

export const ErrorLoadGifts = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
    height: 100px;
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  span {
    font-size: 1.2rem;
    color: var(--light-gray);
  }
`;
