import styled from 'styled-components';
import { MdCallEnd } from 'react-icons/md';
import { FiGift } from 'react-icons/fi';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: var(--secondary);
  overflow: hidden;
`;

export const ContainerVideo = styled.div`
  width: 100%;
  height: ${props => props.theme.height};
`;

export const ButtonCloseCall = styled(MdCallEnd)`
  fill: var(--remove);
  width: 5rem;
  height: 5rem;
  cursor: pointer;
`;

export const ContainerMyCam = styled.video`
  width: 23rem;
  height: 13rem;
  background-color: var(--dark-gray);
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 1300px) {
    width: 20rem;
    height: 11.2rem;
  }
  @media (max-width: 600px) {
    width: 15rem;
    height: 8.4rem;
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
`;

export const GiftsButton = styled(FiGift)`
  fill: var(--primary);
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  margin-right: 3rem;
`;

export const ContainerButtons = styled.div`
  display: flex;
  bottom: ${props => props.theme.bottom};
  width: 100%;
  justify-content: center;
  position: absolute;
`;

export const UserBalance = styled.button`
  background-color: transparent;
  border: none;
  font-size: 2rem;
  font-weight: 400;
  color: var(--primary);
  margin-top: 5rem;
  margin-left: 5rem;
  cursor: pointer;

  @media (max-width: 500px) {
    margin-top: 9rem;
  }
`;

export const LowBalanceWarning = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${props => props.theme.display};
  div {
    width: 25rem;
    padding: 2rem;
    height: 10rem;
    background-color: var(--light-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: absolute;

    span {
      font-size: 1.3rem;
      color: var(--graphite);
      text-align: center;
    }
  }
`;

export const OverlayBuyCoins = styled.div`
  width: 30rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-gray);
  margin-top: 2rem;
  margin-left: 7rem;
  border-radius: 10px;
  display: ${props => props.theme.display};

  @media (max-width: 600px) {
    margin-left: 4rem;
    width: 25rem;
  }

  @media (max-width: 430px) {
    margin-left: 2rem;
  }
  @media (max-width: 400px) {
    padding: 1rem;
    margin-left: 0;
    width: 100%;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: var(--graphite);
    text-align: center;

    @media (max-width: 400px) {
      font-size: 2rem;
    }
  }

  div.container-input-coins {
    width: 8rem;
    margin-bottom: 1rem;
    color: var(--graphite);

    input {
      border-bottom: solid 2px var(--graphite);
      color: var(--graphite);
      font-size: 1.6rem;
    }
  }

  span {
    font-size: 1.2rem;
    color: var(--graphite);
  }

  p {
    font-size: 1.5rem;
    color: var(--graphite);
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  select {
    width: 20rem;
    border: none;
    background-color: transparent;
    padding: 0.5rem 1rem;
    border-bottom: solid 2px var(--graphite);
    font-size: 1.3rem;
  }

  div.container-buttons {
    width: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;

    button {
      margin-bottom: 1rem;
      span {
        color: var(--light-gray);
      }
    }
  }
`;
