import { useState, useEffect, useCallback } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { IoIosReturnLeft } from 'react-icons/io';
import { useToast } from 'hooks/toast';
import { personalCharacteristicsDataValidator } from 'validators/SignUpValidators/personalCharacteristicsData';
import getValidationErrors from 'utils/getValidationErrors';
import { colors } from 'styles/colors';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import api from 'services/api';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setPersonalCharacteristicsData } from 'store/slices/signupSlice';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  InputContainer,
  Select,
  CancelButton,
  Options,
  DivRadio,
  ContainerMain,
  Main,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUserData {
  hairColor: string;
  eyesColor: string;
  height: string;
  isSmoker: boolean;
  doDrink: boolean;
}
interface IColor {
  _id: string;
  color: string;
  hex: string;
}
export function PersonalCharacteristicsData(): JSX.Element {
  const { addError, addSuccess } = useToast();
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const [userHairColor, setUserHairColor] = useState<Array<IColor>>([]);
  const [userEyeColor, setUserEyeColor] = useState<Array<IColor>>([]);
  const [hairColorOptions, setHairColorOptions] = useState<Array<JSX.Element>>(
    [],
  );
  const [eyeColorOptions, setEyeColorOptions] = useState<Array<JSX.Element>>(
    [],
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  const signup = useAppSelector(state => state.signup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    async function getColors() {
      const responseEyes = await api.get<Array<IColor>>(
        '/user/metadata/eyes-colors',
      );
      const responseHair = await api.get<Array<IColor>>(
        '/user/metadata/hair-colors',
      );
      setUserData({
        hairColor: signup.metadata.hairColor,
        eyesColor: signup.metadata.eyesColor,
        height: signup.metadata.height,
        isSmoker: signup.metadata.isSmoker,
        doDrink: signup.metadata.doDrink,
      });

      setUserEyeColor(responseEyes.data);

      setUserHairColor(responseHair.data);
    }
    getColors();
  }, [signup]);

  useEffect(() => {
    const arrayEyeColors = [];
    arrayEyeColors.push(
      <option value="" disabled selected>
        {messages[key].eyeColor}
      </option>,
    );
    userEyeColor.map(obj => {
      return arrayEyeColors.push(<option value={obj._id}>{obj.color}</option>);
    });
    setEyeColorOptions(arrayEyeColors);

    const arrayHairColors = [];
    arrayHairColors.push(
      <option value="" disabled selected>
        {messages[key].hairColor}
      </option>,
    );
    userHairColor.map(obj => {
      return arrayHairColors.push(
        <>
          <option value={obj._id}>{obj.color}</option>,
        </>,
      );
    });
    setHairColorOptions(arrayHairColors);
  }, [userEyeColor, userHairColor, key]);

  const handleSandForm = useCallback(async () => {
    try {
      setErrors({});
      const { height } = userData;
      await personalCharacteristicsDataValidator.validate(
        { height },
        { abortEarly: false },
      );
      if (userData.eyesColor && userData.hairColor) {
        addSuccess(`${messages[key].successMessage}`);
        dispatch(setPersonalCharacteristicsData({ ...userData }));
        history.push('redes-sociais');
      } else {
        addError(`${messages[key].errorMessage}`);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
      }

      addError(`${messages[key].errorMessage}`);
    }
  }, [userData, addSuccess, addError, history, key, dispatch]);
  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <InputContainer>
              <Select
                name="hairColor"
                placeholder={messages[key].hairColor}
                theme={{
                  border:
                    userData.hairColor === ''
                      ? colors.danger
                      : colors.lightGray,
                }}
                onChange={e =>
                  setUserData({ ...userData, hairColor: e.target.value })
                }
              >
                {hairColorOptions}
              </Select>
              <Select
                name="eyesColor"
                placeholder={messages[key].eyeColor}
                theme={{
                  border:
                    userData.eyesColor === ''
                      ? colors.danger
                      : colors.lightGray,
                }}
                onChange={e =>
                  setUserData({ ...userData, eyesColor: e.target.value })
                }
              >
                {eyeColorOptions}
              </Select>
              <Input
                name="height"
                placeholder={messages[key].height}
                value={userData.height}
                errors={errors}
                onChange={e =>
                  setUserData({ ...userData, height: e.target.value })
                }
              />
            </InputContainer>
            <DivRadio>
              <div>
                <Options>
                  <label>{messages[key].smoker}</label>
                  <input
                    type="radio"
                    value="true"
                    name="smoker"
                    checked={userData.isSmoker === true}
                    onClick={() => setUserData({ ...userData, isSmoker: true })}
                  />
                  <span>{messages[key].yes}</span>
                  <input
                    type="radio"
                    value="false"
                    name="smoker"
                    checked={userData.isSmoker === false}
                    onClick={() =>
                      setUserData({ ...userData, isSmoker: false })
                    }
                  />
                  <span>{messages[key].no}</span>
                </Options>
              </div>
              <div>
                <Options>
                  <label>{messages[key].drink}</label>
                  <input
                    type="radio"
                    value="true"
                    name="drink"
                    checked={userData.doDrink === true}
                    onClick={() => setUserData({ ...userData, doDrink: true })}
                  />
                  <span>{messages[key].yes}</span>
                  <input
                    type="radio"
                    value="false"
                    name="drink"
                    checked={userData.doDrink === false}
                    onClick={() => setUserData({ ...userData, doDrink: false })}
                  />
                  <span>{messages[key].no}</span>
                </Options>
              </div>
            </DivRadio>
            <Button
              backgroundColor="primary"
              size="regular"
              onClick={() => handleSandForm()}
            >
              {messages[key].proceed}
            </Button>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
