import { useEffect, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { colors } from 'styles/colors';
import { useToast } from 'hooks/toast';
import { IModalDisplay } from 'interfaces/ModalDisplay';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  ContainerModal,
  TitleModal,
  ContentModal,
  ModalButton,
  ButtonReturn,
} from '../styles';

import { ContainerInputRadio, TextArea } from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ModalProps {
  closeModal: (prop: keyof IModalDisplay, action: 'open' | 'close') => void;
}

export function Ocurrence({ closeModal }: ModalProps): JSX.Element {
  const { addSuccess } = useToast();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleCloseModal() {
    closeModal('ocurrence', 'close');
  }

  function handleConfirmChanges() {
    addSuccess(`${messages[key].message}`);
  }

  return (
    <ContainerModal>
      <TitleModal>
        <FiAlertCircle fill={colors.darkGray} size={50} />
        <h2>{messages[key].title}</h2>
        <ButtonReturn size={40} onClick={() => handleCloseModal()} />
      </TitleModal>
      <ContentModal>
        <ContainerInputRadio>
          <div>
            <label htmlFor="financeiro">{messages[key].financial}</label>
            <input
              type="radio"
              name="radio"
              value="financeiro"
              defaultChecked
            />
          </div>
          <div>
            <label htmlFor="tecnico">{messages[key].technical}</label>
            <input type="radio" name="radio" value="tecnico" />
          </div>
          <div>
            <label htmlFor="outros">{messages[key].others}</label>
            <input type="radio" name="radio" value="outros" />
          </div>
        </ContainerInputRadio>
        <TextArea placeholder={messages[key].note} />
        <ModalButton size="regular" onClick={() => handleConfirmChanges()}>
          {messages[key].concluded}
        </ModalButton>
      </ContentModal>
    </ContainerModal>
  );
}
