import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  financial: string;
  technical: string;
  others: string;
  note: string;
  concluded: string;
  message: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Ocorrências',
    financial: 'Financeiro',
    technical: 'Técnico',
    others: 'Outros',
    note: 'Informe aqui seu problema',
    concluded: 'Concluído',
    message: 'Saved changes',
  },
  'en-US': {
    title: 'Ocurrences',
    financial: 'Financial',
    technical: 'Technical',
    others: 'Others',
    note: 'Specify here the problem',
    concluded: 'Concluded',
    message: 'Saved changes',
  },
};
