import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)``;

export const Title = styled.span`
  color: var(--primary);
  font-size: 2rem;
  font-weight: 300;

  @media (max-width: 650px) {
    font-size: 1.8rem;
  }
`;

export const Subtitle = styled.h2`
  color: var(--primary);
  font-size: 1.5rem;
  font-weight: 300;
  margin: 2rem 0;
  align-self: flex-start;

  @media (max-width: 650px) {
    font-size: 1.5rem;
  }
`;

export const Line = styled.hr`
  border-color: var(--primary);
`;

export const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
`;

export const Select = styled.select`
  appearance: none;
  position: relative;
  margin-bottom: 1rem;
  border: none;
  width: 15rem;
  height: 2.64rem;
  color: var(--light-gray);
  border-bottom: 0.0625rem solid var(--light-gray);
  background-color: transparent;
  padding-left: 0.5rem;
  font-size: 1.2rem;

  ::placeholder {
    color: var(--light-gray);
  }

  option {
    color: var(--graphite);
    background-color: var(--light-gray);

    ::selection {
      background-color: black;
    }
  }
`;

export const ContainerOption = styled.div`
  display: flex;
  align-items: center;

  input {
    margin: 0 3rem;
    width: 1rem;
    height: 1rem;

    @media (max-width: 400px) {
      margin: 0 1.5rem;
    }
  }
`;

export const ContainerPlans = styled.div`
  background-color: var(--blue-card);
  border: solid 1px var(--light-gray);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 20rem;
  margin-top: 2rem;
  padding: 2rem;
  align-items: center;
  @media (max-width: 400px) {
    padding: 1rem;
    width: 15rem;
  }

  p {
    color: var(--light-gray);
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
  }

  span.value {
    color: var(--light-gray);
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  span.content {
    color: var(--light-gray);
    font-size: 1rem;
    line-height: 1.5rem;
    align-self: flex-start;
  }
  span.benefits {
    color: var(--light-gray);
    font-size: 1.2rem;
    line-height: 1.8rem;
    align-self: flex-start;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: var(--light-gray);
  margin: 3rem 0;
`;

export const ContainerInput = styled.div`
  width: 17rem;
`;

export const ContainerButton = styled.div`
  width: 12rem;
  margin: 1.5rem 0;
`;
