/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ICreateEventHandler } from '../interfaces/handler-type';

export const createListenEvent = <T>({
  socket,
  eventName,
  handler,
}: ICreateEventHandler<T>) => {
  socket.on(eventName, data => {
    handler(data, { socket, eventName });
  });
};
