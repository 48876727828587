import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  proceed: string;
  cancel: string;
  successMessage: string;
  errorMessage: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    proceed: 'Prosseguir',
    cancel: 'Cancelar',
    errorMessage: 'Não foi possível validar os dados',
    successMessage: 'Dados validados',
  },
  'en-US': {
    proceed: 'Proceed',
    cancel: 'Cancel',
    errorMessage: 'It was not possible to validate the fields',
    successMessage: 'Validated fields',
  },
};
