import styled from 'styled-components';
import { Button } from 'components/Button';
import Background from 'pages/Auth/_Background';

export const ContainerMain = styled(Background)``;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.div`
  font-size: 17px;
  color: var(--white);
  margin: 5px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
`;

export const TextArea = styled.textarea`
  padding: 10px;
  margin: 10px 0;
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  height: ${props => props.theme.height};
  border: ${props => props.theme.border};
`;
export const ButtonProceed = styled(Button)`
  margin-top: 20px;
`;

export const CancelButton = styled(Button)`
  margin-top: 15px;
`;
