import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Logo } from 'components/Logo';
import { useAuth } from 'hooks/auth';
import getValidationErrors from 'utils/getValidationErrors';
import { signInValidator } from 'validators/signIn';
import { useToast } from 'hooks/toast';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { usePageSettings } from 'hooks/pagesSettings';

import { EarthRotation } from 'components/EarthRotation';
import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';
import {
  Container,
  Content,
  LoginContent,
  SoonContent,
  Slogan,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function SignIn(): JSX.Element {
  const history = useHistory();
  const { addError } = useToast();
  const { language } = usePageSettings();

  const { signIn, loading } = useAuth();
  const [errors, setErrors] = useState({});

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  const handleSignIn = useCallback(async () => {
    try {
      setErrors({});

      await signInValidator.validate(
        { login, password },
        { abortEarly: false },
      );

      signIn({ login, password });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }

      addError('Não foi possível realizar o login');
    }
  }, [login, password, signIn, addError]);

  return (
    <Container>
      <Content>
        <SoonContent>
          <div className="earth-logo">
            <EarthRotation />
            <Logo size={500} />
          </div>
          <Slogan>{messages[key].slogan}</Slogan>
        </SoonContent>
        <ContainerSignUp>
          <LoginContent>
            <Input
              name="login"
              placeholder={messages[key].email}
              errors={errors}
              value={login}
              onChange={e => setLogin(e.target.value)}
            />
            <Input
              placeholder={messages[key].password}
              name="password"
              type="password"
              errors={errors}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <div className="buttons-container">
              <Button
                backgroundColor="primary"
                size="regular"
                onClick={handleSignIn}
                loading={loading}
              >
                {messages[key].buttonSignIn}
              </Button>
              <Button
                backgroundColor="secondary"
                size="regular"
                onClick={() => {
                  history.push('/registrar/principal');
                }}
              >
                {messages[key].buttonSignUp}
              </Button>
              <span style={{ color: '#FFF' }}>
                {messages[key].forgotPassword}
              </span>
            </div>
          </LoginContent>
        </ContainerSignUp>
      </Content>
    </Container>
  );
}
