import styled from 'styled-components';
import { Logo } from 'components/Logo';

export const Container = styled.div`
  width: 100%;
  height: 6rem;
  background: var(--graphite);
  display: flex;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  justify-content: space-between;
  border-bottom: solid 0.1rem var(--primary);
  position: fixed;
  z-index: 9;
  div.button {
    border-radius: 50%;
    width: 5rem;
  }
  div.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 3rem;
    width: 5rem;

    @media (max-width: 940px) {
      width: 2rem;
      padding: 2rem;
      margin-left: 2rem;
    }
  }
  div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-around;

    :first-child {
      justify-content: space-between;

      @media (max-width: 550px) {
        justify-content: flex-start;
      }
    }

    :last-child {
      @media (max-width: 940px) {
        justify-content: flex-end;
      }
    }
  }
`;

export const ContainerIcons = styled.div`
  width: 8rem;
  max-width: 8rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 940px) {
    display: none;
    position: absolute;
    left: -3000px;
  }
`;

export const ContainerImage = styled.div`
  width: 3rem;
  height: 3rem;
  max-width: 3rem;
  max-height: 3rem;
  border: solid 0.105rem var(--primary);
  background-color: var(--light-gray);
  border-radius: 50%;
  pointer-events: none;
  margin: 0 1rem;

  @media (max-width: 940px) {
    display: block;
    pointer-events: all;
    margin: 0;
  }
`;

export const Balance = styled.p`
  color: var(--primary);
  font-size: 1rem;
  cursor: context-menu;
  @media (max-width: 940px) {
    display: none;
  }
`;

export const ContainerLogoBig = styled.div`
  margin-left: 1rem;
  display: block;
  height: 50rem;

  @media (max-width: 550px) {
    position: absolute;
    visibility: hidden;
    display: none;
    left: -3000px;
  }
`;

export const ContainerLogoSmall = styled.img`
  display: none;
  width: 4rem;

  @media (max-width: 550px) {
    display: block;
  }
`;

export const LogoBig = styled(Logo).attrs({ size: 300 })`
  @media (max-width: 550px) {
    display: none;
    visibility: hidden;
  }
`;

export const HiddenMenu = styled.ul`
  width: 10rem;
  background-color: var(--graphite);
  border: solid 0.0625rem var(--primary);
  border-radius: 0.5rem;
  position: absolute;
  margin-top: 16rem;
  right: 4rem;
  display: ${props => props.theme.display};

  li {
    width: 100%;
    height: 3rem;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    font-size: 1.5rem;

    :last-child {
      font-size: 1rem;
    }
  }
`;
