import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  coverPhrase: string;
  change: string;
  remove: string;
  requestReview: string;
  photos: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Editar perfil',
    coverPhrase: 'Frase de apresentação',
    change: 'Trocar',
    remove: 'Remover',
    requestReview: 'Solicitar análise',
    photos: 'Fotos',
  },
  'en-US': {
    title: 'Edit profile',
    coverPhrase: 'Cover phrase',
    change: 'Change',
    remove: 'Remove',
    requestReview: 'Request review',
    photos: 'Photos',
  },
};
