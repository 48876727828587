import { useState, useEffect } from 'react';
import { useMenu } from 'hooks/menu';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdChatboxes, IoMdNotifications } from 'react-icons/io';
import { FaUsers } from 'react-icons/fa';
import { colors } from 'styles/colors';
import smallLogo from 'assets/images/img/logo.png';
import { useAuth } from 'hooks/auth';
import { Button } from 'components/Button';
import { usePageSettings } from 'hooks/pagesSettings';
import { useHistory } from 'react-router-dom';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  ContainerIcons,
  ContainerImage,
  Balance,
  ContainerLogoBig,
  ContainerLogoSmall,
  LogoBig,
  HiddenMenu,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function ToolBar(): JSX.Element {
  const { handleDisplayDrawer } = useMenu();
  const [balance, setBalance] = useState('');
  const [hiddenMenu, setHiddenMenu] = useState(false);
  const { signOut } = useAuth();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const history = useHistory();

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setBalance('500,00');
  }, []);

  return (
    <Container>
      <div>
        <GiHamburgerMenu
          fill={colors.salmon}
          size={30}
          cursor="pointer"
          onClick={() => handleDisplayDrawer()}
        />
        <ContainerLogoBig>
          <LogoBig />
        </ContainerLogoBig>
        <ContainerLogoSmall src={smallLogo} />
      </div>
      <div>
        <ContainerIcons>
          <IoMdChatboxes
            fill={colors.salmon}
            size={30}
            cursor="pointer"
            onClick={() => history.push('/mensagens')}
          />
          <FaUsers
            fill={colors.salmon}
            size={30}
            cursor="pointer"
            onClick={() => history.push('/contatos')}
          />
          <IoMdNotifications fill={colors.salmon} size={30} cursor="pointer" />
        </ContainerIcons>
        <ContainerImage onClick={() => setHiddenMenu(!hiddenMenu)} />
        <div className="rightContainer">
          <div className="button">
            <Button
              backgroundColor="primary"
              name="logout"
              size="small"
              onClick={() => signOut()}
            >
              {messages[key].signOut}
            </Button>
          </div>
          <Balance>
            {messages[key].currency}
            {balance}
          </Balance>
        </div>
        <HiddenMenu theme={{ display: hiddenMenu ? '' : 'none' }}>
          <li>
            <IoMdChatboxes
              fill={colors.salmon}
              size={30}
              cursor="pointer"
              onClick={() => history.push('/mensagens')}
            />
          </li>
          <li>
            <FaUsers fill={colors.salmon} size={30} cursor="pointer" />
          </li>
          <li>
            <IoMdNotifications
              fill={colors.salmon}
              size={30}
              cursor="pointer"
            />
          </li>
          <li>
            {messages[key].currency}
            {balance}
          </li>
        </HiddenMenu>
      </div>
    </Container>
  );
}
