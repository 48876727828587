import { useState, useEffect } from 'react';
import card from 'assets/images/img/baralho.png';
import { Button } from 'components/Button';
import { IoIosArrowDropright, IoIosArrowDropleft } from 'react-icons/io';
import { Animated } from 'react-animated-css';
import { usePageSettings } from 'hooks/pagesSettings';
import { useToast } from 'hooks/toast';

import { IMessage } from 'interfaces/pagesSettings';
import { colors } from 'styles/colors';
import api from 'services/api';
import { messages, LanguageTranslated } from './translate';

import {
  BabyImage,
  Card,
  CheapCount,
  Container,
  ContainerButton,
  ContainerCards,
  ContainerFilter,
  ContainerFilterInput,
  ContainerTextInfo,
  Content,
  Description,
  FilterButton,
  FilterInput,
  Line,
  Options,
  Select,
  Title,
  ContainerInputRadio,
  ContainerInputRange,
  InputRange,
  ContainerInput,
  ContainerButtonsAfterViewPhoto,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IBabyInfo {
  age: string;
  city: string;
  country: string;
  state: string;
  description: string;
  name: string;
}

interface IFilterOptions {
  age: string;
  height: string;
  eyeColor: string;
  hairColor: string;
  country: string;
  state: string;
  city: string;
  smoker: boolean;
  drink: boolean;
  childs: boolean;
  distance: string | number;
  sexualPreference: 'hetero' | 'bissexual';
}

interface IRequestCountry {
  id: string;
  name: string;
  description: string;
}

interface IRequestState {
  id: string;
  name: string;
  description: string;
  latitude: string;
  longitude: string;
  uf: string;
  countryId: string;
}

interface IRequestCity {
  id: string;
  name: string;
  stateId: string;
}

export function Cheap(): JSX.Element {
  const [babys, setBabys] = useState<Array<IBabyInfo>>([] as Array<IBabyInfo>);
  const [babyIndex, setBabyIndex] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [animationOut, setAnimationOut] = useState('FadeInLeft');
  const [showImage, setShowImage] = useState(false);
  const [userHairColor, setUserHairColor] = useState<Array<string>>([]);
  const [userEyeColor, setUserEyeColor] = useState<Array<string>>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showOptionsAfterView, setShowOptionsAfterView] = useState(false);
  const [countries, setCountries] = useState<Array<IRequestCountry>>(
    [] as Array<IRequestCountry>,
  );
  const [states, setStates] = useState<Array<IRequestState>>(
    [] as Array<IRequestState>,
  );
  const [cities, setCities] = useState<Array<IRequestCity>>(
    [] as Array<IRequestCity>,
  );
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>(
    {} as IFilterOptions,
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const { addSuccess, addError } = useToast();

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setBabys([
      {
        name: '1',
        city: 'Barbacena',
        country: 'Brasil',
        state: 'MG',
        description: 'Minha descrição na plataforma',
        age: '19',
      },
      {
        name: '2',
        city: 'São João del Rei',
        country: 'Brasil',
        state: 'MG',
        description: 'Minha descrição de Baby',
        age: '23',
      },
      {
        name: '3',
        city: 'Juiz de Fora',
        country: 'Brasil',
        state: 'MG',
        description: 'Blá blá blá blá',
        age: '30',
      },
      {
        name: '4',
        city: 'Belo Horizonte',
        country: 'Brasil',
        state: 'MG',
        description: 'Alguma descrição aqui',
        age: '21',
      },
    ]);

    setFilterOptions({
      age: '18',
      height: '170',
      childs: false,
      smoker: false,
      drink: false,
      sexualPreference: 'hetero',
      distance: 10,
      country: 'Brasil',
      state: 'Minas Gerais',
      city: 'Juiz de Fora',
      eyeColor: 'Preto',
      hairColor: 'Preto',
    });

    setUserEyeColor([
      'Cor dos olhos',
      'Castanho claro',
      'Castanho escuro',
      'Preto',
      'Azul',
      'Verde',
    ]);

    setUserHairColor([
      'Cor do cabelo',
      'Castanho claro',
      'Castanho escuro',
      'Preto',
      'Loiro',
      'Ruivo',
      'Branco',
    ]);
  }, []);

  useEffect(() => {
    async function getCountries() {
      try {
        const response = await api.get('/geographical/country');
        if (response) setCountries(response.data.result);
      } catch (error) {
        addError('Não foi possível carregar os países');
      }
    }
    getCountries();
  }, [addError]);

  useEffect(() => {
    async function handleGetStates(countryId: string) {
      try {
        const response = await api.get(
          `/geographical/state/${countryId}/states`,
        );
        if (response) setStates(response.data.result);
      } catch (error) {
        setStates([]);
      }
    }
    if (filterOptions.country?.length) handleGetStates(filterOptions.country);
  }, [filterOptions.country, addError]);

  useEffect(() => {
    async function handleGetCities(countryId: string, stateId: string) {
      try {
        const response = await api.get(
          `/geographical/city/${countryId}/states/${stateId}/city`,
        );
        if (response) setCities(response.data.result);
      } catch (error) {
        setCities([]);
      }
    }
    if (filterOptions.country?.length && filterOptions.state?.length)
      handleGetCities(filterOptions.country, filterOptions.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions.state, addError]);

  function handleViewPhoto() {
    setShowImage(true);
    setTimeout(() => {
      setShowImage(false);
      setShowOptionsAfterView(true);
    }, 5000);
  }

  function handleSlideCards(direction: 'left' | 'right') {
    setShowOptionsAfterView(false);
    if (direction === 'left') {
      if (babyIndex > 0) {
        setBabyIndex(babyIndex - 1);
        setAnimate(true);
        setAnimationOut('left');
        setTimeout(() => setAnimate(false), 200);
      }
    }
    if (direction === 'right') {
      if (babyIndex < babys.length - 1) {
        setBabyIndex(babyIndex + 1);
        setAnimate(true);
        setAnimationOut('right');
        setTimeout(() => setAnimate(false), 200);
      }
    }
  }

  return (
    <Container>
      <Title>{messages[key].title}</Title>
      <Line />
      {babys[babyIndex] && (
        <Content>
          <FilterButton size={40} onClick={() => setShowFilter(!showFilter)} />

          <CheapCount>
            {babyIndex + 1} {messages[key].slideCounter} {babys.length}
          </CheapCount>
          <ContainerCards>
            <IoIosArrowDropleft
              size={40}
              fill={colors.salmon}
              cursor="pointer"
              onClick={() => handleSlideCards('left')}
            />
            {showImage && (
              <Animated
                animationIn="fadeOut"
                animationOut="fadeIn"
                animationInDuration={100}
                animationOutDuration={1000}
                isVisible={false}
                style={{ zIndex: 1 }}
              >
                <BabyImage>
                  <h1>{babys[babyIndex].name}</h1>
                </BabyImage>
              </Animated>
            )}
            {!showImage && (
              <Animated
                animationIn="fadeOut"
                animationOut={
                  animationOut === 'left' ? 'fadeInLeft' : 'fadeInRight'
                }
                animationInDuration={400}
                animationOutDuration={800}
                isVisible={animate}
              >
                <Card src={card} alt="baralho" />
              </Animated>
            )}
            <IoIosArrowDropright
              size={40}
              fill={colors.salmon}
              cursor="pointer"
              onClick={() => handleSlideCards('right')}
            />
          </ContainerCards>
          <ContainerTextInfo>
            <p>
              {babys[babyIndex].city} - {babys[babyIndex].state} -
              {babys[babyIndex].country}
            </p>
            <p>
              {babys[babyIndex].age} {messages[key].age}
            </p>
          </ContainerTextInfo>

          <Description>{babys[babyIndex].description}</Description>
          {!showOptionsAfterView && (
            <ContainerButton>
              <Button onClick={() => handleViewPhoto()}>
                {messages[key].view}
              </Button>
            </ContainerButton>
          )}
          {showOptionsAfterView && (
            <ContainerButtonsAfterViewPhoto>
              <div>
                <Button
                  onClick={() => addSuccess(`${messages[key].babyFixed}`)}
                >
                  {messages[key].fixBaby}
                </Button>
              </div>
              <div>
                <Button onClick={() => handleViewPhoto()}>
                  {messages[key].viewAgain}
                </Button>
              </div>
            </ContainerButtonsAfterViewPhoto>
          )}
          {showFilter && (
            <ContainerFilter>
              <ContainerInput>
                <span>{messages[key].country} </span>
                <Select
                  value={filterOptions.country}
                  onChange={e =>
                    setFilterOptions({
                      ...filterOptions,
                      country: e.target.value,
                    })
                  }
                >
                  <option value="default">{messages[key].selectCountry}</option>
                  {countries.map(obj => {
                    return (
                      <option key={obj.id} value={obj.id}>
                        {obj.name}
                      </option>
                    );
                  })}
                </Select>
              </ContainerInput>
              <ContainerInput>
                <span>{messages[key].state}</span>
                <Select
                  value={filterOptions.state}
                  onChange={e =>
                    setFilterOptions({
                      ...filterOptions,
                      state: e.target.value,
                    })
                  }
                >
                  <option value="default">{messages[key].selectState}</option>
                  {states.map(obj => {
                    return (
                      <option key={obj.id} value={obj.id}>
                        {obj.name}
                      </option>
                    );
                  })}
                </Select>
              </ContainerInput>
              <ContainerInput>
                <span>{messages[key].city}</span>
                <Select
                  value={filterOptions.city}
                  onChange={e =>
                    setFilterOptions({
                      ...filterOptions,
                      city: e.target.value,
                    })
                  }
                >
                  <option value="default">{messages[key].selectCity}</option>
                  {cities.map(obj => {
                    return (
                      <option key={obj.id} value={obj.id}>
                        {obj.name}
                      </option>
                    );
                  })}
                </Select>
              </ContainerInput>
              <ContainerInput>
                <span>{messages[key].hairColor}</span>
                <Select
                  value={filterOptions.hairColor}
                  onChange={e =>
                    setFilterOptions({
                      ...filterOptions,
                      hairColor: e.target.value,
                    })
                  }
                >
                  {userHairColor.map(obj => {
                    return (
                      <option key={obj} value={obj}>
                        {obj}
                      </option>
                    );
                  })}
                </Select>
              </ContainerInput>
              <ContainerInput>
                <span>{messages[key].eyeColor}</span>
                <Select
                  value={filterOptions.eyeColor}
                  onChange={e =>
                    setFilterOptions({
                      ...filterOptions,
                      eyeColor: e.target.value,
                    })
                  }
                >
                  {userEyeColor.map(obj => {
                    return (
                      <option key={obj} value={obj}>
                        {obj}
                      </option>
                    );
                  })}
                </Select>
              </ContainerInput>
              <ContainerFilterInput>
                <ContainerInput>
                  <span>{messages[key].ageInput}</span>
                  <FilterInput
                    type="number"
                    min="18"
                    name="age"
                    placeholder="Idade"
                    value={filterOptions.age}
                    onChange={e =>
                      setFilterOptions({
                        ...filterOptions,
                        age: e.target.value,
                      })
                    }
                  />
                </ContainerInput>
              </ContainerFilterInput>
              <ContainerFilterInput>
                <ContainerInput>
                  <span>{messages[key].height}</span>
                  <FilterInput
                    name="height"
                    placeholder="Altura (cm)"
                    value={filterOptions.height}
                    onChange={e =>
                      setFilterOptions({
                        ...filterOptions,
                        height: e.target.value,
                      })
                    }
                  />
                </ContainerInput>
              </ContainerFilterInput>
              <ContainerInputRadio>
                <div>
                  <Options>
                    <label>{messages[key].smoker}</label>
                    <input type="radio" value="yes" name="smoker" />
                    <label className="option" htmlFor="smoker">
                      {messages[key].yes}
                    </label>
                    <input
                      type="radio"
                      value="no"
                      name="smoker"
                      defaultChecked
                    />
                    <label className="option" htmlFor="smoker">
                      {messages[key].no}
                    </label>
                  </Options>
                </div>
                <div>
                  <Options>
                    <label>{messages[key].drink}</label>
                    <input type="radio" value="yes" name="drink" />
                    <label className="option" htmlFor="drink">
                      {messages[key].yes}
                    </label>
                    <input
                      type="radio"
                      value="no"
                      name="drink"
                      defaultChecked
                    />
                    <label className="option" htmlFor="drink">
                      {messages[key].no}
                    </label>
                  </Options>
                </div>
                <div>
                  <Options>
                    <label>{messages[key].child}</label>
                    <input type="radio" value="yes" name="childs" />
                    <label className="option" htmlFor="childs">
                      {messages[key].yes}
                    </label>
                    <input
                      type="radio"
                      value="no"
                      name="childs"
                      defaultChecked
                    />
                    <label className="option" htmlFor="childs">
                      {messages[key].no}
                    </label>
                  </Options>
                </div>
              </ContainerInputRadio>
              <ContainerInputRange>
                <div>
                  <span>0km</span>
                  <div>
                    <InputRange
                      name="distance"
                      type="range"
                      min="0"
                      max="100"
                      value={filterOptions.distance}
                      onChange={e =>
                        setFilterOptions({
                          ...filterOptions,
                          distance: e.target.value,
                        })
                      }
                    />
                  </div>
                  <span>100km</span>
                </div>
                <span>{filterOptions.distance}km</span>
              </ContainerInputRange>
              <ContainerInputRadio>
                <Options>
                  <input type="radio" value="yes" name="sexPreference" />
                  <label className="option" htmlFor="sexPreference">
                    {messages[key].hetero}
                  </label>
                  <input type="radio" value="no" name="childs" defaultChecked />
                  <label className="option" htmlFor="sexPreference">
                    {messages[key].bisexual}
                  </label>
                </Options>
              </ContainerInputRadio>
              <ContainerButton>
                <Button>{messages[key].filter}</Button>
              </ContainerButton>
            </ContainerFilter>
          )}
        </Content>
      )}
    </Container>
  );
}
