import * as firebase from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAZvrSdQwp4ssQaWviOLnspMyq2MIgdRQU',
  authDomain: 'call-service-webrtc.firebaseapp.com',
  projectId: 'call-service-webrtc',
  storageBucket: 'call-service-webrtc.appspot.com',
  messagingSenderId: '737250717823',
  appId: '1:737250717823:web:985c8420dc5cbab5ba4f4a',
};
if (!firebase.getApps().length) {
  firebase.initializeApp(firebaseConfig);
}

export const firestore = getFirestore();
export const realtime = getDatabase();

export default firebase;
