import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  select: string;
  income: string;
  patrimony: string;
  email: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  adress: string;
  country: string;
  state: string;
  city: string;
  profession: string;
  formation: string;
  languages: string;
  hairColor: string;
  eyeColor: string;
  concluded: string;
  telephone: string;
  currency: string;
  message: string;
  selectCountry: string;
  selectState: string;
  selectCity: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Usuário',
    select: 'Selecione',
    income: 'Renda',
    patrimony: 'Patrimônio',
    email: 'Email',
    instagram: 'Instagram',
    facebook: 'Facebook',
    tiktok: 'TikTok',
    adress: 'Endereço',
    country: 'País',
    state: 'Estado',
    city: 'Cidade',
    profession: 'Profissão',
    formation: 'Formação',
    languages: 'Idiomas',
    hairColor: 'Cor do cabelo',
    eyeColor: 'Cor dos olhos',
    telephone: 'Telefone',
    concluded: 'Concluído',
    currency: 'R$',
    message: 'Alterações salvas',
    selectCountry: 'Selecione um país',
    selectState: 'Selecione um estado',
    selectCity: 'Selecione uma cidade',
  },
  'en-US': {
    title: 'User',
    select: 'Select',
    income: 'Income',
    patrimony: 'Patrimony',
    email: 'Email',
    instagram: 'Instagram',
    facebook: 'Facebook',
    tiktok: 'TikTok',
    adress: 'Adress',
    country: 'Country',
    state: 'State',
    city: 'City',
    profession: 'Profession',
    formation: 'Formation',
    languages: 'Languages',
    hairColor: 'Hair color',
    eyeColor: 'Eye color',
    telephone: 'Telephone',
    concluded: 'Concluded',
    currency: '$',
    message: 'Saved changes',
    selectCountry: 'Select a country',
    selectState: 'Select a state',
    selectCity: 'Select a city',
  },
};
