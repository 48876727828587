import defaultImage from 'assets/images/img/userDefaultImage.png';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setIsCallComming } from 'store/slices/callSlice';
import { callSocket } from 'services/callServiceAPI';
import { useHistory } from 'react-router-dom';

import {
  AcceptCallButton,
  Container,
  ContainerButtons,
  Content,
  Image,
  RejectCallButton,
} from './styles';

export default function OverlayReceivedCall(): JSX.Element {
  const dispatch = useAppDispatch();
  const currentCall = useAppSelector(state => state.call.currentCall);
  const history = useHistory();

  function handleRejectCall() {
    const rejectedCall = currentCall;
    dispatch(setIsCallComming(false));
    callSocket.emit('user.call.status', {
      ...rejectedCall,
      status: 'rejected',
    });
  }
  function handleAcceptedCall() {
    const dataAccepted = { ...currentCall, status: 'accepted' };
    callSocket.emit('user.call.status', dataAccepted);
    dispatch(setIsCallComming(false));
    history.push('videochamada');
  }

  return (
    <Container>
      <Content>
        <div className="container-user-info">
          <Image src={defaultImage} alt="user" />
          <h1>{currentCall?.from.name} está te ligando...</h1>
        </div>
        <ContainerButtons>
          <RejectCallButton onClick={() => handleRejectCall()}>
            Recusar
          </RejectCallButton>
          <AcceptCallButton onClick={() => handleAcceptedCall()}>
            Aceitar
          </AcceptCallButton>
        </ContainerButtons>
      </Content>
    </Container>
  );
}
