import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  text: string;
  apresentationPhrase: string;
  objectives: string;
  proceed: string;
  cancel: string;
  successMessage: string;
  errorMessage: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    text: 'Um espaço para demonstrar seus objetivos no Suggar Bank:',
    apresentationPhrase: 'Escreva aqui sua frase de apresentação',
    objectives: 'Insira aqui o seu objetivo no Suggar Bank',
    proceed: 'Prosseguir',
    cancel: 'Cancelar',
    errorMessage: 'Preencha todos os campos para prosseguir',
    successMessage: 'Dados validados',
  },
  'en-US': {
    text: 'A space to demonstrate your goals at Sugar Bank :',
    apresentationPhrase: 'Write your introductory phrase here',
    objectives: 'Enter your Sugar Bank goal here',
    proceed: 'Proceed',
    cancel: 'Cancel',
    errorMessage: 'Fill in all fields to proceed ',
    successMessage: 'Validated fields',
  },
};
