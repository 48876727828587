import { useEffect, useState } from 'react';
import { IoSettingsSharp } from 'react-icons/io5';
import { colors } from 'styles/colors';
import { useToast } from 'hooks/toast';
import { IModalDisplay } from 'interfaces/ModalDisplay';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  ContainerModal,
  TitleModal,
  ContentModal,
  ModalButton,
  ButtonReturn,
} from '../styles';

import {
  ContainerSpicyPhotoValue,
  SpicyPhotoValueInput,
  ModalBigText,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ModalProps {
  closeModal: (prop: keyof IModalDisplay, action: 'open' | 'close') => void;
}

export function OthersSettings({ closeModal }: ModalProps): JSX.Element {
  const [spicyPhotoValue, setSpicyPhotoValue] = useState('10');
  const { addSuccess } = useToast();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleCloseModal() {
    closeModal('othersSettings', 'close');
  }

  function handleConfirmChanges() {
    addSuccess(`${messages[key].message}`);
  }

  return (
    <ContainerModal>
      <TitleModal>
        <IoSettingsSharp fill={colors.darkGray} size={50} />
        <h2>{messages[key].title}</h2>
        <ButtonReturn size={40} onClick={() => handleCloseModal()} />
      </TitleModal>
      <ContentModal>
        <ModalBigText>{messages[key].spicyPhoto}</ModalBigText>
        <ContainerSpicyPhotoValue>
          <SpicyPhotoValueInput
            name="spicyPhotoValue"
            value={spicyPhotoValue}
            type="currency"
            prefix="R$ "
            onValueChange={value => {
              if (value) {
                setSpicyPhotoValue(value);
              } else {
                setSpicyPhotoValue('');
              }
            }}
          />
        </ContainerSpicyPhotoValue>
        <ModalButton size="regular" onClick={() => handleConfirmChanges()}>
          {messages[key].concluded}
        </ModalButton>
      </ContentModal>
    </ContainerModal>
  );
}
