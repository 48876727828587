import { useEffect, useState } from 'react';
import { RiGitRepositoryPrivateLine } from 'react-icons/ri';
import { IoMdClose } from 'react-icons/io';
import { colors } from 'styles/colors';
import { useToast } from 'hooks/toast';
import { IModalDisplay } from 'interfaces/ModalDisplay';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  ContainerModal,
  TitleModal,
  ContentModal,
  ModalButton,
  ModalContainerInput,
  ModalInput,
  ButtonReturn,
} from '../styles';

import {
  ButtonBlockAdress,
  ContainerAdressBlock,
  AdressBlocked,
  ModalText,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ModalProps {
  closeModal: (prop: keyof IModalDisplay, action: 'open' | 'close') => void;
}

export function Privacity({ closeModal }: ModalProps): JSX.Element {
  const [adressToBlock, setAdressToBlock] = useState('');
  const [blockedAdress, setBlockedAdress] = useState<Array<string>>(
    [] as Array<string>,
  );
  const { addSuccess } = useToast();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleCloseModal() {
    closeModal('privacity', 'close');
  }

  function handleConfirmChanges() {
    addSuccess(`${messages[key].message}`);
  }

  function handleAddBlockedAdress() {
    if (!adressToBlock.length) return;
    if (blockedAdress.indexOf(adressToBlock) !== -1) return;
    setBlockedAdress([...blockedAdress, adressToBlock]);
  }

  function handleRemoveBlockedAdress(adress: string) {
    setBlockedAdress(blockedAdress.filter(obj => obj !== adress));
  }

  return (
    <ContainerModal>
      <TitleModal>
        <RiGitRepositoryPrivateLine fill={colors.darkGray} size={50} />
        <h2>{messages[key].title}</h2>
        <ButtonReturn size={40} onClick={() => handleCloseModal()} />
      </TitleModal>
      <ContentModal>
        <ModalText>{messages[key].text}</ModalText>
        <ModalContainerInput>
          <ModalInput
            placeholder={messages[key].placeholder}
            name="bank"
            value={adressToBlock}
            onChange={e => setAdressToBlock(e.target.value)}
          />
        </ModalContainerInput>
        <ButtonBlockAdress
          size="small"
          onClick={() => handleAddBlockedAdress()}
        >
          {messages[key].block}
        </ButtonBlockAdress>
        {blockedAdress.map(obj => {
          return (
            <ContainerAdressBlock key={obj}>
              <AdressBlocked>{obj}</AdressBlocked>
              <IoMdClose
                fill={colors.remove}
                size={30}
                cursor="pointer"
                onClick={() => handleRemoveBlockedAdress(obj)}
              />
            </ContainerAdressBlock>
          );
        })}
        <ModalButton size="regular" onClick={() => handleConfirmChanges()}>
          {messages[key].concluded}
        </ModalButton>
      </ContentModal>
    </ContainerModal>
  );
}
