import { useState, useEffect } from 'react';
import { colors } from 'styles/colors';
import { FaGlobeAmericas, FaRegUserCircle } from 'react-icons/fa';
import { MdAttachMoney } from 'react-icons/md';
import { RiBankFill, RiGitRepositoryPrivateLine } from 'react-icons/ri';
import { FiAlertCircle } from 'react-icons/fi';
import { IoSettingsSharp } from 'react-icons/io5';
import { IModalDisplay } from 'interfaces/ModalDisplay';
import { USER } from 'constants/environment';
import { User } from 'interfaces/user';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import { Language } from './Language';
import { Currency } from './Currency';
import { BankAccount } from './BankAccount';
import { Ocurrence } from './Ocurrence';
import { Privacity } from './Privacity';
import { Users } from './Users';
import { OthersSettings } from './OthersSettings';

import {
  Container,
  Title,
  ContainerSettingsSections,
  ContainerContentSections,
  Main,
  ButtomSection,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function SettingsPage(): JSX.Element {
  const [ShowModalButtons, setShowModalButtons] = useState(true);
  const screenWidth = window.innerWidth;
  const [showModals, setShowModals] = useState<IModalDisplay>(
    {} as IModalDisplay,
  );

  const userData = localStorage.getItem(USER);
  const user: User = userData
    ? JSON.parse(userData)
    : { id: 'null', name: 'null', email: 'null', type: 'DADDY' };
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    if (screenWidth < 950)
      setShowModals({
        language: false,
        currency: false,
        bankAccount: false,
        ocurrence: false,
        privacity: false,
        user: false,
        othersSettings: false,
      });
    else
      setShowModals({
        language: true,
        currency: false,
        bankAccount: false,
        ocurrence: false,
        privacity: false,
        user: false,
        othersSettings: false,
      });
  }, [screenWidth]);

  function handleChangeModal(
    prop: keyof IModalDisplay,
    action: 'open' | 'close',
  ) {
    if (action === 'open') {
      if (screenWidth < 950) {
        setShowModalButtons(false);
      }

      setShowModals({
        language: false,
        currency: false,
        bankAccount: false,
        ocurrence: false,
        privacity: false,
        user: false,
        othersSettings: false,
        [prop]: true,
      });
    }
    if (action === 'close') {
      setShowModalButtons(true);
      setShowModals({ ...showModals, [prop]: false });
    }
  }

  return (
    <Container>
      <Main>
        {ShowModalButtons && (
          <ContainerSettingsSections>
            <Title>{messages[key].title}</Title>
            <ContainerContentSections>
              <ButtomSection
                onClick={() => handleChangeModal('language', 'open')}
              >
                <FaGlobeAmericas fill={colors.darkGray} size={35} />
                <span>{messages[key].language}</span>
              </ButtomSection>
              <ButtomSection
                onClick={() => handleChangeModal('currency', 'open')}
              >
                <MdAttachMoney fill={colors.darkGray} size={35} />
                <span>{messages[key].currency}</span>
              </ButtomSection>
              <ButtomSection
                onClick={() => handleChangeModal('bankAccount', 'open')}
              >
                <RiBankFill fill={colors.darkGray} size={35} />
                <span>{messages[key].bankAccount}</span>
              </ButtomSection>
              <ButtomSection
                onClick={() => handleChangeModal('ocurrence', 'open')}
              >
                <FiAlertCircle fill={colors.darkGray} size={35} />
                <span>{messages[key].occurrences}</span>
              </ButtomSection>
              <ButtomSection
                onClick={() => handleChangeModal('privacity', 'open')}
              >
                <RiGitRepositoryPrivateLine fill={colors.darkGray} size={35} />
                <span>{messages[key].privacity}</span>
              </ButtomSection>
              <ButtomSection onClick={() => handleChangeModal('user', 'open')}>
                <FaRegUserCircle fill={colors.darkGray} size={35} />
                <span>{messages[key].user}</span>
              </ButtomSection>
              {user.type === 'BABY' && (
                <ButtomSection
                  onClick={() => handleChangeModal('othersSettings', 'open')}
                >
                  <IoSettingsSharp fill={colors.darkGray} size={35} />
                  <span>{messages[key].othersSettings}</span>
                </ButtomSection>
              )}
            </ContainerContentSections>
          </ContainerSettingsSections>
        )}
        {showModals.language && <Language closeModal={handleChangeModal} />}

        {showModals.currency && <Currency closeModal={handleChangeModal} />}

        {showModals.bankAccount && (
          <BankAccount closeModal={handleChangeModal} />
        )}

        {showModals.ocurrence && <Ocurrence closeModal={handleChangeModal} />}

        {showModals.privacity && <Privacity closeModal={handleChangeModal} />}

        {showModals.user && <Users closeModal={handleChangeModal} />}

        {showModals.othersSettings && user.type === 'BABY' && (
          <OthersSettings closeModal={handleChangeModal} />
        )}
      </Main>
    </Container>
  );
}
