import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  hairColor: string;
  eyeColor: string;
  height: string;
  smoker: string;
  drink: string;
  yes: string;
  no: string;
  proceed: string;
  cancel: string;
  successMessage: string;
  errorMessage: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    hairColor: 'Cor dos cabelos',
    eyeColor: 'Cor dos olhos',
    height: 'Altura (cm)',
    drink: 'Bebe',
    smoker: 'Fumante',
    yes: 'Sim',
    no: 'Não',
    proceed: 'Prosseguir',
    cancel: 'Cancelar',
    errorMessage: 'Não foi possível validar os dados',
    successMessage: 'Dados validados',
  },
  'en-US': {
    hairColor: 'Hair color',
    eyeColor: 'Eyes color',
    height: 'height (cm)',
    drink: 'drink',
    smoker: 'smoker',
    yes: 'Yes',
    no: 'No',
    proceed: 'Proceed',
    cancel: 'Cancel',
    errorMessage: 'It was not possible to validate the fields',
    successMessage: 'Validated fields',
  },
};
