import { ContainerSignUp } from 'components/ContainerSignUp';
import { useState, useCallback, useEffect } from 'react';
import { useToast } from 'hooks/toast';
import { useHistory } from 'react-router-dom';
import { IoIosReturnLeft } from 'react-icons/io';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IUser, setObjectiveData } from 'store/slices/signupSlice';
import api from 'services/api';
import { sanitizePhone } from 'helpers/sanitize';
import { AxiosError } from 'axios';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  Title,
  TextArea,
  ButtonProceed,
  CancelButton,
  ContainerMain,
  Main,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function ObjectiveData(): JSX.Element {
  const [introductoryPhrase, setIntroductoryPhrase] = useState('');
  const [objective, setObjective] = useState('');
  const { addError, addSuccess } = useToast();
  const history = useHistory();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  const signup = useAppSelector(state => state.signup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
    setObjective(signup.metadata.objective);
    setIntroductoryPhrase(signup.metadata.introductoryPhrase);
  }, [language, signup]);

  const handleSandForm = useCallback(async () => {
    if (!objective.length) addError(`${messages[key].errorMessage}`);
    if (!introductoryPhrase.length) addError(`${messages[key].errorMessage}`);

    if (objective.length && introductoryPhrase.length) {
      addSuccess(`$C`);
      dispatch(setObjectiveData({ objective, introductoryPhrase }));
      try {
        const formatData = (data: IUser) => {
          return {
            ...data,
            phone: sanitizePhone(data.phone),
          };
        };
        await api.post('/user', JSON.stringify(formatData(signup)), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        const errorObj = error as AxiosError;
        if (errorObj.response) {
          const data = errorObj.response?.data;
          if (data) {
            const errors = data.errors as { error: string }[];
            errors.map(({ error: message }) => error && addError(message));
          }
        } else {
          addError('Oops! Ocorreu um erro tente novamente mais tarde!');
        }
      }
      history.push('/');
    }
  }, [
    objective,
    introductoryPhrase,
    addSuccess,
    addError,
    history,
    key,
    dispatch,
    signup,
  ]);

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <Title>{messages[key].text}</Title>
            <TextArea
              name="objective"
              theme={{
                height: '10vh',
                border: introductoryPhrase.length
                  ? 'none'
                  : 'solid 2px #E41D1D',
              }}
              placeholder={messages[key].apresentationPhrase}
              value={introductoryPhrase}
              onChange={e => setIntroductoryPhrase(e.target.value)}
            />
            <TextArea
              theme={{
                height: '20vh',
                border: objective.length ? 'none' : 'solid 2px #E41D1D',
              }}
              placeholder={messages[key].objectives}
              value={objective}
              onChange={e => setObjective(e.target.value)}
            />
            <ButtonProceed
              backgroundColor="primary"
              size="regular"
              onClick={() => handleSandForm()}
            >
              {messages[key].proceed}
            </ButtonProceed>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
