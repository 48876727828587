import styled from 'styled-components';
import { BsPaperclip } from 'react-icons/bs';
import { Button } from 'components/Button';
import Background from 'pages/Auth/_Background';

export const ContainerMain = styled(Background)``;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.span`
  font-size: 17px;
  color: var(--white);
  margin: 5px 0;
`;

export const TitleProof = styled.span`
  display: ${props => props.theme.display};
  font-size: 17px;
  color: var(--white);
  margin: 5px 0;
`;

export const ContentInput = styled.div`
  display: ${props => props.theme.display};
  padding-top: 5px;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  width: 100%;
  flex-wrap: nowrap;
  margin: 5px 0;
`;

export const Clip = styled(BsPaperclip)`
  fill: var(--white);
  font-size: 24px;
  cursor: pointer;
`;

export const Alert = styled.span`
  color: var(--white);
  font-size: 14px;
  padding: 15px;
`;

export const ImportFile = styled.div`
  display: flex;
  padding-top: 5px;
  justify-content: flex-start;
  align-items: space-around;
  width: 100%;
  input[type='file'] {
    display: none;
  }
  label {
    cursor: pointer;
    align-items: center;
    color: var(--dark-gray);
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
    width: 100%;
    border-bottom: 0.0625rem solid var(--ligh-gray);
  }
`;

export const CancelButton = styled(Button)`
  margin-top: 15px;
`;

export const ProceedButton = styled(Button)`
  display: ${props => props.theme.display};
`;
