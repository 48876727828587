import styled from 'styled-components';
import { Button } from 'components/Button';

export const ButtonBlockAdress = styled(Button)`
  width: 7rem;
  margin-bottom: 1rem;
`;

export const ContainerAdressBlock = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

export const AdressBlocked = styled.span`
  font-size: 1.3rem;
  color: var(--dark-gray);
  width: 70%;
  text-align: center;
  margin-right: 1.5rem;
`;

export const ModalText = styled.p`
  font-size: 1.3rem;
  color: var(--dark-gray);
  width: 70%;
  text-align: center;
`;
