import { useState, useEffect, useCallback } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { IoIosReturnLeft } from 'react-icons/io';
import { useToast } from 'hooks/toast';
import { adressDataValidator } from 'validators/SignUpValidators/adressData';
import getValidationErrors from 'utils/getValidationErrors';
// import { colors } from 'styles/colors';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
// import api from 'services/api';

import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setAddressData } from 'store/slices/signupSlice';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  InputContainer,
  // Select,
  CancelButton,
  Main,
  ContainerMain,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUserData {
  street: string;
  cep: string;
  neighborhood: string;
  country: string;
  state: string;
  city: string;
}

// interface IRequestCountry {
//   id: string;
//   name: string;
//   description: string;
// }

// interface IRequestState {
//   id: string;
//   name: string;
//   description: string;
//   latitude: string;
//   longitude: string;
//   uf: string;
//   countryId: string;
// }

// interface IRequestCity {
//   id: string;
//   name: string;
//   stateId: string;
// }

export function AdressData(): JSX.Element {
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  // const [countries, setCountries] = useState<Array<IRequestCountry>>(
  //   [] as Array<IRequestCountry>,
  // );
  // const [states, setStates] = useState<Array<IRequestState>>(
  //   [] as Array<IRequestState>,
  // );
  // const [cities, setCities] = useState<Array<IRequestCity>>(
  //   [] as Array<IRequestCity>,
  // );

  const signup = useAppSelector(state => state.signup.address);
  const dispatch = useAppDispatch();

  const { addError, addSuccess } = useToast();
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setUserData({
      street: signup.street,
      cep: signup.cep,
      neighborhood: signup.neighborhood,
      country: signup.country,
      state: signup.state,
      city: signup.city,
    });
  }, [signup]);

  // useEffect(() => {
  //   async function getCountries() {
  //     try {
  //       const response = await api.get('/geographical/country');
  //       if (response) setCountries(response.data.result);
  //     } catch (error) {
  //       addError('Não foi possível carregar os países');
  //     }
  //   }
  //   getCountries();
  // }, [addError]);

  // useEffect(() => {
  //   async function handleGetStates(countryId: string) {
  //     try {
  //       const response = await api.get(
  //         `/geographical/state/${countryId}/states`,
  //       );
  //       if (response) setStates(response.data.result);
  //     } catch (error) {
  //       addError('Não foi possível carregar os estados');
  //     }
  //   }
  //   if (userData.country) handleGetStates(userData.country);
  // }, [userData.country, addError]);

  // useEffect(() => {
  //   async function handleGetCities(countryId: string, stateId: string) {
  //     try {
  //       const response = await api.get(
  //         `/geographical/city/${countryId}/states/${stateId}/city`,
  //       );
  //       if (response) setCities(response.data.result);
  //     } catch (error) {
  //       addError('Não foi possível carregar as cidades');
  //     }
  //   }
  //   if (userData.country && userData.state)
  //     handleGetCities(userData.country, userData.state);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userData.state, addError]);

  const handleSandForm = useCallback(async () => {
    try {
      setErrors({});
      const { street } = userData;
      await adressDataValidator.validate(
        {
          street,
        },
        { abortEarly: false },
      );
      dispatch(setAddressData({ ...userData }));

      addSuccess(`${messages[key].successMessage}`);
      history.push('idiomas');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }

      addError(`${messages[key].errorMessage}`);
    }
  }, [userData, addSuccess, addError, history, key, dispatch]);

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <InputContainer>
              {/* <Select
                name="country"
                theme={{
                  border:
                    userData.country === '' ? colors.danger : colors.lightGray,
                }}
                onChange={e => {
                  setUserData({ ...userData, country: e.target.value });
                }}
              >
                <option value="default">{messages[key].selectCountry}</option>
                {countries.map(obj => {
                  return (
                    <option value={obj.id} key={obj.id}>
                      {obj.name}
                    </option>
                  );
                })}
              </Select>
              <Select
                name="state"
                placeholder="Estado"
                theme={{
                  border:
                    userData.state === '' ? colors.danger : colors.lightGray,
                }}
                onChange={e => {
                  setUserData({ ...userData, state: e.target.value });
                }}
              >
                <option value="default">{messages[key].selectState}</option>
                {states.map(obj => {
                  return (
                    <option value={obj.id} key={obj.id}>
                      {obj.name}
                    </option>
                  );
                })}
              </Select>
              <Select
                name="city"
                placeholder="Cidade"
                theme={{
                  border:
                    userData.city === '' ? colors.danger : colors.lightGray,
                }}
                onChange={e =>
                  setUserData({ ...userData, city: e.target.value })
                }
              >
                <option value="default">{messages[key].selectCity}</option>
                {cities.map(obj => {
                  return (
                    <option value={obj.name} key={obj.id}>
                      {obj.name}
                    </option>
                  );
                })}
              </Select> */}
              <Input
                name="cep"
                value={userData.cep}
                placeholder={messages[key].cep}
                errors={errors}
                onChange={e =>
                  setUserData({ ...userData, cep: e.target.value })
                }
              />
              <Input
                name="country"
                value={userData.country}
                placeholder={messages[key].country}
                errors={errors}
                onChange={e =>
                  setUserData({ ...userData, country: e.target.value })
                }
              />
              <Input
                name="state"
                value={userData.state}
                placeholder={messages[key].state}
                errors={errors}
                onChange={e =>
                  setUserData({ ...userData, state: e.target.value })
                }
              />
              <Input
                name="city"
                value={userData.city}
                placeholder={messages[key].city}
                errors={errors}
                onChange={e =>
                  setUserData({ ...userData, city: e.target.value })
                }
              />
              <Input
                name="neighborhood"
                value={userData.neighborhood}
                placeholder={messages[key].neighborhood}
                errors={errors}
                onChange={e =>
                  setUserData({ ...userData, neighborhood: e.target.value })
                }
              />
              <Input
                name="street"
                value={userData.street}
                placeholder={messages[key].street}
                errors={errors}
                onChange={e =>
                  setUserData({ ...userData, street: e.target.value })
                }
              />
            </InputContainer>
            <Button
              backgroundColor="primary"
              size="regular"
              onClick={() => handleSandForm()}
            >
              {messages[key].proceed}
            </Button>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
