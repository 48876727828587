import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';
import { FiGift } from 'react-icons/fi';

export const Container = styled(DefaultPage)``;

export const MainContainer = styled.div`
  height: 100%;
  width: 75%;
  margin: 0 auto;
  display: flex;
  background-color: var(--blue-card);
  overflow-x: auto;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const ContainerContacts = styled.div`
  width: 27rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  border: solid 1px var(--light-gray);
  min-width: 27rem;
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1rem;
    width: 100%;
    button {
      border: solid 2px var(--primary);
      border-radius: 5rem;
      padding: 0.5rem;
      background-color: transparent;
      color: var(--primary);
      font-size: 1rem;
      width: auto;

      :last-child {
        padding: 0.5rem 2rem;
      }
    }
  }
`;

export const InputSearch = styled.div`
  background-color: rgba(250, 99, 121, 0.8);
  border-radius: 5rem;
  width: 100%;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;

  input {
    background-color: transparent;
    border: none;
    color: var(--light-gray);
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    width: 90%;

    ::placeholder {
      color: var(--light-gray);
    }
  }
`;

export const MessagesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const UserMessage = styled.div`
  border-radius: 5px;
  background-color: var(--secondary);
  border: solid 1px var(--dark-gray);
  width: 98%;
  display: flex;
  padding: 0.3rem;
  margin-top: 1rem;
  cursor: pointer;
`;

export const UserImage = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: var(--light-gray);
  border: solid 1px var(--primary);
`;

export const MessagesInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-left: 0.5rem;

  div {
    display: flex;
    justify-content: space-between;

    span {
      :nth-child(2) {
        font-size: 0.8rem;
      }
    }
  }

  span {
    :first-child {
      color: var(--light-gray);
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }

    :last-child {
      color: var(--dark-gray);
      font-size: 1rem;
    }
  }
`;

export const Hour = styled.span`
  color: var(--dark-gray);
`;

export const ContainerChat = styled.div`
  flex: 1;
  border: solid 1px var(--light-gray);
  display: flex;
  flex-direction: column;
  background-color: var(--gray);
  justify-content: space-between;
  min-width: 30rem;
`;

export const ContainerWriteMessage = styled.div`
  background-color: var(--blue-card);
  width: 100%;
  display: flex;
  padding: 0.5rem;
  height: 4rem;
  align-items: center;
`;

export const InputWriteMessage = styled.textarea`
  border: solid 1px var(--primary);
  border-radius: 4rem;
  height: 2.5rem;
  min-height: 2.5rem;
  max-height: 2.5rem;
  width: 92%;
  min-width: 87%;
  background-color: transparent;
  color: var(--light-gray);
  font-size: 1rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;

  ::placeholder {
    color: var(--light-gray);
    font-size: 1rem;
  }
`;

export const ChatHeader = styled.div`
  background-color: var(--blue-card);
  width: 100%;
  display: flex;
  padding: 0.5rem;
  padding-left: 1rem;
  height: 6rem;
  align-items: center;
`;

export const ContainerImage = styled.div`
  width: 70px;
`;

export const UserImageHeader = styled(UserImage)`
  width: 70px;
  height: 70px;
`;

export const UserName = styled.p`
  font-size: 1.4rem;
  color: var(--light-gray);
  margin-left: 1.5rem;
  width: 78%;
`;

export const ContainerIcons = styled.div`
  height: 100%;
  width: 7rem;
  display: flex;
  justify-self: flex-end;
  justify-content: space-between;
`;

export const ContainerChatMessages = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 1rem;
`;

export const MessageMe = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  div {
    padding: 0.5rem 1rem;
    background-color: var(--light-gray);
    border-radius: 2rem;
    display: flex;
    align-items: flex-end;

    p {
      color: var(--graphite);
      font-size: 1.1rem;
    }

    span {
      color: var(--graphite);
      font-size: 0.6rem;
      margin-left: 0.5rem;
    }
  }
`;

export const MessageUser = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;

  div {
    padding: 0.5rem 1rem;
    background-color: var(--primary);
    border-radius: 2rem;
    display: flex;
    align-items: flex-end;

    p {
      color: var(--light-gray);
      font-size: 1.1rem;
    }

    span {
      color: var(--graphite);
      font-size: 0.6rem;
      margin-left: 0.5rem;
    }
  }
`;

export const DefaultChat = styled(ContainerChatMessages)`
  align-items: center;
  justify-content: center;
  min-width: 30rem;
`;

export const GiftIcon = styled(FiGift)`
  fill: var(--primary);
  cursor: pointer;
  margin-right: 1rem;
`;
