import { useEffect, useState } from 'react';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { ButtonProfile, Container, ContainerButtons, Image } from './styles';
import { messages, LanguageTranslated } from './translate';

type Key = keyof IMessage<LanguageTranslated>;

export function CardImageProfile(): JSX.Element {
  const [editImage, setEditImage] = useState(false);
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  return (
    <Container>
      <Image />
      <ContainerButtons>
        <ButtonProfile onClick={() => setEditImage(!editImage)}>
          {!editImage ? messages[key].change : messages[key].cancel}
        </ButtonProfile>
        <ButtonProfile>
          {!editImage ? messages[key].remove : messages[key].requestReview}
        </ButtonProfile>
      </ContainerButtons>
    </Container>
  );
}
