import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  birthDate: string;
  name: string;
  email: string;
  nick: string;
  telephone: string;
  baby: string;
  daddy: string;
  proceed: string;
  cancel: string;
  successMessage: string;
  errorMessage: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    birthDate: 'Data de nascimento',
    name: 'Nome',
    email: 'Email',
    nick: 'Apelido',
    telephone: 'Telefone',
    baby: 'Quero ser um(a) baby',
    daddy: 'Quero ser um daddy',
    proceed: 'Prosseguir',
    cancel: 'Cancelar',
    errorMessage: 'Não foi possível validar os dados',
    successMessage: 'Dados validados',
  },
  'en-US': {
    birthDate: 'Birth date',
    name: 'Name',
    email: 'Email',
    nick: 'Nickname',
    telephone: 'Telephone',
    baby: 'I want to be baby',
    daddy: 'I want to be daddy',
    proceed: 'Proceed',
    cancel: 'Cancel',
    errorMessage: 'It was not possible to validate the fields',
    successMessage: 'Validated fields',
  },
};
