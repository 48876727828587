import { useEffect, useState } from 'react';
import { CardImageProfile } from 'components/CardImageProfile';
import { Button } from 'components/Button';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  ContainerAnalyze,
  ContainerButtons,
  ContainerImages,
  ContainerPhrase,
  SubTitle,
  Title,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function EditProfile(): JSX.Element {
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  return (
    <Container>
      <Title>{messages[key].title}</Title>
      <SubTitle>{messages[key].photos}</SubTitle>
      <ContainerImages>
        <CardImageProfile />
        <CardImageProfile />
        <CardImageProfile />
        <CardImageProfile />
      </ContainerImages>
      <SubTitle>{messages[key].coverPhrase}</SubTitle>
      <ContainerAnalyze>
        <ContainerPhrase>
          <p>
            frase de apresentação Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Fuga neque eius dolores, fugit est, tempora
            provident minima asperiores, aperiam nostrum ut! Distinctio facilis
            impedit totam libero at? Dolorem, hic consectetur.
          </p>
        </ContainerPhrase>
        <ContainerButtons>
          <Button>{messages[key].requestReview}</Button>
        </ContainerButtons>
      </ContainerAnalyze>
    </Container>
  );
}
