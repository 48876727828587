import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  text: string;
  proceed: string;
  cancel: string;
  successMessage: string;
  errorMessage: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    text: 'Adicione aqui suas 4 melhores fotos:',
    proceed: 'Prosseguir',
    cancel: 'Cancelar',
    errorMessage: 'Você deve adicionar pelo menos uma imagem',
    successMessage: 'Dados validados',
  },
  'en-US': {
    text: 'Add your 4 best photos here:',
    proceed: 'Proceed',
    cancel: 'Cancel',
    errorMessage: 'You must add at least one image',
    successMessage: 'Validated fields',
  },
};
