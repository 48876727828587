export const colors = {
  blueDark: '#04121F',
  blueRegular: '#0E2C48',
  blue: '#001978',
  blueCard: '#122C38',
  black: '#000000',
  pink: '#c940a6',
  salmon: '#fa6379',
  graphite: '#333333',
  white: '#FFFFFF',
  lightGray: '#F2F2F2',
  gray: '#E0E0E0',
  darkGray: '#b0b0b0',
  regularGray: '#828282',
  success: '#6FCF97',
  danger: '#EB5757',
  remove: '#E41D1D',
};
