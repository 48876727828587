import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  real: string;
  dollar: string;
  euro: string;
  realCurrency: string;
  dollarCurrency: string;
  euroCurrency: string;
  message: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Moeda',
    real: 'Real',
    dollar: 'Dólar',
    euro: 'Euro',
    realCurrency: 'BRL',
    dollarCurrency: 'USD',
    euroCurrency: 'EUR',
    message: 'Moeda alterada',
  },
  'en-US': {
    title: 'Currency',
    real: 'Real',
    dollar: 'Dollar',
    euro: 'Euro',
    realCurrency: 'BRL',
    dollarCurrency: 'USD',
    euroCurrency: 'EUR',
    message: 'Changed currency',
  },
};
