import { Button } from 'components/Button';
import { useAuth } from 'hooks/auth';
import { MdMood } from 'react-icons/md';
import { Container } from './styles';

export function HomePage(): JSX.Element {
  const { signOut } = useAuth();
  return (
    <Container>
      <div className="header">
        <MdMood /> <span>Seja bem-vindo</span>
      </div>
      <div className="button">
        <Button
          backgroundColor="primary"
          name="logout"
          size="regular"
          onClick={() => signOut()}
        >
          Sair
        </Button>
      </div>
    </Container>
  );
}
