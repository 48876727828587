import { useState, useEffect } from 'react';
import { ImSearch } from 'react-icons/im';
import { IoSendSharp } from 'react-icons/io5';
import { GoAlert } from 'react-icons/go';
import { BsCameraVideoFill } from 'react-icons/bs';
import { colors } from 'styles/colors';
import { EarthRotation } from 'components/EarthRotation';
import { Logo } from 'components/Logo';
import { usePageSettings } from 'hooks/pagesSettings';
import { useAuth } from 'hooks/auth';
import { Gifts } from 'components/Gifts';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ICurrentCall, IUserCall, startCall } from 'store/slices/callSlice';

import { IMessage } from 'interfaces/pagesSettings';
import { callSocket } from 'services/callServiceAPI';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  MainContainer,
  ContainerContacts,
  ContainerFilters,
  InputSearch,
  UserMessage,
  UserImage,
  MessagesList,
  MessagesInformation,
  Hour,
  ContainerChat,
  ContainerWriteMessage,
  InputWriteMessage,
  ChatHeader,
  UserImageHeader,
  UserName,
  ContainerIcons,
  ContainerChatMessages,
  MessageMe,
  MessageUser,
  DefaultChat,
  ContainerImage,
  GiftIcon,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUserMessages {
  id: string;
  message: string;
  whoSent: 'me' | 'user';
  hour: string;
}

interface IMessages {
  user: IUserCall;
  id: string;
  status: 'online' | 'offline';
  name: string;
  role: 'babby' | 'daddy';
  message: string;
  hour: string;
  messagesInChat: Array<IUserMessages>;
}

export function Messages(): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userMessages, setUserMessages] = useState<Array<IMessages>>(
    [] as Array<IMessages>,
  );
  const [nameFiltered, setNameFiltered] = useState('');
  const [contactSelected, setContactSelected] = useState<
    IMessages | undefined
  >();
  const [messageWrited, setMessageWrited] = useState('');
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const { user } = useAuth();
  const [showGifts, setShowGifts] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const users = useAppSelector(state => state.call.usersList);

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleGetCurrentTime() {
    const data = new Date();
    return `${data.getHours()}:${data.getMinutes()}h`;
  }

  function handleSendMessage() {
    if (messageWrited.length) {
      const arrayMessages: Array<IUserMessages> = contactSelected
        ? contactSelected?.messagesInChat
        : [
            {
              id: '1',
              message: 'Olá',
              whoSent: 'user',
              hour: '6:59h',
            },
            {
              id: '2',
              message: 'Bom dia!',
              whoSent: 'user',
              hour: '7h',
            },
          ];
      if (arrayMessages) {
        arrayMessages.push({
          id: `${Math.random() * 10}`,
          message: messageWrited,
          whoSent: 'me',
          hour: handleGetCurrentTime(),
        });
        if (contactSelected)
          setContactSelected({
            ...contactSelected,
            messagesInChat: arrayMessages,
          });
      }
    }
    setMessageWrited('');
  }

  function handleShowGifts() {
    setShowGifts(!showGifts);
  }

  useEffect(() => {
    users?.forEach(obj => {
      const contact: IMessages = {
        user: obj,
        name: obj.name,
        id: obj.id,
        status: obj.status,
        role: obj.role,
        message: 'Olá!',
        hour: '12:27h',
        messagesInChat: [
          {
            id: '1',
            message: 'Olá',
            whoSent: 'user',
            hour: '6:59h',
          },
          {
            id: '2',
            message: 'Bom dia!',
            whoSent: 'user',
            hour: '7h',
          },
          {
            id: '3',
            message: 'Bom dia!',
            whoSent: 'me',
            hour: '7h',
          },
          {
            id: '4',
            message: 'Tudo bem?',
            whoSent: 'me',
            hour: '7h',
          },
          {
            id: '5',
            message: 'Tudo ótimo!',
            whoSent: 'user',
            hour: '7h',
          },
          {
            id: '6',
            message: 'E vc?',
            whoSent: 'user',
            hour: '7h',
          },
          {
            id: '7',
            message: 'Tudo bem também!',
            whoSent: 'me',
            hour: '7h',
          },
        ],
      };
      if (
        contact.id !== user.id &&
        !userMessages.find(item => item.id === contact.id)
      )
        userMessages.push(contact);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  function handleStartCall(toUser: IUserCall) {
    dispatch(startCall());
    const newCall: ICurrentCall = {
      to: toUser,
      from: {
        id: user.id,
        name: user.name,
        role: user.type === 'BABY' ? 'babby' : 'daddy',
        status: 'online',
      },
      call: null,
      playing: false,
      room: null,
      status: 'pending',
      timestamp: new Date().toString(),
    };

    callSocket.emit('user.call', newCall);
    history.push('videochamada');
  }

  return (
    <Container>
      <MainContainer>
        <ContainerContacts>
          <ContainerFilters>
            <div>
              {user.type === 'BABY' && (
                <button type="button">{messages[key].myDaddys}</button>
              )}

              <button type="button">{messages[key].all}</button>
            </div>
            <InputSearch>
              <input
                placeholder={
                  user.type === 'DADDY'
                    ? messages[key].searchDaddy
                    : messages[key].searchBaby
                }
                value={nameFiltered}
                onChange={e => setNameFiltered(e.target.value)}
              />
              <ImSearch fill={colors.lightGray} size={20} cursor="pointer" />
            </InputSearch>
          </ContainerFilters>

          <MessagesList>
            {userMessages
              .filter(obj =>
                obj.name.toLowerCase().includes(nameFiltered.toLowerCase()),
              )
              .map(obj => {
                return (
                  <UserMessage
                    key={obj.id}
                    onClick={() => setContactSelected(obj)}
                  >
                    <UserImage />
                    <MessagesInformation>
                      <div>
                        <span>{obj.name}</span>
                        <Hour>{obj.hour}</Hour>
                      </div>
                      <span>{obj.message}</span>
                    </MessagesInformation>
                  </UserMessage>
                );
              })}
          </MessagesList>
        </ContainerContacts>
        {contactSelected && (
          <ContainerChat>
            <ChatHeader>
              <ContainerImage>
                <UserImageHeader />
              </ContainerImage>
              <UserName>{contactSelected.name}</UserName>
              <ContainerIcons>
                <GoAlert size={40} fill={colors.salmon} cursor="pointer" />
                <BsCameraVideoFill
                  size={40}
                  fill={colors.salmon}
                  cursor="pointer"
                  onClick={() => handleStartCall(contactSelected.user)}
                />
              </ContainerIcons>
            </ChatHeader>
            <ContainerChatMessages>
              {contactSelected.messagesInChat.map(obj => {
                if (obj.whoSent === 'me')
                  return (
                    <MessageMe key={obj.id}>
                      <div>
                        <p>{obj.message}</p>
                        <span>{obj.hour}</span>
                      </div>
                    </MessageMe>
                  );
                return (
                  <MessageUser key={obj.id}>
                    <div>
                      <p>{obj.message}</p>
                      <span>{obj.hour}</span>
                    </div>
                  </MessageUser>
                );
              })}
            </ContainerChatMessages>
            {showGifts && <Gifts handleClose={handleShowGifts} />}
            <ContainerWriteMessage>
              {user.type === 'DADDY' && (
                <GiftIcon size={40} onClick={() => handleShowGifts()} />
              )}
              <InputWriteMessage
                placeholder={messages[key].writeAMessage}
                onChange={e => setMessageWrited(e.target.value)}
                value={messageWrited || ''}
              />
              <IoSendSharp
                fill={colors.salmon}
                size={30}
                cursor="pointer"
                onClick={() => handleSendMessage()}
              />
            </ContainerWriteMessage>
          </ContainerChat>
        )}
        {!contactSelected && (
          <DefaultChat>
            <EarthRotation position="relative" />
            <Logo size={400} />
          </DefaultChat>
        )}
      </MainContainer>
    </Container>
  );
}
