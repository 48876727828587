import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useState, useEffect } from 'react';
import { usePageSettings } from 'hooks/pagesSettings';
import { IMessage } from 'interfaces/pagesSettings';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  Title,
  Line,
  Content,
  Subtitle,
  Select,
  ContainerPlans,
  ContainerOption,
  Text,
  ContainerInput,
  ContainerButton,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ICurrency {
  id: string;
  currency: string;
  code: string;
}

interface IDataPlans {
  _id: string;
  value: number;
  label: string;
  description: string;
  parcels: number;
  periodInDays: number;
}

export function MyPlan(): JSX.Element {
  const [currencies, setCurrencies] = useState<Array<ICurrency>>(
    [] as Array<ICurrency>,
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const [dataPlans, setDataPlans] = useState<Array<IDataPlans>>(
    [] as Array<IDataPlans>,
  );
  const { addError } = useToast();

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    async function loadDataPlans() {
      try {
        const response = await api.get('/plans');
        if (response) setDataPlans(response.data.result);
        else setDataPlans([]);
      } catch (err) {
        addError('Erro ao carregar planos');
      }
    }
    loadDataPlans();
  }, [addError]);

  useEffect(() => {
    setCurrencies([
      {
        id: '1',
        currency: 'Real',
        code: 'BRL',
      },
      {
        id: '2',
        currency: 'Dólar',
        code: 'USD',
      },
      {
        id: '3',
        currency: 'Euro',
        code: 'EUR',
      },
    ]);
  }, []);

  return (
    <Container>
      <Title>{messages[key].title}</Title>
      <Line />
      <Content>
        <Subtitle>{messages[key].subtitle}</Subtitle>
        <Select>
          {currencies.map(obj => {
            return (
              <option key={obj.id} value={obj.code}>
                {obj.currency}
              </option>
            );
          })}
        </Select>

        {dataPlans.map(obj => {
          return (
            <ContainerOption key={obj._id}>
              <input type="radio" name="plan" value={obj.label} />
              <ContainerPlans>
                <p>{obj.label}</p>
                <span className="value">R${obj.value}</span>
                <span className="benefits">{messages[key].benefits}</span>
                <span className="content">{obj.description}</span>
              </ContainerPlans>
            </ContainerOption>
          );
        })}

        <Text>{messages[key].text}</Text>

        <ContainerInput>
          <Input name="coupon" placeholder={messages[key].coupon} />
        </ContainerInput>

        <ContainerButton>
          <Button size="regular">{messages[key].proceed}</Button>
        </ContainerButton>
      </Content>
    </Container>
  );
}
