import { useState, useEffect, useCallback } from 'react';
import { Gifts } from 'components/Gifts';
import { useToast } from 'hooks/toast';
import { useHistory } from 'react-router-dom';
import { Input } from 'components/Input';
import { usePageSettings } from 'hooks/pagesSettings';
import { useAuth } from 'hooks/auth';
import { useAppDispatch, useAppSelector } from 'hooks';
import { endCall } from 'store/slices/callSlice';

import { IMessage } from 'interfaces/pagesSettings';
import { Button } from 'components/Button';

import { callSocket } from 'services/callServiceAPI';
import { pc } from 'services/peerWebRTC';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  ButtonCloseCall,
  ContainerMyCam,
  Video,
  GiftsButton,
  ContainerVideo,
  ContainerButtons,
  UserBalance,
  LowBalanceWarning,
  OverlayBuyCoins,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function VideoCall(): JSX.Element {
  const [showGifts, setShowGifts] = useState(false);
  const [coinsToBuy, setCoinsToBuy] = useState('');
  const [showOverlayBuyCoins, setShowOverlayBuyCoins] = useState(false);
  const [showLowBalanceWarning, setLowBalanceWarning] = useState(false);
  const [userBalance, setUserBalance] = useState(1510);
  const { addError } = useToast();
  const history = useHistory();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const call = useAppSelector(state => state.call);

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleShowGifts() {
    setShowGifts(!showGifts);
  }

  useEffect(() => {
    async function streamCamVideo() {
      const localStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      const remoteStream = new MediaStream();

      localStream.getTracks().forEach(track => {
        pc.addTrack(track, localStream);
      });
      pc.ontrack = event => {
        event.streams[0].getTracks().forEach(track => {
          remoteStream.addTrack(track);
        });
      };
      const webcamVideo: HTMLVideoElement | null = document.querySelector(
        '.my-cam',
      );
      const remoteVideo: HTMLVideoElement | null = document.querySelector(
        '.remote-cam',
      );
      if (webcamVideo) webcamVideo.srcObject = localStream;
      if (remoteVideo) remoteVideo.srcObject = remoteStream;
    }
    streamCamVideo();
  }, [addError, key]);

  const handleStopStream = useCallback(async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    const webcamVideo: HTMLVideoElement | null = document.querySelector(
      '.my-cam',
    );
    if (webcamVideo) {
      webcamVideo.srcObject = localStream;
      webcamVideo.srcObject.getTracks()[0].stop();
    } else {
      addError(`${messages[key].cantOpenWebcam}`);
    }
  }, [addError, key]);

  useEffect(() => {
    if (user.type === 'DADDY') {
      setTimeout(() => {
        setUserBalance(userBalance - 2);
      }, 1000);

      if (userBalance === 1500 || userBalance === 1501) {
        setLowBalanceWarning(true);
        setTimeout(() => {
          setLowBalanceWarning(false);
        }, 5000);
      }
      if (userBalance === 10 || userBalance === 9)
        addError(`${messages[key].finallyCall}`);
      if (userBalance <= 0) {
        handleStopStream();
        history.go(0);
      }
    } else if (user.type === 'BABY') {
      setTimeout(() => {
        setUserBalance(userBalance + 2);
      }, 1000);
    }
  }, [userBalance, addError, handleStopStream, history, key, user.type]);

  function handleCloseCall() {
    handleStopStream();
    callSocket.emit('ending.call', call.currentCall);
    dispatch(endCall());
    history.go(0);
  }

  return (
    <Container>
      {showLowBalanceWarning && (
        <LowBalanceWarning>
          <div>
            <span>{messages[key].lowBalance}</span>
          </div>
        </LowBalanceWarning>
      )}
      <ContainerVideo theme={{ height: showGifts ? '70%' : '100%' }}>
        <UserBalance
          onClick={() => setShowOverlayBuyCoins(!showOverlayBuyCoins)}
        >
          {messages[key].balance} R${userBalance}
        </UserBalance>
        {showOverlayBuyCoins && user.type === 'DADDY' && (
          <OverlayBuyCoins>
            <h1>{messages[key].buyCoins}</h1>
            <div className="container-input-coins">
              <Input
                name="coins"
                type="number"
                min="50"
                max="2000"
                value={coinsToBuy}
                onChange={e => setCoinsToBuy(e.target.value)}
              />
            </div>
            <span>{messages[key].minValue}</span>
            <p>{messages[key].paymentMethods}</p>
            <select>
              <option value="1">{messages[key].creditCard}</option>
              <option value="2">Pix</option>
              <option value="3">{messages[key].ticket}</option>
            </select>
            <div className="container-buttons">
              <Button size="regular">Cancelar</Button>
              <Button size="regular">Confirmar</Button>
            </div>
          </OverlayBuyCoins>
        )}

        <Video className="remote-cam" autoPlay playsInline id="remoteVideo" />
        <ContainerMyCam
          className="my-cam"
          autoPlay
          playsInline
          muted
          id="webcamVideo"
        />
        <ContainerButtons
          theme={{
            bottom: showGifts && user.type === 'DADDY' ? '20rem' : '6rem',
          }}
        >
          {user.type === 'DADDY' && (
            <GiftsButton onClick={() => handleShowGifts()} />
          )}

          <ButtonCloseCall
            type="button"
            onClick={() => {
              handleCloseCall();
            }}
          />
        </ContainerButtons>
      </ContainerVideo>
      {showGifts && user.type === 'DADDY' && (
        <Gifts handleClose={handleShowGifts} />
      )}
    </Container>
  );
}
