import * as Yup from 'yup';
import { cpf } from 'cpf-cnpj-validator';

export const identificationDataValidator = Yup.object().shape({
  identify: Yup.string().required('Campo obrigatório'),
  income: Yup.string().required('Campo obrigatório'),
  patrimony: Yup.string().required('Campo obrigatório'),
  profession: Yup.string().required('Campo obrigatório'),
  academicEducation: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .test('validate-cpf', 'CPF inválido', value => {
      if (value && cpf.isValid(value)) return true;
      return false;
    })
    .required('Campo obrigatório'),
});
