import { useEffect, useState, useCallback } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { IoIosReturnLeft } from 'react-icons/io';
import { useToast } from 'hooks/toast';
import { mainDataValidator } from 'validators/SignUpValidators/mainData';
import getValidationErrors from 'utils/getValidationErrors';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { usePageSettings } from 'hooks/pagesSettings';
import { useAppSelector, useAppDispatch } from 'hooks';

import { IMessage } from 'interfaces/pagesSettings';

import { setMainData } from 'store/slices/signupSlice';
import { messages, LanguageTranslated } from './translate';

import { Content, DivRadio, CancelButton, ContainerMain, Main } from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUser {
  email: string;
  name: string;
  nickName: string;
  telephone: string;
  date: string;
  userType: string;
}

export function MainData(): JSX.Element {
  const [userData, setUserData] = useState<IUser>({} as IUser);
  const [telephoneMask, setTelephoneMask] = useState<string>('');
  const { addError, addSuccess } = useToast();
  const [errors, setErrors] = useState({});

  const signup = useAppSelector(state => state.signup);
  const dispatch = useAppDispatch();

  const history = useHistory();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setTelephoneMask('(99) 9 9999-9999');
    setUserData({
      name: signup.name,
      email: signup.email,
      nickName: signup.nickname,
      telephone: signup.phone,
      date: signup.bornedDate,
      userType: signup.role,
    });
  }, [signup]);

  const handleSandForm = useCallback(async () => {
    try {
      setErrors({});
      const { name, email, nickName, telephone, date, userType } = userData;
      await mainDataValidator.validate(
        { name, email, nickName, telephone, date },
        { abortEarly: false },
      );
      dispatch(
        setMainData({
          name,
          email,
          nickname: nickName,
          phone: telephone,
          bornedDate: new Date(date).toISOString(),
          type: userType,
        }),
      );

      addSuccess(`${messages[key].successMessage}`);
      history.push('identificacao');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }

      addError(`${messages[key].errorMessage}`);
    }
  }, [userData, addSuccess, addError, history, key, dispatch]);

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <Input
              name="date"
              type="date"
              value={userData.date}
              errors={errors}
              placeholder={messages[key].birthDate}
              onChange={e => setUserData({ ...userData, date: e.target.value })}
            />
            <Input
              name="name"
              type="text"
              placeholder={messages[key].name}
              value={userData.name}
              errors={errors}
              onChange={e => setUserData({ ...userData, name: e.target.value })}
            />
            <Input
              name="email"
              type="email"
              placeholder={messages[key].email}
              value={userData.email}
              errors={errors}
              onChange={e =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
            <Input
              name="nickName"
              type="text"
              placeholder={messages[key].nick}
              value={userData.nickName}
              errors={errors}
              onChange={e =>
                setUserData({ ...userData, nickName: e.target.value })
              }
            />
            <Input
              name="telephone"
              type="masked"
              mask={telephoneMask}
              placeholder={messages[key].telephone}
              value={userData.telephone}
              errors={errors}
              onChange={e =>
                setUserData({ ...userData, telephone: e.target.value })
              }
            />
            <DivRadio>
              <div>
                <input
                  type="radio"
                  value="DADDY"
                  name="user"
                  onChange={e =>
                    setUserData({ ...userData, userType: e.target.value })
                  }
                  checked={userData.userType === 'DADDY'}
                />
                <label>{messages[key].daddy}</label>
              </div>
              <div>
                <input
                  type="radio"
                  value="BABY"
                  name="user"
                  onChange={e =>
                    setUserData({ ...userData, userType: e.target.value })
                  }
                  checked={userData.userType === 'BABY'}
                />
                <label>{messages[key].baby}</label>
              </div>
            </DivRadio>
            <Button
              backgroundColor="primary"
              size="regular"
              onClick={() => handleSandForm()}
            >
              {messages[key].proceed}
            </Button>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
