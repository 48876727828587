import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  slideCounter: string;
  view: string;
  age: string;
  country: string;
  state: string;
  city: string;
  height: string;
  hairColor: string;
  eyeColor: string;
  smoker: string;
  drink: string;
  child: string;
  hetero: string;
  bisexual: string;
  ageInput: string;
  yes: string;
  no: string;
  filter: string;
  viewAgain: string;
  fixBaby: string;
  babyFixed: string;
  selectCountry: string;
  selectState: string;
  selectCity: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Baralho',
    slideCounter: 'de',
    view: 'Ver',
    age: 'anos',
    bisexual: 'bissexual',
    child: 'Filhos:',
    city: 'Cidade:',
    country: 'País:',
    drink: 'Bebe:',
    eyeColor: 'Cor dos olhos:',
    hairColor: 'Cor dos cabelos:',
    height: 'Altura (cm):',
    hetero: 'hétero',
    smoker: 'Fumante:',
    state: 'Estado:',
    ageInput: 'Idade:',
    yes: 'sim',
    no: 'não',
    filter: 'Filtrar',
    fixBaby: 'Fixar baby',
    viewAgain: 'Ver novamente',
    babyFixed: 'Baby fixada!',
    selectCountry: 'Selecione um país',
    selectState: 'Selecione um estado',
    selectCity: 'Selecione uma cidade',
  },
  'en-US': {
    title: 'Cheap',
    slideCounter: 'for',
    view: 'View',
    age: 'years',
    bisexual: 'bisexual',
    child: 'Childs:',
    city: 'City:',
    country: 'Country:',
    drink: 'Drink:',
    eyeColor: 'Eye color:',
    hairColor: 'Hair color:',
    height: 'Height (cm):',
    hetero: 'hetero',
    smoker: 'Smoker:',
    state: 'State:',
    ageInput: 'Age:',
    yes: 'yes',
    no: 'no',
    filter: 'Filter',
    fixBaby: 'Fix baby',
    viewAgain: 'View again',
    babyFixed: 'Baby fixed!',
    selectCountry: 'Select a country',
    selectState: 'Select a state',
    selectCity: 'Select a city',
  },
};
