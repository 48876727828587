import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  bank: string;
  account: string;
  agency: string;
  concluded: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Conta bancária',
    bank: 'Banco',
    account: 'Conta',
    agency: 'Agência',
    concluded: 'Concluído',
  },
  'en-US': {
    title: 'Bank account',
    bank: 'Bank',
    account: 'Account',
    agency: 'Agency',
    concluded: 'Concluded',
  },
};
