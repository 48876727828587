import { Token } from 'interfaces/token';
import { User } from 'interfaces/user';

interface SignIn {
  data: {
    user: User;
    token: Token;
  };
}

export function signInFake(login: string, password: string): Promise<SignIn> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (login === 'daddy@teste.com' && password === 'teste') {
        resolve({
          data: {
            user: {
              id: '1',
              name: 'Marcos Pasquin',
              email: 'daddy@teste.com',
              type: 'DADDY',
            },
            token: {
              access_token:
                'eyJhbIAaishdiqIAIS73MMh8dkosjhd9120h0R5cCI6IkpXVCJ9',
              refresh_token:
                'eyJhbIAaishdiqIAIS73MMh8dkosjhd9120h0R5cCI6IkpXVCJ9',
              refresh_expires: 465154864662156,
            },
          },
        });
      } else if (login === 'baby@teste.com' && password === 'teste') {
        resolve({
          data: {
            user: {
              id: '2',
              name: 'Bruna Marquezini',
              email: 'baby@teste.com',
              type: 'BABY',
            },
            token: {
              access_token:
                'eyJhbIAaishdiqIAIS73MMh8dkosjhd9120h0R5cCI6IkpXVCJ9',
              refresh_token:
                'eyJhbIAaishdiqIAIS73MMh8dkosjhd9120h0R5cCI6IkpXVCJ9',
              refresh_expires: 465154864662156,
            },
          },
        });
      } else {
        reject(new Error('400'));
      }
    }, 2000);
  });
}
