import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)``;

export const Title = styled.span`
  color: var(--primary);
  font-size: 2rem;
  font-weight: 300;

  @media (max-width: 650px) {
    font-size: 1.8rem;
  }
`;

export const Subtitle = styled.h2`
  color: var(--primary);
  font-size: 1.5rem;
  font-weight: 300;
  margin: 2rem 0;
  align-self: flex-start;

  @media (max-width: 650px) {
    font-size: 1.5rem;
  }
`;
export const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
`;

export const Select = styled.select`
  appearance: none;
  position: relative;
  margin-bottom: 1rem;
  border: none;
  width: 15rem;
  height: 2.64rem;
  color: var(--light-gray);
  border-bottom: 0.0625rem solid var(--light-gray);
  background-color: transparent;
  padding-left: 0.5rem;
  font-size: 1.2rem;

  ::placeholder {
    color: var(--light-gray);
  }

  option {
    color: var(--graphite);
    background-color: var(--light-gray);

    ::selection {
      background-color: black;
    }
  }
`;

export const ContainerCoins = styled.div`
  background-color: var(--blue-card);
  border: solid 1px var(--light-gray);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 30rem;
  margin-top: 2rem;
  padding: 2rem;
  align-items: center;
  @media (max-width: 450px) {
    padding: 1rem;
    width: 20rem;
  }

  p {
    color: var(--light-gray);
    font-size: 1.7rem;
    line-height: 2rem;
    text-align: center;
    margin: 2rem 0;
  }

  span {
    color: var(--light-gray);
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
  }
`;

export const InputCoin = styled.input`
  width: 8rem;
  font-size: 2.5rem;
  text-align: center;
  color: var(--light-gray);
  background-color: transparent;
  border: none;
  border-bottom: solid 1px var(--light-gray);

  margin-bottom: 1rem;
`;

export const ContainerButton = styled.div`
  width: 12rem;
  margin-top: 4rem;

  @media (max-height: 650px) {
    margin-top: 2rem;
  }
`;
