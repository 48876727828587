import * as Yup from 'yup';

export const mainDataValidator = Yup.object().shape({
  email: Yup.string()
    .required('Campo obrigatório')
    .email('Endereço de email inválido'),
  name: Yup.string().required('Campo obrigatório'),
  date: Yup.string().required('Campo obrigatório'),
  nickName: Yup.string().required('Campo obrigatório'),
  telephone: Yup.string().required('Campo obrigatório'),
});
