import styled from 'styled-components';
import { Button } from 'components/Button';
import { RiCloseCircleLine } from 'react-icons/ri';
import Background from 'pages/Auth/_Background';

export const ContainerMain = styled(Background)``;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.div`
  font-size: 17px;
  color: var(--white);
  margin: 5px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
`;

export const ContainerAllImage = styled.div`
  display: flex;
  width: 120%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 200px;
  justify-content: center;
  position: relative;
  margin-top: 10px;
  overflow: hidden;
  input[type='file'] {
    display: none;
  }

  img {
    width: 140px;
    height: 200px;
  }
`;

export const Label = styled.label`
  flex: 1 1 300px;
  padding: 100px 70px;
  position: absolute;
  background-color: transparent;
  cursor: pointer;
`;

export const ButtonProceed = styled(Button)`
  margin-top: 20px;
`;

export const TextArea = styled.textarea`
  padding: 10px;
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  height: 10vh;
`;
export const CloseImage = styled(RiCloseCircleLine)`
  align-self: flex-end;
  color: var(--remove);
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const UploadedImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const CancelButton = styled(Button)`
  margin-top: 15px;
`;
