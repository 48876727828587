import { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import { Container } from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  children: string;
  size?: 'small' | 'regular' | 'large';
  backgroundColor?: 'primary' | 'secondary';
}

export function Button({
  loading = false,
  children,
  size = 'small',
  backgroundColor = 'primary',
  ...rest
}: ButtonProps): JSX.Element {
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  return (
    <Container
      size={size}
      disabled={loading}
      {...rest}
      theme={{ buttonColor: backgroundColor }}
    >
      <span>{loading ? messages[key].loading : children}</span>
    </Container>
  );
}
