import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  myDaddys: string;
  myBabys: string;
  writeAMessage: string;
  all: string;
  searchDaddy: string;
  searchBaby: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    all: 'Todos os contatos',
    myBabys: 'Babys',
    myDaddys: 'Meus daddys convidados',
    writeAMessage: 'Escreva uma mensagem...',
    searchDaddy: 'Pesquisar daddy',
    searchBaby: 'Pesquisar baby',
  },
  'en-US': {
    all: 'All contacts',
    myBabys: 'Babys',
    myDaddys: 'My guest daddys',
    writeAMessage: 'Write a message...',
    searchDaddy: 'Search daddy',
    searchBaby: 'Search baby',
  },
};
