import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { IoMdArrowRoundBack } from 'react-icons/io';

export const Container = styled(DefaultPage)``;
export const Main = styled.div`
  flex-direction: row;
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 100%;
  height: 100%;

  @media (max-width: 950px) {
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  color: var(--dark-gray);
  font-weight: 300;
  margin-bottom: 1rem;

  @media (max-width: 650px) {
    font-size: 2.5rem;
  }
`;

export const ContainerSettingsSections = styled.div`
  flex-direction: column;
  min-width: 425px;

  @media (max-width: 950px) {
    width: 100%;
    min-width: 0;
  }
`;

export const ContainerContentSections = styled.div`
  border-radius: 15px;
  background-color: var(--graphite);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 93%;
  padding-top: 2rem;
  width: 100%;

  @media (max-width: 950px) {
    height: auto;
  }
`;

export const ButtomSection = styled.button`
  width: 80%;
  background-color: var(--secondary);
  border-radius: 25px 25px 0 0;
  padding: 1rem 2rem;
  display: flex;
  cursor: pointer;

  border: none;
  margin-bottom: 1rem;

  @media (max-width: 2000px) {
    width: 90%;
  }

  @media (max-width: 950px) {
    padding-left: 30%;
  }

  @media (max-width: 650px) {
    padding-left: 20%;
  }

  @media (max-width: 500px) {
    padding-left: 15%;
  }

  @media (max-width: 475px) {
    padding-left: 5%;
  }

  span {
    color: var(--dark-gray);
    font-size: 1.8rem;
    margin-left: 2rem;
  }

  :last-child {
    span {
      font-size: 1.6rem;
    }
  }
`;

export const ContainerModal = styled.div`
  background-color: var(--graphite);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 4.2rem;
  margin-left: 4.2rem;
  width: 95%;
  height: 93.5%;

  @media (max-width: 1500px) {
    margin-left: 3rem;
  }
  @media (max-width: 1300px) {
    margin-left: 2rem;
  }
  @media (max-width: 1100px) {
    margin-left: 1rem;
    width: 90%;
  }
  @media (max-width: 950px) {
    width: 100%;
    height: auto;
    margin-left: 0;
    padding-bottom: 1rem;
  }

  @media (max-height: 700px) {
    margin-top: 1rem;
  }
`;

export const TitleModal = styled.div`
  width: 100%;
  border-radius: 15px 15px 0 0;
  border: solid 2px var(--dark-gray);
  display: flex;
  background-color: var(--secondary);
  padding: 0.5rem 2rem;

  @media (max-width: 400px) {
    padding: 0.5rem 1rem;
  }

  h2 {
    color: var(--dark-gray);
    width: 100%;
    text-align: center;
    font-size: 3rem;
    font-weight: 300;
  }
`;

export const Option = styled.div`
  width: 100%;
  border: solid 2px var(--dark-gray);
  display: flex;
  background-color: var(--secondary);
  padding: 0.5rem 2rem;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 200ms;

  @media (max-width: 400px) {
    padding: 0.5rem 1rem;
  }

  :hover {
    background-color: var(--primary);
    span {
      color: var(--graphite);
    }
  }

  img {
    width: 50px;
  }

  span {
    color: var(--dark-gray);
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 300;
  }
`;

export const ContentModal = styled.div`
  background-color: var(--secondary);
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 2rem auto;

  @media (max-width: 950px) {
    height: auto;
  }
`;

export const ModalContainerInput = styled.div`
  display: flex;
  align-items: center;
  width: 25rem;

  @media (max-width: 400px) {
    width: 15rem;
  }

  span {
    font-size: 1.5rem;
    color: var(--dark-gray);
    margin-right: 1rem;
    width: 25rem;
  }
`;

export const ModalInput = styled(Input)``;

export const ModalButton = styled(Button)`
  width: 20rem;
  margin: 2rem 0;

  @media (max-width: 400px) {
    width: 15rem;
  }
`;

export const ButtonReturn = styled(IoMdArrowRoundBack)`
  display: none;
  fill: var(--dark-gray);
  cursor: pointer;

  @media (max-width: 950px) {
    display: block;
  }
`;
