export function sanitizeDocument(document: string): string {
  return document.replace(/[._/]/g, '').replace(/[-]/g, '').replace(/\s/g, '');
}
export function sanitizePhone(phone: string): string {
  return phone
    .replace(/[.]/g, '')
    .replace(/[()]/g, '')
    .replace(/[-]/g, '')
    .replace(/\s/g, '');
}
