import styled from 'styled-components';
import { Button } from 'components/Button';
import Background from 'pages/Auth/_Background';

export const ContainerMain = styled(Background)``;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div``;

export const InputContainer = styled.div`
  margin-bottom: 30px;
`;

export const CancelButton = styled(Button)`
  margin-top: 15px;
  :active {
    background-color: red;
  }
`;
