import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  limit: string;
  minValue: string;
  request: string;
  error: string;
  success: string;
  placeholder: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Solicitar resgate',
    limit: 'Você pode solicitar resgates uma vez por semana.',
    minValue: 'Valor mínimo: R$ 500,00',
    request: 'Solicitar',
    error: 'O valor mínimo de resgate é R$500,00',
    success: 'O dinheiro estará disponivel na sua conta em até 48h',
    placeholder: 'Insira um valor',
  },
  'en-US': {
    title: 'Request redemption',
    limit: 'You can request redemptions once a week.',
    minValue: 'Minimum amount of $500,00',
    request: 'Request',
    error: 'The minimum amount to request is $500,00',
    success: 'The money will be available in your account within 48h',
    placeholder: 'Put a value',
  },
};
