import { useState, useEffect, useCallback } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { Button } from 'components/Button';
import defaultImage from 'assets/images/img/background_image.png';
import { readUploadedFile } from 'helpers/upload';
import { useToast } from 'hooks/toast';
import { IoIosReturnLeft } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  Title,
  ContainerImage,
  Label,
  CloseImage,
  UploadedImage,
  CancelButton,
  ContainerMain,
  Main,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function ImageData(): JSX.Element {
  const { addError, addSuccess } = useToast();
  const history = useHistory();

  const [urlImage, setUrlImage] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<string>();
  const [uploadFile, setUploadFile] = useState<File>();
  const [inProgress, setInProgress] = useState(false);
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  const removeImage = () => {
    setUrlImage(defaultImage);
  };

  useEffect(() => {
    const handleProcess = async () => {
      try {
        if (uploadFile) {
          const promise = await readUploadedFile(uploadFile);
          setSelectedFile(promise);
        }
        if (selectedFile?.length) addSuccess('Imagem carregada com sucesso!!');
      } catch (err) {
        addError('Erro ao carregar a imagem');
      } finally {
        setInProgress(false);
      }
    };

    if (uploadFile) handleProcess();
  }, [uploadFile, addError, addSuccess, selectedFile]);

  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (e.target.files) {
        const { files } = e.target;
        setUploadFile(files[0]);
        setInProgress(true);
      }
    },
    [],
  );
  useEffect(() => {
    if (selectedFile) setUrlImage(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    setUrlImage(defaultImage);
  }, []);

  function handleSandForm() {
    if (selectedFile?.length) {
      addSuccess(`${messages[key].successMessage}`);
      history.push('video');
    } else addError(`${messages[key].errorMessage}`);
  }

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <Title>
              {messages[key].text} <br />
              <br /> {'"Suggar World 2021"'}{' '}
            </Title>
            <ContainerImage>
              {urlImage === defaultImage ? (
                <>
                  <img src={urlImage} alt="" />
                  <Label htmlFor="image" />
                  <input
                    name="image"
                    type="file"
                    id="image"
                    accept="image/*"
                    multiple
                    onChange={e => handleImageChange(e)}
                  />
                </>
              ) : (
                <>
                  <CloseImage
                    fill="var(--remove)"
                    onClick={() => removeImage()}
                  />
                  <UploadedImage src={urlImage} alt="" />
                </>
              )}
            </ContainerImage>
            <Button
              backgroundColor="primary"
              loading={inProgress}
              size="regular"
              onClick={() => handleSandForm()}
            >
              {messages[key].proceed}
            </Button>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
