import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  document: string;
  cpf: string;
  income: string;
  patrimony: string;
  profession: string;
  formation: string;
  proceed: string;
  cancel: string;
  successMessage: string;
  errorMessage: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    document: 'Identidade',
    cpf: 'CPF',
    income: 'Renda',
    formation: 'Formação acadêmica',
    patrimony: 'Patrimônio',
    profession: 'Profissão',
    proceed: 'Prosseguir',
    cancel: 'Cancelar',
    errorMessage: 'Não foi possível validar os dados',
    successMessage: 'Dados validados',
  },
  'en-US': {
    document: 'Identity',
    cpf: 'CPF',
    income: 'Income',
    formation: 'Formation',
    patrimony: 'Patrimony',
    profession: 'Profession',
    proceed: 'Proceed',
    cancel: 'Cancel',
    errorMessage: 'It was not possible to validate the fields',
    successMessage: 'Validated fields',
  },
};
