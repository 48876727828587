export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const LANGUAGE = '@suggar-bank-user:language';
export const CURRENCY = '@suggar-bank-user:currency';
export const USER = '@suggar-bank-user:user';
export const ACCESS_TOKEN = '@suggar-bank-user:token';
export const TOKEN = '@suggar-bank-user:token';
export const EXPIRES_TOKEN = '@suggar-bank-user:expiresToken';
export const REFRESH_TOKEN = '@suggar-bank-user:refreshToken';
