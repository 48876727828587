import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)``;

export const Title = styled.span`
  color: var(--primary);
  font-size: 2rem;
  font-weight: 300;

  @media (max-width: 650px) {
    font-size: 1.8rem;
  }
`;

export const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding-top: 8rem;

  @media (max-width: 560px) {
    width: 100%;
  }
  @media (max-height: 570px) {
    padding-top: 4rem;
  }
`;

export const Text = styled.h2`
  color: var(--light-gray);
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
`;

export const ContainerValue = styled.div`
  background-color: var(--blue-card);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin-top: 4rem;

  div.containerInput {
    width: 70%;
  }

  span {
    color: var(--light-gray);
    font-size: 1.2rem;
    margin-top: 1rem;
  }
`;

export const ContainerButton = styled.div`
  width: 10rem;
  margin-top: 5rem;
`;

export const OverlaySuccess = styled.div`
  border-radius: 10px;
  width: 15rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blue-card);
  justify-content: flex-end;
  position: absolute;
  top: calc(50% - 5rem);
  right: calc(50% - 7.5rem);
  border: solid 1px var(--gray);

  span {
    color: var(--light-gray);
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
    margin-bottom: 2rem;
  }

  button {
    margin-bottom: 0;
    height: 2rem;
  }
`;
