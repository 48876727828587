import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  titleDaddy: string;
  titleBaby: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    titleBaby: 'Meus daddys convidados',
    titleDaddy: 'Minhas babys',
  },
  'en-US': {
    titleBaby: 'My guest daddys',
    titleDaddy: 'My babbys',
  },
};
