import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  cancel: string;
  change: string;
  remove: string;
  requestReview: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    cancel: 'Cancelar',
    change: 'Trocar',
    remove: 'Remover',
    requestReview: 'Solicitar análise',
  },
  'en-US': {
    cancel: 'Cancel',
    change: 'Change',
    remove: 'Remove',
    requestReview: 'Request review',
  },
};
