import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  text: string;
  proceed: string;
  cancel: string;
  successMessage: string;
  errorMessage: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    text: 'Adicione aqui uma foto segurando um cartaz escrito:',
    proceed: 'Prosseguir',
    cancel: 'Cancelar',
    errorMessage: 'Você deve adicionar uma imagem',
    successMessage: 'Dados validados',
  },
  'en-US': {
    text: 'Add here a photo holding a written sign:',
    proceed: 'Proceed',
    cancel: 'Cancel',
    errorMessage: 'You must add one image',
    successMessage: 'Validated fields',
  },
};
