import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background: var(--blue-dark);
  align-items: center;
  overflow-y: scroll;
  padding: 2rem;
  padding-top: 7rem;

  @media (max-width: 600px) {
    padding: 7rem 0.5rem 2rem;
  }
`;
