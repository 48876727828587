/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider as NotificationsProvider } from 'react-toast-notifications';
import {
  Provider,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';

import type { RootState, AppDispatch } from 'store';
import store from 'store/index';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { PageSettingsProvider } from './pagesSettings';

interface AppProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const AppProvider = ({ children }: AppProviderProps): JSX.Element => {
  return (
    <BrowserRouter>
      <NotificationsProvider>
        <ToastProvider>
          <PageSettingsProvider>
            <Provider store={store}>
              <AuthProvider>{children}</AuthProvider>
            </Provider>
          </PageSettingsProvider>
        </ToastProvider>
      </NotificationsProvider>
    </BrowserRouter>
  );
};
