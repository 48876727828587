import { useEffect, useState } from 'react';
import chocolate from 'assets/images/svg/chocolate.svg';
import { useToast } from 'hooks/toast';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import api from 'services/api';
import { messages, LanguageTranslated } from './translate';

import {
  ContainerGifts,
  CloseButton,
  Gift,
  ImageGift,
  Container,
  ErrorLoadGifts,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IProps {
  handleClose: () => void;
}

interface IDataGifts {
  _id: string;
  title: string;
  description: string;
  value: number;
}

export function Gifts({ handleClose }: IProps): JSX.Element {
  const [gifts, setGifts] = useState<Array<IDataGifts>>(
    [] as Array<IDataGifts>,
  );
  const { addSuccess, addError } = useToast();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    async function getDataGifts() {
      try {
        const response = await api.get('/gifts/gifts');
        setGifts(response.data);
      } catch (err) {
        addError('Erro ao carregar gifts');
      }
    }
    getDataGifts();
  }, [addError]);

  return (
    <Container>
      <div>
        <CloseButton onClick={() => handleClose()} />
      </div>
      <ContainerGifts>
        {gifts.length &&
          gifts.map(obj => {
            return (
              <Gift
                key={obj._id}
                onClick={() => addSuccess(`${messages[key].send}`)}
              >
                <ImageGift src={chocolate} alt={obj.title} />
                <span>R$ {obj.value}</span>
              </Gift>
            );
          })}
        {!gifts.length && (
          <ErrorLoadGifts>
            <span>{messages[key].error}</span>
          </ErrorLoadGifts>
        )}
      </ContainerGifts>
    </Container>
  );
}
