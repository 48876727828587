import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  text: string;
  placeholder: string;
  block: string;
  concluded: string;
  message: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Privacidade',
    text:
      'Selecione até cinco paises, cinco estados e/ou até vinte cidades em que você não quer aparecer.',
    placeholder: 'país/cidade/estado',
    block: 'Bloquear',
    concluded: 'Concluído',
    message: 'Alterações salvas',
  },
  'en-US': {
    title: 'Privacity',
    text: `Select up to five countries, five states and/or up to twenty cities you don't want to appear in.`,
    placeholder: 'country/city/state',
    block: 'Block',
    concluded: 'Concluded',
    message: 'saved changes',
  },
};
