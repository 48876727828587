import { useState, useEffect } from 'react';
import { colors } from 'styles/colors';
import { IUserInfo } from 'interfaces/cardUserInfo';
import { PerfilExpanded } from 'components/PerfilExpanded';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  ButtonRemove,
  ButtonSendMessage,
  Container,
  ContainerPerfilUser,
  Card,
  UserInformation,
  ImageCard,
  Status,
  ViewVideoButton,
  ContainerBabbyButtons,
  ContainerButtonSendMessage,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ICardProps {
  userType: 'BABY' | 'DADDY';
  userInfo: IUserInfo;
}

export function UserCard({ userType, userInfo }: ICardProps): JSX.Element {
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const [perfilExpanded, setPerfilExpanded] = useState(false);

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleClose(display: boolean) {
    setPerfilExpanded(display);
  }

  return (
    <Container key={userInfo.id}>
      {userType === 'DADDY' && (
        <Card>
          <div className="container">
            <ContainerPerfilUser onClick={() => handleClose(true)}>
              <div>
                <ImageCard />
              </div>
              <UserInformation>
                <span>{userInfo.name}</span>
                <span>{userInfo.city}</span>
                <span>
                  {userInfo.age} {messages[key].age}
                </span>
              </UserInformation>
            </ContainerPerfilUser>

            <ButtonRemove>{messages[key].removeDaddy}</ButtonRemove>
          </div>
          <div className="container">
            <div className="childOne">
              {userInfo?.status && (
                <ContainerButtonSendMessage>
                  <ButtonSendMessage size="small">
                    {messages[key].sendMessage}
                  </ButtonSendMessage>
                </ContainerButtonSendMessage>
              )}
            </div>
            <div className="childTwo">
              <Status
                theme={{
                  backgroundColor: userInfo?.status
                    ? colors.success
                    : colors.danger,
                }}
              >
                {userInfo?.status
                  ? messages[key].statusActive
                  : messages[key].statusInactive}
              </Status>
            </div>
          </div>
        </Card>
      )}
      {userType === 'BABY' && (
        <Card>
          <div className="container">
            <ContainerPerfilUser onClick={() => handleClose(true)}>
              <div>
                <ImageCard />
              </div>
              <UserInformation>
                <span>{userInfo.name}</span>
                <span>{userInfo.city}</span>
                <span>{userInfo.age} anos</span>
              </UserInformation>
            </ContainerPerfilUser>
            <ContainerBabbyButtons>
              <ButtonRemove>{messages[key].seeSpicyImage}</ButtonRemove>
              <ViewVideoButton>{messages[key].viewVideo}</ViewVideoButton>
            </ContainerBabbyButtons>
          </div>
          <div className="container">
            <div className="childOne">
              <ContainerButtonSendMessage>
                <ButtonSendMessage size="small">
                  {messages[key].sendMessage}
                </ButtonSendMessage>
              </ContainerButtonSendMessage>
            </div>
          </div>
        </Card>
      )}
      {perfilExpanded && (
        <PerfilExpanded
          age={userInfo.age}
          city={userInfo.city}
          country={userInfo.country}
          state={userInfo.state}
          nick={userInfo.name}
          status={userInfo.status ? 'Ativo' : 'Inativo'}
          handleClose={handleClose}
        />
      )}
    </Container>
  );
}
