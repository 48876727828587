import { useEffect, useState } from 'react';
import flagBrasil from 'assets/images/img/brl_flag.png';
import flagEua from 'assets/images/img/usd_flag.png';
import { FaGlobeAmericas } from 'react-icons/fa';
import { colors } from 'styles/colors';
import { IModalDisplay } from 'interfaces/ModalDisplay';
import { usePageSettings } from 'hooks/pagesSettings';
import { useToast } from 'hooks/toast';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import { ContainerModal, TitleModal, Option, ButtonReturn } from '../styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ModalProps {
  closeModal: (prop: keyof IModalDisplay, action: 'open' | 'close') => void;
}

export function Language({ closeModal }: ModalProps): JSX.Element {
  const { addSuccess } = useToast();
  const { handleSetLanguage, language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleCloseModal() {
    closeModal('language', 'close');
  }

  function handleSaveLanguage(languageSelected: string) {
    handleSetLanguage(languageSelected);
    addSuccess(`${messages[key].message}`);
  }

  return (
    <ContainerModal>
      <TitleModal>
        <FaGlobeAmericas fill={colors.darkGray} size={50} />
        <h2>{messages[key].title}</h2>
        <ButtonReturn size={40} onClick={() => handleCloseModal()} />
      </TitleModal>
      <Option onClick={() => handleSaveLanguage('pt-BR')}>
        <img src={flagBrasil} alt="pt-BR" />
        <span>{messages[key].portuguese}</span>
      </Option>
      <Option onClick={() => handleSaveLanguage('en-US')}>
        <img src={flagEua} alt="en-US" />
        <span>{messages[key].englishUs}</span>
      </Option>
    </ContainerModal>
  );
}
