import styled from 'styled-components';

export const ContainerInputRadio = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }

  div {
    width: 10rem;

    @media (max-width: 600px) {
      margin-bottom: 1rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    label {
      font-size: 1.3rem;
      color: var(--dark-gray);
      margin-right: 1rem;
    }

    input {
      color: var(--dark-gray);
      background-color: var(--dark-gray);
    }
  }
`;

export const TextArea = styled.textarea`
  width: 90%;
  border-radius: 25px;
  border: solid 2px var(--light-gray);
  background-color: var(--graphite);
  color: var(--light-gray);
  text-align: center;
  margin-top: 2rem;
  height: 10rem;
  padding-top: 2rem;
  font-size: 1.3rem;
`;
