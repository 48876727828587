import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  send: string;
  error: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    send: 'Presente enviado!',
    error: 'Ops! Erro ao carregar os gifts 😥',
  },
  'en-US': {
    send: 'Gift sent!',
    error: 'Ops! Error loading gifts 😥',
  },
};
