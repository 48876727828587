import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';
import { IoFilter } from 'react-icons/io5';
import { Input } from 'components/Input';

export const Container = styled(DefaultPage)``;

export const Title = styled.span`
  color: var(--primary);
  font-size: 2rem;
  font-weight: 300;

  @media (max-width: 650px) {
    font-size: 1.8rem;
  }
`;

export const Line = styled.hr`
  border-color: var(--primary);
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CheapCount = styled.h2`
  color: var(--light-gray);
  margin: 1rem 0;
`;

export const ContainerCards = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 1rem 0 2rem;
  align-items: center;

  @media (max-width: 1100px) {
    width: 80%;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const Card = styled.img`
  top: auto;
  @media (max-width: 500px) {
    width: 20rem;
  }
  @media (max-width: 400px) {
    width: 16rem;
  }
  @media (max-width: 350px) {
    width: 13rem;
  }
`;

export const ContainerTextInfo = styled.div`
  margin-bottom: 1rem;
  p {
    font-size: 1.5rem;
    color: var(--light-gray);
    text-align: center;
    line-height: 2rem;
  }
`;

export const Description = styled.p`
  font-size: 1.2rem;
  color: var(--light-gray);
  text-align: center;
`;

export const ContainerButton = styled.div`
  width: 7rem;
  margin: 2rem 0;
`;

export const BabyImage = styled.div`
  background-color: var(--dark-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 22rem;
  height: 29rem;

  @media (max-width: 500px) {
    width: 20rem;
    height: 26rem;
  }
  @media (max-width: 400px) {
    width: 16rem;
    height: 23rem;
  }
  @media (max-width: 350px) {
    width: 13rem;
    height: 19rem;
  }

  h1 {
    color: var(--light-gray);
  }
`;

export const FilterButton = styled(IoFilter)`
  align-self: flex-end;
  margin-top: 2rem;
  margin-right: 2rem;
  fill: var(--primary);
  cursor: pointer;

  @media (max-width: 600px) {
    margin-top: 1rem;
    margin-right: 1rem;
  }
`;

export const ContainerFilter = styled.div`
  width: 25rem;
  height: 35rem;
  background-color: var(--blue-regular);
  border-radius: 10px;
  position: absolute;
  top: 10rem;
  right: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: solid 1px var(--light-gray);
  overflow: auto;

  @media (max-width: 500px) {
    right: 3rem;
    top: 13rem;
  }
  @media (max-width: 400px) {
    width: 20rem;
  }

  @media (max-height: 680px) {
    height: 30rem;
  }
`;

export const Select = styled.select`
  appearance: none;
  position: relative;
  margin-bottom: 1rem;
  border: none;
  width: 85%;
  height: 2.64rem;
  color: var(--light-gray);
  border-bottom: 0.0625rem solid var(--light-gray);
  background-color: transparent;
  padding-left: 0.5rem;
  font-size: 1.2rem;

  ::placeholder {
    color: var(--light-gray);
  }

  option {
    color: var(--graphite);
    background-color: var(--light-gray);

    ::selection {
      background-color: black;
    }
  }
`;

export const ContainerFilterInput = styled.div`
  width: 85%;
  display: flex;
  justify-content: center;
`;

export const FilterInput = styled(Input)``;

export const ContainerInputRadio = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;

  div {
    margin-bottom: 0.5rem;
  }
  label {
    font-size: 1.3rem;
    color: var(--gray);
    margin-right: 10px;
    width: 50%;
  }

  label.option {
    color: white;
    margin-right: 15px;
    margin-left: 10px;
    font-size: 1rem;
  }
  span {
    color: white;
    margin-right: 15px;
    margin-left: 10px;
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
`;

export const ContainerInputRange = styled.div`
  width: 90%;
  margin: 5rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: var(--light-gray);
  }
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span {
      color: var(--light-gray);
      width: 10%;
    }

    div {
      width: 70%;
    }
  }
`;

export const InputRange = styled.input`
  width: 100%;
  border: none;

  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    transition: 0.2s;
    background: var(--primary);
    border-radius: 25px;
  }

  ::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
  }
`;

export const ContainerInput = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 400px) {
    width: 80%;
  }
  span {
    color: var(--dark-gray);
    margin-right: 0.5rem;
    font-size: 1.3rem;
  }
`;

export const ContainerButtonsAfterViewPhoto = styled.div`
  display: flex;
  width: 15rem;
  justify-content: space-between;
  margin-top: 2rem;
  div {
    width: 7rem;
  }
`;
