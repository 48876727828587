import styled from 'styled-components';
import { Button } from 'components/Button';

export const Container = styled.div``;

export const Card = styled.div`
  border-radius: 1rem;
  border: solid 2px var(--regular-gray);
  background-color: var(--blue-card);
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  height: auto;
  width: 36rem;
  justify-content: space-between;
  margin-bottom: 2rem;

  div.container {
    display: flex;

    @media (max-width: 550px) {
      flex-direction: column;
      width: 100%;
    }

    :first-child {
      justify-content: space-between;
      align-items: center;
    }

    :last-child {
      display: flex;
      align-items: flex-end;
      @media (max-width: 550px) {
        margin-top: 0;
      }
    }
    div.childOne {
      display: flex;
      flex: 1;
      justify-content: center;
      @media (max-width: 550px) {
        width: 100%;
        padding-top: 1rem;
      }
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding-left: 1rem;
  }

  @media (max-width: 530px) {
    width: 25rem;
  }
  @media (max-width: 430px) {
    width: 20rem;
  }
`;

export const ContainerPerfilUser = styled.div`
  display: flex;
  width: 20rem;
  cursor: pointer;
  @media (max-width: 550px) {
    width: 100%;
    padding-top: 2rem;
    padding-left: 2rem;
  }
  @media (max-width: 400px) {
    width: 100%;
    padding-top: 1rem;
    padding-left: 0rem;
  }
`;

export const ImageCard = styled.div`
  width: 5rem;
  height: 5rem;
  background-color: var(--white);
  border: solid 2px var(--regular-gray);
  border-radius: 50%;
`;

export const UserInformation = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: var(--white);
    margin-left: 1rem;
    font-size: 1rem;
    line-height: 1rem;
    font-style: italic;

    :first-child {
      font-size: 1.5rem;
      line-height: 2rem;
      font-style: normal;
    }
  }
`;

export const ContainerButtonSendMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 13rem;
  margin-bottom: 0;

  @media (max-width: 550px) {
    width: 100%;
    height: auto;
    align-items: center;
    margin-bottom: 1rem;
  }
`;

export const ButtonSendMessage = styled(Button)`
  align-self: flex-end;
  margin-bottom: 0;

  @media (max-width: 550px) {
    width: 70%;
    border-radius: 4rem;
  }

  @media (max-width: 500px) {
    width: 70%;
  }
`;

export const ContainerButtomStatus = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 550px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

export const ContainerBabbyButtons = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;

  @media (max-width: 550px) {
    height: 10rem;
    width: 100%;
    margin-top: 2rem;
  }
`;

export const ButtonRemove = styled.button`
  background-color: var(--remove);
  color: var(--white);
  padding: 0.4rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.5rem 0 0 0.5rem;
  width: 12rem;

  @media (max-width: 550px) {
    border-radius: 1rem;
    width: 70%;
    align-self: center;
    margin-top: 2rem;
  }
  @media (max-width: 500px) {
    width: 70%;
  }
`;

export const Status = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  color: var(--white);
  padding: 0.4rem;
  border-radius: 0.9rem 0 0.9rem 0;
  font-size: 1rem;
  width: 7rem;
  text-align: center;
  right: 0;
  @media (max-width: 550px) {
    margin-top: 2rem;
  }
`;

export const ViewVideoButton = styled(ButtonRemove)`
  background-color: #4d8ad6;
  margin-top: 0.5rem;
  @media (max-width: 550px) {
    margin-top: 0;
  }
`;
