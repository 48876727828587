import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  loading: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    loading: 'Carregando...',
  },
  'en-US': {
    loading: 'Loading...',
  },
};
