import { useState, useEffect, useCallback } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { IoIosReturnLeft } from 'react-icons/io';
import { useToast } from 'hooks/toast';
import { identificationDataValidator } from 'validators/SignUpValidators/identificationData';
import getValidationErrors from 'utils/getValidationErrors';
import { useHistory } from 'react-router-dom';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setIdentificantionData } from 'store/slices/signupSlice';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  InputContainer,
  CancelButton,
  ContainerMain,
  Main,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUserData {
  identify: string;
  cpf: string;
  income: string;
  patrimony: string;
  academicEducation: string;
  profession: string;
}

export function IdentificationData(): JSX.Element {
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const [identifyMask, setIdentifyMask] = useState<string>('');
  const [cpfMask, setCpfMask] = useState<string>('');
  const signup = useAppSelector(state => state.signup);
  const dispatch = useAppDispatch();

  const { addError, addSuccess } = useToast();
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setIdentifyMask('99.999.999');
    setCpfMask('999.999.999-99');
  }, []);

  useEffect(() => {
    setUserData({
      identify: signup.identityDocument,
      cpf: signup.document,
      income: signup.metadata.income,
      patrimony: signup.metadata.patrimony,
      profession: signup.metadata.profession,
      academicEducation: signup.metadata.academicFormation,
    });
  }, [signup]);

  const handleSendForm = useCallback(async () => {
    try {
      setErrors({});
      const {
        identify,
        cpf,
        income,
        patrimony,
        academicEducation,
        profession,
      } = userData;
      await identificationDataValidator.validate(
        {
          identify,
          cpf,
          income,
          patrimony,
          academicEducation,
          profession,
        },
        { abortEarly: false },
      );

      dispatch(
        setIdentificantionData({
          identityDocument: identify,
          document: cpf,
          academicFormation: academicEducation,
          profession,
          patrimony,
          income,
        }),
      );

      addSuccess(`${messages[key].successMessage}`);
      history.push('endereco');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }

      addError(`${messages[key].errorMessage}`);
    }
  }, [userData, addSuccess, addError, history, key, dispatch]);

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <InputContainer>
              <Input
                name="identify"
                placeholder={messages[key].document}
                errors={errors}
                type="masked"
                mask={identifyMask}
                value={userData.identify}
                onChange={e =>
                  setUserData({ ...userData, identify: e.target.value })
                }
              />
              <Input
                name="cpf"
                placeholder={messages[key].cpf}
                errors={errors}
                type="masked"
                mask={cpfMask}
                value={userData.cpf}
                onChange={e =>
                  setUserData({ ...userData, cpf: e.target.value })
                }
              />
              <Input
                name="income"
                placeholder={messages[key].income}
                errors={errors}
                type="currency"
                prefix="R$ "
                value={userData.income}
                onValueChange={value => {
                  if (value) {
                    setUserData({ ...userData, income: value });
                  } else {
                    setUserData({ ...userData, income: '' });
                  }
                }}
              />
              <Input
                name="patrimony"
                placeholder={messages[key].patrimony}
                errors={errors}
                type="currency"
                prefix="R$ "
                value={userData.patrimony}
                onValueChange={value => {
                  if (value) {
                    setUserData({ ...userData, patrimony: value });
                  } else {
                    setUserData({ ...userData, patrimony: '' });
                  }
                }}
              />
              <Input
                name="profession"
                placeholder={messages[key].profession}
                errors={errors}
                value={userData.profession}
                onChange={e =>
                  setUserData({ ...userData, profession: e.target.value })
                }
              />
              <Input
                name="academicEducation"
                placeholder={messages[key].formation}
                errors={errors}
                value={userData.academicEducation}
                onChange={e =>
                  setUserData({
                    ...userData,
                    academicEducation: e.target.value,
                  })
                }
              />
            </InputContainer>
            <Button
              backgroundColor="primary"
              size="regular"
              onClick={() => handleSendForm()}
            >
              {messages[key].proceed}
            </Button>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
