import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import {
  MdKeyboardArrowLeft,
  MdHome,
  MdAccountCircle,
  MdAttachMoney,
} from 'react-icons/md';
import {
  IoPersonAdd,
  IoPeopleCircle,
  IoSettingsOutline,
} from 'react-icons/io5';
import LogoImg from 'assets/images/img/suggar_bank_logo.png';
import deck from 'assets/images/svg/deck.svg';
import { useMenu } from 'hooks/menu';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Menu,
  CloseButton,
  MenuIcon,
  ListMenu,
  LogoContainer,
  ButtonMenu,
  Background,
  ContainerUserInfo,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function DrawerMenu(): JSX.Element {
  const history = useHistory();
  const { openDrawer, handleDisplayDrawer } = useMenu();
  const [babyMenu, setBabyMenu] = useState<Array<JSX.Element>>([]);
  const [daddyMenu, setDaddyMenu] = useState<Array<JSX.Element>>([]);
  const iconSize = 50;
  const { user } = useAuth();

  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setBabyMenu([
      <MenuIcon
        key="0"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/');
        }}
      >
        <MdHome size={iconSize} fill="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].home}
        </ButtonMenu>
      </MenuIcon>,
      <MenuIcon
        key="1"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/editar-perfil');
        }}
      >
        <IoPeopleCircle size={iconSize} fill="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].editProfile}
        </ButtonMenu>
      </MenuIcon>,
      <MenuIcon
        key="2"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/convidar-daddys');
        }}
      >
        <IoPersonAdd size={iconSize - 5} fill="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].inviteDaddys}
        </ButtonMenu>
      </MenuIcon>,
      <MenuIcon
        key="3"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/solicitar-resgate');
        }}
      >
        <MdAttachMoney size={iconSize} fill="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].requestRedemption}
        </ButtonMenu>
      </MenuIcon>,
      <MenuIcon
        key="4"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/configuracoes');
        }}
      >
        <IoSettingsOutline size={iconSize - 5} fill="#fff" stroke="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].settings}
        </ButtonMenu>
      </MenuIcon>,
    ]);

    setDaddyMenu([
      <MenuIcon
        key="0"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/');
        }}
      >
        <MdHome size={iconSize} fill="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].home}
        </ButtonMenu>
      </MenuIcon>,
      <MenuIcon
        key="1"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/meu-plano');
        }}
      >
        <MdAccountCircle size={iconSize} fill="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].myPlan}
        </ButtonMenu>
      </MenuIcon>,
      <MenuIcon
        key="2"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/credito-virtual');
        }}
      >
        <MdAttachMoney size={iconSize - 5} fill="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].virtualCredit}
        </ButtonMenu>
      </MenuIcon>,
      <MenuIcon
        key="3"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/baralho');
        }}
      >
        <img src={deck} alt="baralho" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].cheap}
        </ButtonMenu>
      </MenuIcon>,
      <MenuIcon
        key="4"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/editar-perfil');
        }}
      >
        <IoPeopleCircle size={iconSize - 5} fill="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].editProfile}
        </ButtonMenu>
      </MenuIcon>,
      <MenuIcon
        key="5"
        onClick={() => {
          handleDisplayDrawer();
          history.push('/configuracoes');
        }}
      >
        <IoSettingsOutline size={iconSize - 5} fill="#fff" stroke="#fff" />
        <ButtonMenu backgroundColor="primary" size="large">
          {messages[key].settings}
        </ButtonMenu>
      </MenuIcon>,
    ]);
  }, [history, handleDisplayDrawer, key]);

  return (
    <Menu theme={{ slide: openDrawer }}>
      <Background />
      <LogoContainer>
        <img src={LogoImg} alt="Logo" />
        <CloseButton>
          <MdKeyboardArrowLeft
            size={70}
            fill="#fff"
            cursor="pointer"
            onClick={() => handleDisplayDrawer()}
          />
        </CloseButton>
      </LogoContainer>
      <ListMenu>
        {user.type === 'BABY'
          ? babyMenu.map(obj => {
              return obj;
            })
          : daddyMenu.map(obj => {
              return obj;
            })}
      </ListMenu>
      <ContainerUserInfo>
        <div>
          <span>Nome de usuário</span>
          <span>Cidade - UF</span>
          <span>Idade</span>
        </div>
        <div>
          <span>
            {messages[key].balance}: {messages[key].currency} 300,00
          </span>
          {user.type === 'BABY' ? (
            <span>
              {messages[key].spicyPhoto}: {messages[key].currency} 50,00
            </span>
          ) : (
            <span>Plano Básico</span>
          )}
        </div>
      </ContainerUserInfo>
    </Menu>
  );
}
