import { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { usePageSettings } from 'hooks/pagesSettings';
import { IMessage } from 'interfaces/pagesSettings';
import { useToast } from 'hooks/toast';
import { messages, LanguageTranslated } from './translate';

import { Container, Title, Content, Text, ContainerButton } from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function InviteDaddys(): JSX.Element {
  const [userInvites, setUserInvites] = useState(7);
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const { addSuccess, addError } = useToast();

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleBuyInvites() {
    addSuccess(`${messages[key].purchase}`);
  }

  function handleInviteDaddys() {
    if (userInvites < 1) {
      addError(`${messages[key].error}`);
      addError(`${messages[key].pleaseBuy}`);
      return;
    }
    addSuccess(`${messages[key].copiedLink}`);
    setUserInvites(userInvites - 1);
  }
  return (
    <Container>
      <Title>{messages[key].title}</Title>
      <Content>
        <Text>{messages[key].limitInvites}</Text>
        <br />
        <br />
        <Text>
          {messages[key].youHave} {userInvites} {messages[key].invites}
        </Text>
        <ContainerButton>
          <Button size="regular" onClick={() => handleInviteDaddys()}>
            {messages[key].copyLink}
          </Button>
        </ContainerButton>
        <br />
        <br />
        <Text>{messages[key].buyMore}</Text>
        <ContainerButton>
          <Button size="regular" onClick={() => handleBuyInvites()}>
            {messages[key].buttonBuy}
          </Button>
        </ContainerButton>
      </Content>
    </Container>
  );
}
