import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  limitInvites: string;
  invites: string;
  youHave: string;
  buyMore: string;
  buttonBuy: string;
  copyLink: string;
  purchase: string;
  error: string;
  pleaseBuy: string;
  copiedLink: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Convidar daddys',
    limitInvites: 'Você tem direito a um limite de 100 convites.',
    youHave: 'Você ainda tem',
    invites: 'convites.',
    buttonBuy: 'Comprar',
    buyMore: 'Comprar mais 10 convites.',
    copyLink: 'Copiar link',
    purchase: 'Compra efetuada com sucesso!',
    error: 'Você não possui mais convites',
    pleaseBuy: 'Por favor, compre mais convites',
    copiedLink: 'Link copiado',
  },
  'en-US': {
    title: 'Invite Daddys',
    limitInvites: 'You are entitled to a limit of 100 invitations.',
    youHave: 'You still have',
    invites: 'invites.',
    buttonBuy: 'Buy',
    buyMore: 'Buy 10 more invitations.',
    copyLink: 'Copy link',
    purchase: 'Successful purchase!',
    error: 'You no longer have invitations',
    pleaseBuy: 'Please buy more invitations',
    copiedLink: 'Copied link',
  },
};
