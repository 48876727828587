import { useEffect, useState } from 'react';
import { RiBankFill } from 'react-icons/ri';
import { colors } from 'styles/colors';
import { useToast } from 'hooks/toast';
import { IModalDisplay } from 'interfaces/ModalDisplay';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  ButtonReturn,
  ContainerModal,
  ContentModal,
  ModalButton,
  ModalContainerInput,
  ModalInput,
  TitleModal,
} from '../styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ModalProps {
  closeModal: (prop: keyof IModalDisplay, action: 'open' | 'close') => void;
}

export function BankAccount({ closeModal }: ModalProps): JSX.Element {
  const { addSuccess } = useToast();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleConfirmChanges() {
    addSuccess('Alterações salvas');
  }

  function handleCloseModal() {
    closeModal('bankAccount', 'close');
  }

  return (
    <ContainerModal>
      <TitleModal>
        <RiBankFill fill={colors.darkGray} size={50} />
        <h2>{messages[key].title}</h2>
        <ButtonReturn size={40} onClick={() => handleCloseModal()} />
      </TitleModal>
      <ContentModal>
        <ModalContainerInput>
          <span>{messages[key].bank}:</span>
          <ModalInput name="bank" />
        </ModalContainerInput>
        <ModalContainerInput>
          <span>{messages[key].agency}:</span>
          <ModalInput name="bank" />
        </ModalContainerInput>
        <ModalContainerInput>
          <span>{messages[key].account}:</span>
          <ModalInput name="bank" />
        </ModalContainerInput>
        <ModalButton size="regular" onClick={() => handleConfirmChanges()}>
          {messages[key].concluded}
        </ModalButton>
      </ContentModal>
    </ContainerModal>
  );
}
