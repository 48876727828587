import { useState, useEffect, useCallback } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { readUploadedFile } from 'helpers/upload';
import defaultImage from 'assets/images/img/background_image.png';
import { useToast } from 'hooks/toast';
import { IoIosReturnLeft } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  Title,
  ContainerImage,
  Label,
  ContainerAllImage,
  ButtonProceed,
  CloseImage,
  UploadedImage,
  CancelButton,
  ContainerMain,
  Main,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUploadImage {
  id: number;
  file: File | null;
  controlUpload: boolean;
}
interface ISelectImage {
  id: number;
  base64: string;
}
interface ISelectObject {
  image0: ISelectImage;
  image1: ISelectImage;
  image2: ISelectImage;
  image3: ISelectImage;
}
interface IUploadObject {
  image0: IUploadImage;
  image1: IUploadImage;
  image2: IUploadImage;
  image3: IUploadImage;
}

export function ImagesData(): JSX.Element {
  const { addError, addSuccess } = useToast();
  const history = useHistory();
  const [selectedFiles, setSelectedFiles] = useState<ISelectObject>(
    {} as ISelectObject,
  );
  const [uploadFile, setUploadFile] = useState<IUploadObject>(
    {} as IUploadObject,
  );
  const [inProgress, setInProgress] = useState(false);
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleRemoveImage(id: number) {
    if (id === 0) {
      setUploadFile({
        ...uploadFile,
        image0: { id: 0, file: null, controlUpload: false },
      });
      setSelectedFiles({ ...selectedFiles, image0: { id: 0, base64: '' } });
    }
    if (id === 1) {
      setUploadFile({
        ...uploadFile,
        image1: { id: 1, file: null, controlUpload: false },
      });
      setSelectedFiles({ ...selectedFiles, image1: { id: 1, base64: '' } });
    }
    if (id === 2) {
      setUploadFile({
        ...uploadFile,
        image2: { id: 2, file: null, controlUpload: false },
      });
      setSelectedFiles({ ...selectedFiles, image2: { id: 2, base64: '' } });
    }
    if (id === 3) {
      setUploadFile({
        ...uploadFile,
        image3: { id: 3, file: null, controlUpload: false },
      });
      setSelectedFiles({ ...selectedFiles, image3: { id: 3, base64: '' } });
    }
  }

  useEffect(() => {
    const handleProcess = async () => {
      try {
        if (uploadFile.image0?.file && uploadFile.image0?.controlUpload) {
          const base64 = await readUploadedFile(uploadFile.image0?.file);
          setSelectedFiles({ ...selectedFiles, image0: { id: 0, base64 } });
          setUploadFile({
            ...uploadFile,
            image0: {
              id: 0,
              file: uploadFile.image0?.file,
              controlUpload: false,
            },
          });
        }
        if (uploadFile.image1?.file && uploadFile.image1?.controlUpload) {
          const base64 = await readUploadedFile(uploadFile.image1?.file);
          setSelectedFiles({ ...selectedFiles, image1: { id: 1, base64 } });
          setUploadFile({
            ...uploadFile,
            image1: {
              id: 1,
              file: uploadFile.image1?.file,
              controlUpload: false,
            },
          });
        }
        if (uploadFile.image2?.file && uploadFile.image2?.controlUpload) {
          const base64 = await readUploadedFile(uploadFile.image2?.file);
          setSelectedFiles({ ...selectedFiles, image2: { id: 2, base64 } });
          setUploadFile({
            ...uploadFile,
            image2: {
              id: 2,
              file: uploadFile.image2?.file,
              controlUpload: false,
            },
          });
        }
        if (uploadFile.image3?.file && uploadFile.image3?.controlUpload) {
          const base64 = await readUploadedFile(uploadFile.image3.file);
          setSelectedFiles({ ...selectedFiles, image3: { id: 3, base64 } });
          setUploadFile({
            ...uploadFile,
            image3: {
              id: 3,
              file: uploadFile.image3?.file,
              controlUpload: false,
            },
          });
        }
      } catch (err) {
        addError('Erro ao processar os arquivos');
      } finally {
        setInProgress(false);
      }
    };
    if (uploadFile) handleProcess();
  }, [uploadFile, addError, addSuccess, selectedFiles]);

  const handleImageChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      prop: keyof IUploadObject,
      id: number,
    ): void => {
      if (e.target.files) {
        const { files } = e.target;
        const file = files[0];
        setUploadFile({
          ...uploadFile,
          [prop]: { id, file, controlUpload: true },
        });
        setInProgress(true);
      }
    },
    [uploadFile],
  );
  useEffect(() => {
    setUploadFile({
      image0: {
        id: 0,
        file: null,
        controlUpload: false,
      },
      image1: {
        id: 1,
        file: null,
        controlUpload: false,
      },
      image2: {
        id: 2,
        file: null,
        controlUpload: false,
      },
      image3: {
        id: 3,
        file: null,
        controlUpload: false,
      },
    });
    setSelectedFiles({
      image0: {
        id: 0,
        base64: '',
      },
      image1: {
        id: 1,
        base64: '',
      },
      image2: {
        id: 2,
        base64: '',
      },
      image3: {
        id: 3,
        base64: '',
      },
    });
  }, []);

  function handleSandForm() {
    if (
      selectedFiles.image0?.base64.length ||
      selectedFiles.image1?.base64.length ||
      selectedFiles.image2?.base64.length ||
      selectedFiles.image3?.base64.length
    ) {
      addSuccess(`${messages[key].successMessage}`);
      history.push('imagem');
    } else addError(`${messages[key].errorMessage}`);
  }

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <Title>{messages[key].text} </Title>
            <ContainerAllImage>
              <ContainerImage>
                {selectedFiles.image0?.base64 === '' ? (
                  <>
                    <img src={defaultImage} alt="Insira uma imagem" />
                    <Label htmlFor="image0" />
                    <input
                      name="image"
                      type="file"
                      id="image0"
                      accept="image/*"
                      onChange={e => handleImageChange(e, 'image0', 0)}
                    />
                  </>
                ) : (
                  <>
                    <CloseImage
                      fill="var(--remove)"
                      size={20}
                      onClick={() =>
                        handleRemoveImage(selectedFiles.image0?.id)
                      }
                    />
                    <UploadedImage src={selectedFiles.image0?.base64} alt="" />
                  </>
                )}
              </ContainerImage>

              <ContainerImage>
                {selectedFiles.image1?.base64 === '' ? (
                  <>
                    <img src={defaultImage} alt="" />
                    <Label htmlFor="image1" />
                    <input
                      name="image"
                      type="file"
                      id="image1"
                      accept="image/*"
                      onChange={e => handleImageChange(e, 'image1', 1)}
                    />
                  </>
                ) : (
                  <>
                    <CloseImage
                      fill="var(--remove)"
                      size={20}
                      onClick={() => {
                        handleRemoveImage(selectedFiles.image1?.id);
                      }}
                    />
                    <UploadedImage src={selectedFiles.image1?.base64} alt="" />
                  </>
                )}
              </ContainerImage>
            </ContainerAllImage>
            <ContainerAllImage>
              <ContainerImage>
                {selectedFiles.image2?.base64 === '' ? (
                  <>
                    <img src={defaultImage} alt="" />
                    <Label htmlFor="image2" />
                    <input
                      name="image"
                      type="file"
                      id="image2"
                      accept="image/*"
                      onChange={e => handleImageChange(e, 'image2', 2)}
                    />
                  </>
                ) : (
                  <>
                    <CloseImage
                      fill="var(--remove)"
                      size={20}
                      onClick={() =>
                        handleRemoveImage(selectedFiles.image2?.id)
                      }
                    />
                    <UploadedImage src={selectedFiles.image2?.base64} alt="" />
                  </>
                )}
              </ContainerImage>
              <ContainerImage>
                {selectedFiles.image3?.base64 === '' ? (
                  <>
                    <img src={defaultImage} alt="" />
                    <Label htmlFor="image3" />
                    <input
                      name="image"
                      type="file"
                      id="image3"
                      accept="image/*"
                      onChange={e => handleImageChange(e, 'image3', 3)}
                    />
                  </>
                ) : (
                  <>
                    <CloseImage
                      fill="var(--remove)"
                      size={20}
                      onClick={() =>
                        handleRemoveImage(selectedFiles.image3?.id)
                      }
                    />
                    <UploadedImage src={selectedFiles.image3?.base64} alt="" />
                  </>
                )}
              </ContainerImage>
            </ContainerAllImage>
            <ButtonProceed
              backgroundColor="primary"
              loading={inProgress}
              size="regular"
              onClick={() => handleSandForm()}
            >
              {messages[key].proceed}
            </ButtonProceed>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
