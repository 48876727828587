import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  name: string;
  nickname: string;
  bornedDate: string;
  phone: string;
  email: string;
  identityDocument: string;
  document: string;
  address: {
    cep: string;
    city: string;
    state: string;
    country: string;
    street: string;
    neighborhood: string;
  };
  metadata: {
    languages: string[];
    haveChildren: boolean;
    isSmoker: boolean;
    doDrink: boolean;
    hairColor: string;
    eyesColor: string;
    height: string;
    profession: string;
    academicFormation: string;
    income: string;
    patrimony: string;
    introductoryPhrase: string;
    objective: string;
  };
  socialMedias: {
    instagram: string;
    facebook: string;
    tiktok: string;
  };
  role: string;
}

const initialState: IUser = {
  name: '',
  nickname: '',
  bornedDate: '',
  phone: '',
  email: '',
  identityDocument: '',
  document: '',
  address: {
    cep: '',
    city: '',
    state: '',
    country: '',
    street: '',
    neighborhood: '',
  },
  metadata: {
    languages: [],
    haveChildren: false,
    isSmoker: false,
    doDrink: false,
    hairColor: '',
    eyesColor: '',
    height: '',
    profession: '',
    academicFormation: '',
    income: '',
    patrimony: '',
    introductoryPhrase: '',
    objective: '',
  },
  socialMedias: {
    instagram: '',
    facebook: '',
    tiktok: '',
  },
  role: '',
};

interface IMainData {
  bornedDate: string;
  name: string;
  nickname: string;
  email: string;
  phone: string;
  type: string;
}

interface IIdentificationData {
  identityDocument: string;
  document: string;
  income: string;
  patrimony: string;
  profession: string;
  academicFormation: string;
}

interface IAddressData {
  cep: string;
  city: string;
  state: string;
  country: string;
  street: string;
  neighborhood: string;
}

interface IPersonalCharacteristcsData {
  hairColor: string;
  eyesColor: string;
  height: string;
  isSmoker: boolean;
  doDrink: boolean;
}

interface ISocialNetworksData {
  facebook: string;
  instagram: string;
  tiktok: string;
}
interface IObjectiveData {
  introductoryPhrase: string;
  objective: string;
}
export const signupSlice = createSlice({
  name: 'signup',

  initialState,
  reducers: {
    setMainData: (state, mainData: PayloadAction<IMainData>) => {
      state.bornedDate = mainData.payload.bornedDate;
      state.name = mainData.payload.name;
      state.nickname = mainData.payload.nickname;
      state.email = mainData.payload.email;
      state.phone = mainData.payload.phone;
      state.role = mainData.payload.type;
    },
    setIdentificantionData: (
      state,
      data: PayloadAction<IIdentificationData>,
    ) => {
      state.identityDocument = data.payload.identityDocument;
      state.document = data.payload.document;
      state.metadata.income = data.payload.income;
      state.metadata.patrimony = data.payload.patrimony;
      state.metadata.profession = data.payload.profession;
      state.metadata.academicFormation = data.payload.academicFormation;
    },
    setAddressData: (state, data: PayloadAction<IAddressData>) => {
      state.address.cep = data.payload.cep;
      state.address.city = data.payload.city;
      state.address.country = data.payload.country;
      state.address.neighborhood = data.payload.neighborhood;
      state.address.state = data.payload.state;
      state.address.street = data.payload.street;
    },
    setLanguagesData: (state, data: PayloadAction<Array<string>>) => {
      state.metadata.languages = data.payload;
    },
    setPersonalCharacteristicsData: (
      state,
      data: PayloadAction<IPersonalCharacteristcsData>,
    ) => {
      state.metadata.eyesColor = data.payload.eyesColor;
      state.metadata.hairColor = data.payload.hairColor;
      state.metadata.height = data.payload.height;
      state.metadata.isSmoker = data.payload.isSmoker;
      state.metadata.doDrink = data.payload.doDrink;
    },
    setSocialNetworksDataData: (
      state,
      data: PayloadAction<ISocialNetworksData>,
    ) => {
      state.socialMedias.instagram = data.payload.instagram;
      state.socialMedias.facebook = data.payload.facebook;
      state.socialMedias.tiktok = data.payload.tiktok;
    },
    setObjectiveData: (state, data: PayloadAction<IObjectiveData>) => {
      state.metadata.introductoryPhrase = data.payload.introductoryPhrase;
      state.metadata.objective = data.payload.objective;
    },
  },
});

export const {
  setMainData,
  setIdentificantionData,
  setAddressData,
  setLanguagesData,
  setPersonalCharacteristicsData,
  setSocialNetworksDataData,
  setObjectiveData,
} = signupSlice.actions;

export default signupSlice.reducer;
