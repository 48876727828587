import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)``;

export const Title = styled.span`
  color: var(--primary);
  font-size: 2rem;
  font-weight: 300;

  @media (max-width: 650px) {
    font-size: 1.8rem;
  }
`;
export const Line = styled.hr`
  border-color: var(--primary);
`;

export const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding-top: 8rem;

  @media (max-width: 560px) {
    width: 100%;
  }
  @media (max-height: 570px) {
    padding-top: 4rem;
  }
`;

export const Text = styled.h2`
  color: var(--light-gray);
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
`;

export const ContainerButton = styled.div`
  width: 10rem;
  margin-top: 2rem;
`;
