import { ReactNode } from 'react';
import { DrawerMenu } from 'components/Menu';
import { ToolBar } from 'components/ToolBar';
import OverlayReceivedCall from 'components/OverlayReceivedCall';
import { useAppSelector } from 'hooks';
import { Container, Content } from './styles';

interface BackgroundProps {
  children?: ReactNode;
}

function DefaultPage({ children, ...rest }: BackgroundProps): JSX.Element {
  const isCallComming = useAppSelector(state => state.call.isCallComming);
  return (
    <Container {...rest}>
      <ToolBar />
      <DrawerMenu />
      {isCallComming && <OverlayReceivedCall />}

      <Content>{children}</Content>
    </Container>
  );
}

export default DefaultPage;
