import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  home: string;
  myPlan: string;
  virtualCredit: string;
  cheap: string;
  editProfile: string;
  settings: string;
  inviteDaddys: string;
  requestRedemption: string;
  balance: string;
  currency: string;
  spicyPhoto: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    home: 'Home',
    myPlan: 'Meu Plano',
    cheap: 'Baralho',
    editProfile: 'Editar perfil',
    settings: 'Configurações',
    virtualCredit: 'Crédito virtual',
    inviteDaddys: 'Convidar daddys',
    requestRedemption: 'Solicitar resgate',
    balance: 'Saldo',
    currency: 'R$',
    spicyPhoto: 'Foto picante',
  },
  'en-US': {
    home: 'Home',
    myPlan: 'My plan',
    cheap: 'Cheap',
    editProfile: 'Edit profile',
    settings: 'Settings',
    virtualCredit: 'Virtual credit',
    inviteDaddys: 'Invite daddys',
    requestRedemption: 'Request redemption',
    balance: 'Balance',
    currency: '$',
    spicyPhoto: 'Spicy photo',
  },
};
