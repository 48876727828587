import { useState, useEffect } from 'react';
import { UserCard } from 'components/UserCard';
import { IUserInfo } from 'interfaces/cardUserInfo';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { useAuth } from 'hooks/auth';
import { messages, LanguageTranslated } from './translate';
import { Container, UserList, Title } from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function Contacts(): JSX.Element {
  const [daddys, setDaddys] = useState<Array<IUserInfo>>(
    [] as Array<IUserInfo>,
  );
  const [babys, setBabys] = useState<Array<IUserInfo>>([] as Array<IUserInfo>);

  const { user } = useAuth();

  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setDaddys([
      {
        id: '1',
        name: 'Carlos',
        city: 'Juiz de Fora',
        country: 'Brasil',
        state: 'Minas Gerais',
        age: '55',
        status: true,
      },
      {
        id: '2',
        name: 'João',
        city: 'Campinas',
        country: 'Brasil',
        state: 'São Paulo',
        age: '38',
        status: true,
      },
      {
        id: '3',
        name: 'Marcelo',
        city: 'Cabo Frio',
        country: 'Brasil',
        state: 'Rio de Janeiro',
        age: '35',
        status: false,
      },
      {
        id: '4',
        name: 'Sérgio',
        city: 'São João del Rei',
        country: 'Brasil',
        state: 'Minas Gerais',
        age: '58',
        status: false,
      },
      {
        id: '5',
        name: 'Paulo',
        city: 'Barbacena',
        country: 'Brasil',
        state: 'Minas Gerais',
        age: '40',
        status: true,
      },
    ]);

    setBabys([
      {
        id: '1',
        name: 'Marcela',
        city: 'Juiz de Fora',
        country: 'Brasil',
        state: 'Minas Gerais',
        age: '19',
        status: true,
      },
      {
        id: '2',
        name: 'Ana Paula',
        city: 'Campinas',
        country: 'Brasil',
        state: 'São Paulo',
        age: '25',
        status: true,
      },
      {
        id: '3',
        name: 'Patrícia',
        city: 'Cabo Frio',
        country: 'Brasil',
        state: 'Rio de Janeiro',
        age: '31',
        status: false,
      },
      {
        id: '4',
        name: 'Carla',
        city: 'São João del Rei',
        country: 'Brasil',
        state: 'Minas Gerais',
        age: '26',
        status: false,
      },
      {
        id: '5',
        name: 'Tainá',
        city: 'Barbacena',
        country: 'Brasil',
        state: 'Minas Gerais',
        age: '20',
        status: true,
      },
    ]);
  }, []);

  return (
    <Container>
      {user.type === 'DADDY' && (
        <>
          <Title>{messages[key].titleDaddy}</Title>
          <UserList>
            {babys.map(obj => {
              return <UserCard key={obj.id} userType="BABY" userInfo={obj} />;
            })}
          </UserList>
        </>
      )}
      {user.type === 'BABY' && (
        <>
          <Title>{messages[key].titleBaby}</Title>
          <UserList>
            {daddys.map(obj => {
              return <UserCard key={obj.id} userType="DADDY" userInfo={obj} />;
            })}
          </UserList>
        </>
      )}
    </Container>
  );
}
