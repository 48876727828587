import styled from 'styled-components';
import { RiCloseCircleLine } from 'react-icons/ri';
import { Button } from 'components/Button';
import Background from 'pages/Auth/_Background';

export const ContainerMain = styled(Background)``;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  position: relative;
  padding: 10px;
  margin: 10px;
  input[type='file'] {
    display: none;
  }
`;

export const Label = styled.label`
  width: 90%;
  position: absolute;
  height: 80%;
  padding: 150px 120px;
  background-color: transparent;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 17px;
  color: var(--white);
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
`;
export const CloseVideo = styled(RiCloseCircleLine)`
  align-self: flex-end;
  color: var(--remove);
  cursor: pointer;
  font-size: 30px;
`;

export const CancelButton = styled(Button)`
  margin-top: 15px;
`;
