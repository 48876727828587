import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 6rem;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Content = styled.div`
  width: 30rem;
  height: 36rem;
  border-radius: 10px;
  background-color: var(--blue-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-top: 2rem;
  justify-content: space-between;

  @media (max-height: 630px) {
    height: 30rem;
  }
  @media (max-height: 620px) {
    height: 27rem;
  }

  div.container-user-info {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;

    h1 {
      font-size: 1.6rem;
      text-align: center;
      color: var(--white);
      margin: 2rem 0;
    }
  }
`;

export const Image = styled.img`
  width: 20rem;

  @media (max-height: 630px) {
    width: 15rem;
  }
  @media (max-height: 620px) {
    width: 10rem;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const AcceptCallButton = styled.button`
  width: 9rem;
  padding: 0.5rem 1rem;
  color: var(--white);
  border: none;
  border-radius: 5px;
  background-color: var(--success);
  font-size: 1.3rem;
`;

export const RejectCallButton = styled.button`
  width: 9rem;
  padding: 0.5rem 1rem;
  color: var(--white);
  border: none;
  border-radius: 5px;
  background-color: var(--danger);
  font-size: 1.3rem;
`;
