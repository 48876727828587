import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  text: string;
  textProofs: string;
  cpf: string;
  documentWithPhoto: string;
  adressProof: string;
  incomeProof: string;
  requiredItens: string;
  proceed: string;
  cancel: string;
  successMessage: string;
  errorMessage: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    text: 'Insira aqui uma copia do seu CPF, um documento com foto:',
    textProofs:
      'Insira aqui uma copia do seu comprovante de renda e de endereço:',
    cpf: 'CPF*',
    documentWithPhoto: 'Documento com foto*',
    adressProof: 'Comprovante de endereço*',
    incomeProof: 'Comprovante de renda',
    requiredItens: 'Itens obrigatórios*',
    proceed: 'Prosseguir',
    cancel: 'Cancelar',
    errorMessage: 'Por favor, preencha os campos obrigatórios',
    successMessage: 'Dados enviados!',
  },
  'en-US': {
    text:
      'Add a photo here holding a written sign Insert here a copy of your CPF, a document with a photo:',
    textProofs: 'Insert a copy of your proof of income and address here:',
    cpf: 'CPF*',
    proceed: 'Proceed',
    documentWithPhoto: 'Document with photo*',
    adressProof: 'Adress proof*',
    incomeProof: 'Income proof',
    requiredItens: 'Required itens*',
    cancel: 'Cancel',
    errorMessage: 'Please, fill in the required fields ',
    successMessage: 'Uploaded files!',
  },
};
