/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'hooks';
import { useAuth } from 'hooks/auth';

import {
  IUserCall,
  setListUsers,
  setIsCallComming,
  setCurrentCall,
  ICurrentCall,
  setCurrentUser,
  setInProgress,
  ICallState,
} from 'store/slices/callSlice';
import { callSocket } from 'services/callServiceAPI';
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  DocumentReference,
  DocumentData,
  setDoc,
  onSnapshot,
  getDoc,
  updateDoc,
  getDocs,
} from 'firebase/firestore';
import { firestore } from 'services/firebaseConfig';
import { pc } from 'services/peerWebRTC';
import { createListenEvent } from './factory/listen-event.factory';

interface IVideoCallSocket {
  children: JSX.Element | JSX.Element[];
}

export const VideoCallSocket = ({
  children,
}: IVideoCallSocket): JSX.Element => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callState: ICallState = useSelector<any, ICallState>(state => {
    return state.call;
  });

  // awnswer call
  async function answerCall(data: ICurrentCall) {
    const callId: string = data.call ?? '';

    const callDoc = await doc(firestore, 'calls', callId);

    const offerCandidates = collection(callDoc, 'offerCandidates');
    const answerCandidates = collection(callDoc, 'answerCandidates');

    pc.onicecandidate = async event => {
      if (event.candidate) {
        await addDoc(answerCandidates, event.candidate.toJSON());
      }
    };

    const callData = (await getDoc(callDoc)).data();
    if (callData) {
      const offerDescription = callData.offer;

      await pc.setRemoteDescription(
        new RTCSessionDescription(offerDescription),
      );
    }

    const answerDescription = await pc.createAnswer();
    await pc.setLocalDescription(answerDescription);

    const answer = {
      type: answerDescription.type,
      sdp: answerDescription.sdp,
    };

    await updateDoc(callDoc, { answer });

    const offerCandidatesData = await getDocs(offerCandidates);

    offerCandidatesData.forEach(change => {
      if (change.data().type === 'added') {
        const dataChange = change.data();
        pc.addIceCandidate(new RTCIceCandidate(dataChange));
      }
    });
  }

  // create call offer
  async function createOffer(callDoc: DocumentReference<DocumentData>) {
    const offerCandidates = collection(callDoc, 'offerCandidates');
    const answerCandidates = collection(callDoc, 'answerCandidates');

    // Get candidates for caller, save to db
    pc.onicecandidate = async event => {
      if (event.candidate) {
        await addDoc(offerCandidates, event.candidate.toJSON());
      }
    };

    // Create offer
    const offerDescription = await pc.createOffer();

    await pc.setLocalDescription(offerDescription);

    const offer = {
      sdp: offerDescription.sdp,
      type: offerDescription.type,
    };

    await setDoc(callDoc, { offer });

    // Listen for remote answer
    onSnapshot(callDoc, snapshot => {
      const data = snapshot.data();
      if (!pc.currentRemoteDescription && data?.answer) {
        const answerDescription = new RTCSessionDescription(data.answer);

        pc.setRemoteDescription(answerDescription);
      }
    });
    // When answered, add candidate to peer connection
    onSnapshot(answerCandidates, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const candidate = new RTCIceCandidate(change.doc.data());
          pc.addIceCandidate(candidate);
        }
      });
    });
  }

  useEffect(() => {
    const startWebsocketHandlers = () => {
      if (!user) return;
      dispatch(setInProgress(true));

      // handler => 'user.connected'
      createListenEvent<Array<IUserCall>>({
        socket: callSocket,
        eventName: 'user.connected',
        handler(usersListData) {
          const userFilter: IUserCall | undefined = usersListData.find(
            userData => userData.id === user.id,
          );

          dispatch(setListUsers(usersListData));
          dispatch(setCurrentUser(userFilter));
        },
      });

      // handler => 'user.call'
      createListenEvent<Array<IUserCall>>({
        socket: callSocket,
        eventName: 'user.disconnect',
        handler(usersListData) {
          dispatch(setListUsers(usersListData));
          dispatch(setCurrentUser(undefined));
        },
      });

      // handler => 'user-call'
      createListenEvent<ICurrentCall>({
        socket: callSocket,
        eventName: 'user.call',
        handler(data: ICurrentCall) {
          if (data.to.id === user?.id) {
            dispatch(setIsCallComming(true));
            dispatch(setCurrentCall(data));
          }
        },
      });

      // handler => 'user.call.status'
      createListenEvent({
        socket: callSocket,
        eventName: 'user.call.status',
        handler(callDataStatus: ICurrentCall) {
          if (callDataStatus.status === 'accepted') {
            // Aceitando a chamada a chamada
            callSocket.emit('call.accepted', callDataStatus);
          }

          if (callDataStatus.status === 'rejected') {
            // Rejeitando a chamada a chamada
            if (callDataStatus.to.id === user?.id) {
              callSocket.emit('call.rejected', callDataStatus);
            }
          }
        },
      });

      // handler => 'users.leave.room'
      createListenEvent({
        socket: callSocket,
        eventName: 'users.leave.room',
        handler(data: ICurrentCall) {
          if (data.to.id === user?.id || data.from.id === user?.id) {
            callSocket.emit('users.leave.room', data);
          }
        },
      });

      // handler => 'call.rejected'
      createListenEvent({
        socket: callSocket,
        eventName: 'call.rejected',
        handler(data: ICurrentCall) {
          if (data.from.id === user?.id) {
            dispatch(setIsCallComming(false));
          }
        },
      });

      // handler => 'call.accepted'
      createListenEvent({
        socket: callSocket,
        eventName: 'call.accepted',
        handler(data: ICurrentCall) {
          const processAcceptedCall = async () => {
            // Aqui é para quem enviou o invite
            if (data.from.id === user?.id) {
              const docRef = await addDoc(collection(firestore, 'calls'), {}); // Cria um registro no firebase, para usar o id no mesmo formato
              const callId = docRef.id; // runtime, mantem o id em uma constante para ser usado enquanto o código está rodando
              await deleteDoc(doc(firestore, 'calls', callId)); // Limpa o registro do firebase

              const currentCall: ICurrentCall = {
                ...data,
                call: callId,
                playing: true,
              };
              await createOffer(docRef);
              dispatch(setCurrentCall(currentCall));

              callSocket.emit('call.start', currentCall);
            }
          };

          processAcceptedCall();
        },
      });

      // handler => 'call.start'
      createListenEvent({
        socket: callSocket,
        eventName: 'call.start',
        handler(data: ICurrentCall) {
          const startCall = async () => {
            const currentCallCopy: ICurrentCall = { ...data };
            if (data.from.id === user?.id) {
              if (callState.currentCall) {
                const currentCallOriginal: ICurrentCall = callState.currentCall; // VOCÊ PRECISA TROCAR PELO ESTADO
                dispatch(
                  setCurrentCall({
                    ...currentCallCopy,
                    ...currentCallOriginal,
                  }),
                );
                const webcamVideo = document.getElementById('webcamVideo');
                const remoteVideo = document.getElementById('remoteVideo');
                webcamVideo?.addEventListener('timeupdate', () => true);
                remoteVideo?.addEventListener('timeupdate', () => true);
              }
            }
            // Aqui é para quem recebeu o invite
            if (data.to.id === user?.id) {
              const currentCall = data;
              await answerCall(currentCall);
              dispatch(setCurrentCall(currentCallCopy));
              const webcamVideo = document.getElementById('webcamVideo');
              const remoteVideo = document.getElementById('remoteVideo');
              webcamVideo?.addEventListener('timeupdate', () => true);
              remoteVideo?.addEventListener('timeupdate', () => true);
            }
          };
          startCall();
        },
      });
    };

    if (!callState.inProgress) {
      startWebsocketHandlers();
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      const userCall: IUserCall = {
        id: user.id,
        status: 'online',
        name: user.name,
        role: user.type === 'BABY' ? 'babby' : 'daddy',
      };
      callSocket.emit('user.connected', userCall);
    }
  }, [user]);

  return <>{children}</>;
};
