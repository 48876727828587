import { Switch, useRouteMatch } from 'react-router-dom';

import { MenuProvider } from 'hooks/menu';

import { SignIn } from 'pages/Auth/SignIn';
import { AdressData } from 'pages/Auth/SignUp/AdressData';
import { DocumentsData } from 'pages/Auth/SignUp/DocumentsData';
import { IdentificationData } from 'pages/Auth/SignUp/IdentificationData';
import { ImageData } from 'pages/Auth/SignUp/ImageData';
import { ImagesData } from 'pages/Auth/SignUp/ImagesData';
import { LanguagesData } from 'pages/Auth/SignUp/LanguagesData';
import { MainData } from 'pages/Auth/SignUp/MainData';
import { ObjectiveData } from 'pages/Auth/SignUp/ObjectiveData';
import { PersonalCharacteristicsData } from 'pages/Auth/SignUp/PersonalCharacteristicsData';
import { SocialNetworksData } from 'pages/Auth/SignUp/SocialNetworksData';
import { VideoData } from 'pages/Auth/SignUp/VideoData';
import { Contacts } from 'pages/App/Contacts';
import { EditProfile } from 'pages/App/EditProfile';
import { SettingsPage } from 'pages/App/SettingsPage';
import { Messages } from 'pages/App/Messages';
import { Cheap } from 'pages/App/Cheap';
import { MyPlan } from 'pages/App/MyPlan';
import { VirtualCredit } from 'pages/App/VirtualCredit';
import { InviteDaddys } from 'pages/App/InviteDaddys';
import { RedemptionRequest } from 'pages/App/RedemptionRequest';
import { VideoCall } from 'pages/App/VideoCall';
import { HomePage } from 'pages/App/HomePage';

import { Route } from './Route';

export const Routes = (): JSX.Element => {
  const match = useRouteMatch('/registrar');

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      {/* SignUp flow */}
      <Route path="/registrar" exact component={MainData} />
      <Route path={`${match?.path}/endereco`} component={AdressData} />
      <Route path={`${match?.path}/documentos`} component={DocumentsData} />
      <Route
        path={`${match?.path}/identificacao`}
        component={IdentificationData}
      />
      <Route path={`${match?.path}/imagem`} component={ImageData} />
      <Route path={`${match?.path}/imagens`} component={ImagesData} />
      <Route path={`${match?.path}/idiomas`} component={LanguagesData} />
      <Route path={`${match?.path}/principal`} component={MainData} />
      <Route path={`${match?.path}/objetivos`} component={ObjectiveData} />
      <Route
        path={`${match?.path}/caracteristicas-pessoais`}
        component={PersonalCharacteristicsData}
      />
      <Route
        path={`${match?.path}/redes-sociais`}
        component={SocialNetworksData}
      />
      <Route path={`${match?.path}/video`} component={VideoData} />
      <MenuProvider>
        <Route path="/home" isPrivate component={HomePage} />

        {/* User pages */}
        <Route path="/contatos" isPrivate exact component={Contacts} />
        <Route path="/editar-perfil" isPrivate exact component={EditProfile} />
        <Route path="/configuracoes" isPrivate exact component={SettingsPage} />
        <Route path="/mensagens" isPrivate exact component={Messages} />
        <Route path="/baralho" isPrivate exact component={Cheap} />
        <Route path="/videochamada" isPrivate exact component={VideoCall} />
        <Route
          path="/solicitar-resgate"
          isPrivate
          exact
          component={RedemptionRequest}
        />
        <Route path="/meu-plano" isPrivate exact component={MyPlan} />
        <Route
          path="/convidar-daddys"
          isPrivate
          exact
          component={InviteDaddys}
        />
        <Route
          path="/credito-virtual"
          isPrivate
          exact
          component={VirtualCredit}
        />
      </MenuProvider>
    </Switch>
  );
};
