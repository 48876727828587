import { Button } from 'components/Button';
import { useEffect, useState } from 'react';
import { usePageSettings } from 'hooks/pagesSettings';
import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  Title,
  Content,
  Subtitle,
  Select,
  ContainerCoins,
  InputCoin,
  ContainerButton,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ICurrency {
  id: string;
  currency: string;
  code: string;
  coin: string;
}

export function VirtualCredit(): JSX.Element {
  const [currencies, setCurrencies] = useState<Array<ICurrency>>(
    [] as Array<ICurrency>,
  );
  const [selectedCurrency, setSelectedCurrency] = useState('R$');
  const [coins, setCoins] = useState('50');
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setCurrencies([
      {
        id: '1',
        currency: 'Real',
        code: 'BRL',
        coin: 'R$',
      },
      {
        id: '2',
        currency: 'Dólar',
        code: 'USD',
        coin: '$',
      },
      {
        id: '3',
        currency: 'Euro',
        code: 'EUR',
        coin: '€',
      },
    ]);
  }, []);

  return (
    <Container>
      <Title>{messages[key].title}</Title>
      <Content>
        <Subtitle>{messages[key].subtitle}</Subtitle>
        <Select onChange={e => setSelectedCurrency(e.target.value)}>
          {currencies.map(obj => {
            return (
              <option key={obj.id} value={obj.coin}>
                {obj.currency}
              </option>
            );
          })}
        </Select>
        <ContainerCoins>
          <InputCoin
            type="number"
            min="50"
            max="2000"
            value={coins}
            onChange={e => setCoins(e.target.value)}
          />
          <span>{messages[key].text}</span>
          <p>
            {messages[key].value} {selectedCurrency} {coins}
          </p>
        </ContainerCoins>

        <ContainerButton>
          <Button size="regular">{messages[key].proceed}</Button>
        </ContainerButton>
      </Content>
    </Container>
  );
}
