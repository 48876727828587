import { useEffect, useState } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { colors } from 'styles/colors';
import { useToast } from 'hooks/toast';
import { IModalDisplay } from 'interfaces/ModalDisplay';
import { usePageSettings } from 'hooks/pagesSettings';
import api from 'services/api';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  ContainerModal,
  TitleModal,
  ContentModal,
  ModalButton,
  ModalInput,
  ButtonReturn,
} from '../styles';

import { ContainerUserInputs, Select, ModalUserContainerInput } from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUserData {
  income: string;
  patrimony: string;
  adress: string;
  country: string;
  state: string;
  city: string;
  profession: string;
  academicEducation: string;
  telephone: string;
  email: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  hairColor: string;
  eyesColor: string;
}

interface IRequestCountry {
  id: string;
  name: string;
  description: string;
}

interface IRequestState {
  id: string;
  name: string;
  description: string;
  latitude: string;
  longitude: string;
  uf: string;
  countryId: string;
}

interface IRequestCity {
  id: string;
  name: string;
  stateId: string;
}

interface ModalProps {
  closeModal: (prop: keyof IModalDisplay, action: 'open' | 'close') => void;
}

export function Users({ closeModal }: ModalProps): JSX.Element {
  const [telephoneMask, setTelephoneMask] = useState<string>('');
  const [userHairColor, setUserHairColor] = useState<Array<string>>([]);
  const [userEyeColor, setUserEyeColor] = useState<Array<string>>([]);

  const [countries, setCountries] = useState<Array<IRequestCountry>>(
    [] as Array<IRequestCountry>,
  );
  const [states, setStates] = useState<Array<IRequestState>>(
    [] as Array<IRequestState>,
  );
  const [cities, setCities] = useState<Array<IRequestCity>>(
    [] as Array<IRequestCity>,
  );
  const [userData, setUserData] = useState<IUserData>({
    income: '',
    patrimony: '',
    adress: '',
    country: '',
    state: '',
    city: '',
    profession: '',
    academicEducation: '',
    telephone: '',
    email: '',
    instagram: '',
    facebook: '',
    tiktok: '',
    hairColor: '',
    eyesColor: '',
  });

  const { addSuccess, addError } = useToast();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleCloseModal() {
    closeModal('user', 'close');
  }

  useEffect(() => {
    async function getCountries() {
      try {
        const response = await api.get('/geographical/country');
        if (response) setCountries(response.data.result);
      } catch (error) {
        addError('Não foi possível carregar os países');
      }
    }
    getCountries();
  }, [addError]);

  useEffect(() => {
    setTelephoneMask('(99) 9 9999-9999');

    setUserEyeColor([
      'Selecione',
      'Castanho claro',
      'Castanho escuro',
      'Preto',
      'Azul',
      'Verde',
    ]);

    setUserHairColor([
      'Selecione',
      'Castanho claro',
      'Castanho escuro',
      'Preto',
      'Loiro',
      'Ruivo',
      'Branco',
    ]);
  }, []);

  function handleConfirmChanges() {
    addSuccess(`${messages[key].message}`);
  }

  useEffect(() => {
    async function handleGetStates(countryId: string) {
      try {
        const response = await api.get(
          `/geographical/state/${countryId}/states`,
        );
        if (response) setStates(response.data.result);
      } catch (error) {
        addError('Não foi possível carregar os estados');
      }
    }
    if (userData.country) handleGetStates(userData.country);
  }, [userData.country, addError]);

  useEffect(() => {
    async function handleGetCities(countryId: string, stateId: string) {
      try {
        const response = await api.get(
          `/geographical/city/${countryId}/states/${stateId}/city`,
        );
        if (response) setCities(response.data.result);
      } catch (error) {
        addError('Não foi possível carregar as cidades');
      }
    }
    if (userData.country && userData.state)
      handleGetCities(userData.country, userData.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.state, addError]);

  return (
    <ContainerModal>
      <TitleModal>
        <FaRegUserCircle fill={colors.darkGray} size={50} />
        <h2>{messages[key].title}</h2>
        <ButtonReturn size={40} onClick={() => handleCloseModal()} />
      </TitleModal>
      <ContentModal>
        <ContainerUserInputs>
          <ModalUserContainerInput>
            <span>{messages[key].income}:</span>
            <ModalInput
              name="income"
              type="currency"
              prefix={messages[key].currency}
              value={userData.income}
              onValueChange={value => {
                if (value) {
                  setUserData({ ...userData, income: value });
                } else {
                  setUserData({ ...userData, income: '' });
                }
              }}
            />
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].telephone}:</span>
            <ModalInput
              name="telephone"
              type="masked"
              mask={telephoneMask}
              value={userData.telephone}
              onChange={e =>
                setUserData({ ...userData, telephone: e.target.value })
              }
            />
          </ModalUserContainerInput>

          <ModalUserContainerInput>
            <span>{messages[key].patrimony}:</span>
            <ModalInput
              name="patrimony"
              type="currency"
              prefix="R$ "
              value={userData.patrimony}
              onValueChange={value => {
                if (value) {
                  setUserData({ ...userData, patrimony: value });
                } else {
                  setUserData({ ...userData, patrimony: '' });
                }
              }}
            />
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].email}:</span>
            <ModalInput
              name="email"
              value={userData.email}
              onChange={e =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].adress}:</span>
            <ModalInput
              name="adress"
              value={userData.adress}
              onChange={e =>
                setUserData({ ...userData, adress: e.target.value })
              }
            />
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].instagram}:</span>
            <ModalInput
              name="instagram"
              value={userData.instagram}
              onChange={e =>
                setUserData({ ...userData, instagram: e.target.value })
              }
            />
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].country}:</span>
            <Select
              name="country"
              placeholder={messages[key].country}
              onChange={e => {
                setUserData({ ...userData, country: e.target.value });
              }}
            >
              <option value="default">{messages[key].selectCountry}</option>
              {countries.map(obj => {
                return (
                  <option key={obj.id} value={obj.id}>
                    {obj.name}
                  </option>
                );
              })}
            </Select>
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].facebook}:</span>
            <ModalInput
              name="facebook"
              value={userData.facebook}
              onChange={e =>
                setUserData({ ...userData, facebook: e.target.value })
              }
            />
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].state}:</span>
            <Select
              name="state"
              placeholder="Estado"
              onChange={e => {
                setUserData({ ...userData, state: e.target.value });
              }}
            >
              <option value="default">{messages[key].selectState}</option>
              {states.map(obj => {
                return (
                  <option key={obj.id} value={obj.id}>
                    {obj.name}
                  </option>
                );
              })}
            </Select>
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].tiktok}:</span>
            <ModalInput
              name="tiktok"
              value={userData.tiktok}
              onChange={e =>
                setUserData({ ...userData, tiktok: e.target.value })
              }
            />
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].city}:</span>
            <Select
              name="city"
              placeholder="Cidade"
              onChange={e => setUserData({ ...userData, city: e.target.value })}
            >
              <option value="default">{messages[key].selectCity}</option>
              {cities.map(obj => {
                return (
                  <option key={obj.id} value={obj.name}>
                    {obj.name}
                  </option>
                );
              })}
            </Select>
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].hairColor}:</span>
            <Select>
              {userHairColor.map(obj => {
                return (
                  <option key={obj} value={obj}>
                    {obj}
                  </option>
                );
              })}
            </Select>
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].profession}:</span>
            <ModalInput
              name="profession"
              value={userData.profession}
              onChange={e =>
                setUserData({ ...userData, profession: e.target.value })
              }
            />
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].eyeColor}:</span>
            <Select>
              {userEyeColor.map(obj => {
                return (
                  <option key={obj} value={obj}>
                    {obj}
                  </option>
                );
              })}
            </Select>
          </ModalUserContainerInput>
          <ModalUserContainerInput>
            <span>{messages[key].formation}:</span>
            <ModalInput
              name="academicEducation"
              value={userData.academicEducation}
              onChange={e =>
                setUserData({ ...userData, academicEducation: e.target.value })
              }
            />
          </ModalUserContainerInput>
        </ContainerUserInputs>
        <ModalButton size="regular" onClick={() => handleConfirmChanges()}>
          {messages[key].concluded}
        </ModalButton>
      </ContentModal>
    </ContainerModal>
  );
}
