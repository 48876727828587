import { useState, useEffect, useCallback } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { IoIosReturnLeft } from 'react-icons/io';
import { useToast } from 'hooks/toast';
import { useHistory } from 'react-router-dom';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setSocialNetworksDataData } from 'store/slices/signupSlice';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  InputContainer,
  CancelButton,
  ContainerMain,
  Main,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUserSocialNetworksData {
  facebook: string;
  instagram: string;
  tiktok: string;
}

export function SocialNetworksData(): JSX.Element {
  const [userData, setUserData] = useState<IUserSocialNetworksData>(
    {} as IUserSocialNetworksData,
  );
  const { addSuccess } = useToast();
  const history = useHistory();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  const signup = useAppSelector(state => state.signup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setUserData({
      facebook: signup.socialMedias.facebook,
      instagram: signup.socialMedias.instagram,
      tiktok: signup.socialMedias.tiktok,
    });
  }, [signup]);

  const handleSandForm = useCallback(async () => {
    addSuccess(`${messages[key].successMessage}`);
    dispatch(setSocialNetworksDataData({ ...userData }));
    history.push('objetivos');
  }, [addSuccess, history, key, dispatch, userData]);

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <InputContainer>
              <Input
                name="facebook"
                placeholder="Facebook"
                value={userData.facebook}
                onChange={e =>
                  setUserData({ ...userData, facebook: e.target.value })
                }
              />
              <Input
                name="instagram"
                placeholder="Instagram"
                value={userData.instagram}
                onChange={e =>
                  setUserData({ ...userData, instagram: e.target.value })
                }
              />
              <Input
                name="tiktok"
                placeholder="TikTok"
                value={userData.tiktok}
                onChange={e =>
                  setUserData({ ...userData, tiktok: e.target.value })
                }
              />
            </InputContainer>
            <Button
              backgroundColor="primary"
              size="regular"
              onClick={() => handleSandForm()}
            >
              {messages[key].proceed}
            </Button>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
