import { useState, useEffect } from 'react';
import { MdAttachMoney } from 'react-icons/md';
import { colors } from 'styles/colors';
import { IModalDisplay } from 'interfaces/ModalDisplay';
import { CURRENCY } from 'constants/environment';
import { useToast } from 'hooks/toast';
import { usePageSettings } from 'hooks/pagesSettings';
import api from 'services/api';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import { ContainerModal, TitleModal, Option, ButtonReturn } from '../styles';

type Key = keyof IMessage<LanguageTranslated>;

interface ModalProps {
  closeModal: (prop: keyof IModalDisplay, action: 'open' | 'close') => void;
}

interface ICurrencies {
  _id: string;
  title: string;
  currency: string;
}

export function Currency({ closeModal }: ModalProps): JSX.Element {
  const { addSuccess, addError } = useToast();
  const [currencies, setCurrencies] = useState<Array<ICurrencies>>(
    [] as Array<ICurrencies>,
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleCloseModal() {
    closeModal('currency', 'close');
  }

  function handleSaveCurrency(currency: string) {
    localStorage.setItem(CURRENCY, currency);
    addSuccess(`${messages[key].message}`);
  }

  useEffect(() => {
    async function getCurrencys() {
      try {
        const response = await api.get('/locale');
        setCurrencies(response.data.result);
      } catch (error) {
        addError('Não foi possível carregar as moedas');
      }
    }
    getCurrencys();
  }, [addError]);

  return (
    <ContainerModal>
      <TitleModal>
        <MdAttachMoney fill={colors.darkGray} size={50} />
        <h2>{messages[key].title}</h2>
        <ButtonReturn size={40} onClick={() => handleCloseModal()} />
      </TitleModal>
      {currencies.map(obj => {
        return (
          <Option
            key={obj._id}
            onClick={() => handleSaveCurrency(obj.currency)}
          >
            <span>
              {obj.title} - {obj.currency}
            </span>
          </Option>
        );
      })}
    </ContainerModal>
  );
}
