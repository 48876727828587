import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  language: string;
  currency: string;
  bankAccount: string;
  occurrences: string;
  privacity: string;
  user: string;
  othersSettings: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Configurações',
    bankAccount: 'Conta bancária',
    currency: 'Moeda',
    language: 'Idioma',
    occurrences: 'Ocorrências',
    privacity: 'Privacidade',
    user: 'Usuário',
    othersSettings: 'Outras configurações',
  },
  'en-US': {
    title: 'Settings',
    bankAccount: 'Bank account',
    currency: 'Currency',
    language: 'Language',
    occurrences: 'Occurrences',
    privacity: 'Privacity',
    user: 'User',
    othersSettings: 'Others settings',
  },
};
