import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  subtitle: string;
  basicPlan: string;
  premiumPlan: string;
  monthly: string;
  benefits: string;
  seal: string;
  accountRelease: string;
  ad: string;
  coupon: string;
  proceed: string;
  text: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Meu plano',
    subtitle: 'Escolha um plano:',
    premiumPlan: 'Plano Premium:',
    basicPlan: 'Plano básico',
    monthly: 'por mês',
    accountRelease: 'Liberação da conta em 24h',
    ad: 'Anuncio com destaque',
    benefits: 'Vantagens:',
    text: ' Todo saldo de mensalidade terá 18% convertido em Suggars Moedas',
    coupon: 'Insira um cupom de desconto',
    proceed: 'Confirmar',
    seal: 'Selo premium',
  },
  'en-US': {
    title: 'My plan',
    subtitle: 'Choose a plan:',
    premiumPlan: 'Premium plan:',
    basicPlan: 'Basic plan:',
    monthly: 'monthly',
    accountRelease: 'Account release within 24 hours',
    ad: 'highlighted ad',
    benefits: 'Benefits:',
    text: 'All monthly balance will have 18% converted into Suggar Currencies',
    coupon: 'Insert discount coupon',
    proceed: 'Confirm',
    seal: 'premium seal',
  },
};
