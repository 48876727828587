import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserCall {
  id: string;
  status: 'online' | 'offline';
  name: string;
  role: 'babby' | 'daddy';
}

export interface ICurrentCall {
  call: string | null;
  from: IUserCall;
  to: IUserCall;
  timestamp: string;
  status: 'pending' | 'accepted' | 'rejected';
  playing: boolean;
  room: string | null;
}

export interface ICallState {
  isCall: boolean;
  usersList: Array<IUserCall> | null;
  isCallComming: boolean;
  currentCall: ICurrentCall | null;
  currentUser: IUserCall | null;
  inProgress: boolean;
}

const initialState: ICallState = {
  isCall: false,
  usersList: null,
  isCallComming: false,
  currentCall: null,
  currentUser: null,
  inProgress: false,
};

export const callSlice = createSlice({
  name: 'call',

  initialState,
  reducers: {
    setCurrentUser: (state, currentUser) => {
      state.currentUser = currentUser.payload;
    },
    startCall: state => {
      state.isCall = true;
    },
    endCall: state => {
      state.isCall = false;
      state.inProgress = false;
    },
    setListUsers: (state, usersList: PayloadAction<Array<IUserCall>>) => {
      state.usersList = usersList.payload;
    },
    setIsCallComming: (state, isCallComming: PayloadAction<boolean>) => {
      state.isCallComming = isCallComming.payload;
    },
    setCurrentCall: (state, currentCall: PayloadAction<ICurrentCall>) => {
      state.currentCall = currentCall.payload;
    },
    setInProgress(state, delta: PayloadAction<boolean>) {
      state.inProgress = delta.payload;
    },
  },
});

export const {
  startCall,
  endCall,
  setListUsers,
  setIsCallComming,
  setCurrentCall,
  setInProgress,
  setCurrentUser,
} = callSlice.actions;

export default callSlice.reducer;
