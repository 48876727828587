import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  spicyPhoto: string;
  concluded: string;
  message: string;
  currency: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Outras configurações',
    spicyPhoto: 'Alterar valor da foto picante',
    concluded: 'Concluído',
    message: 'Saved changes',
    currency: 'R$',
  },
  'en-US': {
    title: 'Others settings',
    spicyPhoto: 'Change spicy photo value',
    concluded: 'Concluded',
    message: 'Saved changes',
    currency: '$',
  },
};
