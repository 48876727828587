import styled from 'styled-components';
import { Button } from 'components/Button';
import Background from 'pages/Auth/_Background';

export const ContainerMain = styled(Background)``;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div``;

export const Title = styled.h3`
  font-size: 1.7rem;
  width: 100%;
  text-align: center;
  color: var(--white);
  font-weight: 400;
  margin-bottom: 15px;
`;

export const Select = styled.select`
  appearance: none;
  position: relative;
  margin-bottom: 1rem;
  border: none;
  width: 100%;
  height: 2.64rem;
  color: var(--light-gray);
  border-bottom: 0.0625rem solid ${props => props.theme.border};
  background-color: var(--blue-regular);
  padding-left: 0.5rem;
  font-size: 1.2rem;

  ::placeholder {
    color: var(--light-gray);
  }

  option {
    color: var(--graphite);
    background-color: var(--light-gray);

    ::selection {
      background-color: black;
    }
  }
`;

export const LanguagesList = styled.ul`
  list-style: none;
  overflow-y: auto;
  max-height: 8rem;
  margin-bottom: 5rem;

  li {
    height: 2rem;
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    padding: 0 0.3rem;

    span {
      color: var(--white);
    }
  }
`;

export const CancelButton = styled(Button)`
  margin-top: 15px;
`;
