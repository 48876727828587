import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)`
  height: auto;
`;

export const Title = styled.h1`
  color: var(--primary);
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 2rem;

  @media (max-width: 650px) {
    font-size: 2.5rem;
  }
`;
export const SubTitle = styled.h1`
  color: var(--primary);
  font-size: 2.7rem;
  font-weight: 300;
  padding-left: 4rem;

  @media (max-width: 650px) {
    font-size: 2.2rem;
  }
`;

export const ContainerImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
`;

export const ContainerAnalyze = styled.div`
  width: 20rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: var(--blue-card);
  margin-bottom: 2rem;
  margin: 2rem auto;
`;

export const ContainerPhrase = styled.div`
  margin: 1rem;
  width: 18rem;
  height: 18rem;
  border-radius: 15px;
  background-color: var(--light-gray);
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0.5rem;

  p {
    text-align: center;
    width: 100%;
    font-size: 1.2rem;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1rem;
`;
