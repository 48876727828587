import { useState, useEffect, useCallback } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { readUploadedFile } from 'helpers/upload';
import { Button } from 'components/Button';
import { useToast } from 'hooks/toast';
import defaultVideo from 'assets/images/img/background_video.png';
import { IoIosReturnLeft } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  Title,
  ContainerImage,
  Label,
  CloseVideo,
  CancelButton,
  ContainerMain,
  Main,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function VideoData(): JSX.Element {
  const [urlVideo, setUrlVideo] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<string>();
  const [uploadFile, setUploadFile] = useState<File>();
  const [inProgress, setInProgress] = useState(false);
  const { addError, addSuccess } = useToast();
  const history = useHistory();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  const removeVideo = () => {
    setUrlVideo(defaultVideo);
  };

  useEffect(() => {
    const handleProcess = async () => {
      try {
        if (uploadFile) {
          const promise = await readUploadedFile(uploadFile);
          setSelectedFile(promise);
        }
        addSuccess('Imagem carregada com sucesso!!');
      } catch (err) {
        addError(err);
      } finally {
        setInProgress(false);
      }
    };

    if (uploadFile) handleProcess();
  }, [uploadFile, addError, addSuccess]);

  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (e.target.files) {
        const { files } = e.target;
        setUploadFile(files[0]);
        setInProgress(true);
      }
    },
    [],
  );
  useEffect(() => {
    if (selectedFile) setUrlVideo(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    setUrlVideo(defaultVideo);
  }, []);

  function handleSandForm() {
    if (selectedFile?.length) {
      addSuccess(`${messages[key].successMessage}`);
      history.push('documentos');
    } else addError(`${messages[key].errorMessage}`);
  }

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <Title>{messages[key].text}</Title>
            <ContainerImage theme={{ background: { urlVideo } }}>
              {urlVideo === defaultVideo ? (
                <>
                  <img src={urlVideo} alt="" />
                  <Label htmlFor="video" />
                  <input
                    name="video"
                    type="file"
                    id="video"
                    accept="video/*"
                    multiple
                    onChange={e => handleImageChange(e)}
                  />
                </>
              ) : (
                <>
                  <CloseVideo
                    fill="var(--remove)"
                    onClick={() => removeVideo()}
                  />
                  <video width="320" height="240" controls>
                    <source src={urlVideo} type="video/mp4" />
                    <track
                      default
                      kind="subtitles"
                      srcLang="pt-br"
                      label="Portuguese"
                    />
                    <track
                      default
                      kind="captions"
                      srcLang="pt-br"
                      label="Portuguese"
                    />
                  </video>
                </>
              )}
            </ContainerImage>
            <Button
              backgroundColor="primary"
              loading={inProgress}
              size="regular"
              onClick={() => handleSandForm()}
            >
              Prosseguir
            </Button>
            <CancelButton backgroundColor="secondary" size="small">
              Cancelar
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
