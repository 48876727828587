import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  sendMessage: string;
  statusActive: string;
  statusInactive: string;
  seeSpicyImage: string;
  viewVideo: string;
  removeDaddy: string;
  age: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    sendMessage: 'Enviar mensagem',
    statusActive: 'Ativo',
    statusInactive: 'Inativo',
    seeSpicyImage: 'Ver foto picante',
    viewVideo: 'Ver vídeo',
    removeDaddy: 'Remover daddy',
    age: 'anos',
  },
  'en-US': {
    sendMessage: 'Send message',
    statusActive: 'Active',
    statusInactive: 'Inactive',
    seeSpicyImage: 'See spicy photo',
    viewVideo: 'View video',
    removeDaddy: 'Remove daddy',
    age: 'years',
  },
};
