import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  subtitle: string;
  text: string;
  value: string;
  currency: string;
  proceed: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Crédito virtual',
    subtitle: 'Quantas Suggar moedas o senhor deseja?',
    currency: 'R$',
    text: 'Valor mínimo 50 e valor máximo 2000',
    value: 'Valor a pagar:',
    proceed: 'Confirmar',
  },
  'en-US': {
    title: 'Virtual credit',
    subtitle: 'How many coin Sugars do you want?',
    currency: '$',
    text: 'Minimum value 50 and maximum value 2000 ',
    value: 'Amount to pay:',
    proceed: 'Confirm',
  },
};
