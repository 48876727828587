import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  country: string;
  cep: string;
  neighborhood: string;
  state: string;
  city: string;
  street: string;
  proceed: string;
  cancel: string;
  successMessage: string;
  errorMessage: string;
  selectCountry: string;
  selectState: string;
  selectCity: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    country: 'País',
    cep: 'CEP',
    neighborhood: 'Bairro',
    state: 'Estado',
    city: 'Cidade',
    street: 'Logradouro',
    proceed: 'Prosseguir',
    cancel: 'Cancelar',
    errorMessage: 'Não foi possível validar os dados',
    successMessage: 'Dados validados',
    selectCountry: 'Selecione um país',
    selectState: 'Selecione um estado',
    selectCity: 'Selecione uma cidade',
  },
  'en-US': {
    country: 'Country',
    cep: 'Posta Code',
    neighborhood: 'Neighborhood',
    state: 'State',
    city: 'City',
    street: 'Street',
    proceed: 'Proceed',
    cancel: 'Cancel',
    errorMessage: 'It was not possible to validate the fields',
    successMessage: 'Validated fields',
    selectCountry: 'Select a country',
    selectState: 'Select a state',
    selectCity: 'Select a city',
  },
};
