import styled from 'styled-components';
import { Button } from 'components/Button';

export const Container = styled.div`
  width: 20rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: var(--blue-card);
  margin-bottom: 2rem;
`;

export const Image = styled.div`
  margin: 1rem;
  width: 18rem;
  height: 18rem;
  border-radius: 15px;
  background-color: var(--light-gray);
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const ButtonProfile = styled(Button)`
  width: 45%;
`;
