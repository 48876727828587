import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  signOut: string;
  currency: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    signOut: 'Sair',
    currency: 'R$',
  },
  'en-US': {
    signOut: 'Sign out',
    currency: '$',
  },
};
