import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  title: string;
  portuguese: string;
  englishUs: string;
  message: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Idioma',
    englishUs: 'United States - English',
    portuguese: 'Português brasileiro',
    message: 'Idioma alterado',
  },
  'en-US': {
    title: 'Language',
    englishUs: 'United States - English',
    portuguese: 'Brazilian portuguese',
    message: 'Changed language',
  },
};
