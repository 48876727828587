import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';
import { Button } from 'components/Button';

export const Container = styled(DefaultPage)``;

export const Title = styled.span`
  color: var(--primary);
  font-size: 2rem;
  font-weight: 300;

  @media (max-width: 650px) {
    font-size: 1.8rem;
  }
`;

export const UserList = styled.div`
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  margin: 2rem auto;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 36rem);
  margin-top: 5rem;

  @media (max-width: 1798px) {
    grid-template-columns: repeat(auto-fit, 36rem);
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 530px) {
    grid-template-columns: repeat(auto-fit, 25rem);
  }

  @media (max-width: 430px) {
    grid-template-columns: repeat(auto-fit, 20rem);
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const DaddyCard = styled.div`
  border-radius: 1rem;
  border: solid 2px var(--regular-gray);
  background-color: var(--blue-card);
  display: flex;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  height: 10rem;
  width: 60rem;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media (max-width: 1150px) {
    width: 50rem;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    height: auto;
    align-items: center;
    width: 30rem;
  }

  @media (max-width: 530px) {
    width: 25rem;
  }
  @media (max-width: 430px) {
    width: 20rem;
  }
`;

export const ContainerPerfilDaddy = styled.div`
  display: flex;
  @media (max-width: 900px) {
    width: 100%;
    padding-top: 2rem;
    padding-left: 2rem;
  }
  @media (max-width: 400px) {
    width: 100%;
    padding-top: 1rem;
    padding-left: 0rem;
  }
`;

export const ImageCard = styled.div`
  width: 8.5rem;
  height: 8.5rem;
  background-color: var(--white);
  border: solid 2px var(--regular-gray);
  border-radius: 50%;
`;

export const DaddyInformation = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: var(--white);
    margin-left: 1rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-style: italic;

    :first-child {
      font-size: 1.7rem;
      line-height: 2.5rem;
      font-style: normal;
    }
  }
`;

export const ButtonSendMessage = styled(Button)`
  width: 15rem;
  align-self: flex-end;
  margin-bottom: 0;

  @media (max-width: 900px) {
    align-self: center;
    margin-top: 3rem;
  }
`;

export const ContainerButtomStatus = styled.div`
  align-self: flex-end;
  justify-self: flex-end;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

export const ButtonRemove = styled.button`
  background-color: var(--remove);
  color: var(--white);
  padding: 0.7rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.9rem 0 0 0.9rem;

  @media (max-width: 900px) {
    border-radius: 1rem;
    width: 50%;
    align-self: center;
  }
  @media (max-width: 500px) {
    width: 70%;
  }
`;

export const Status = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  color: var(--white);
  padding: 0.7rem;
  align-self: flex-end;
  justify-self: flex-end;
  border-radius: 1rem 0 1rem 0;
  font-size: 1rem;
  width: 10rem;
  text-align: center;
  right: 0;

  @media (max-width: 900px) {
    margin-top: 2rem;
  }
`;
