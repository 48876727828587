import styled from 'styled-components';
import { Input } from 'components/Input';

export const SpicyPhotoValueInput = styled(Input)`
  font-size: 2rem;
  text-align: center;
`;

export const ContainerSpicyPhotoValue = styled.div`
  font-size: 2rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const ModalBigText = styled.p`
  font-size: 2rem;
  color: var(--dark-gray);
  width: 70%;
  text-align: center;
`;
