import styled from 'styled-components';
import { Button } from 'components/Button';
import Background from 'pages/Auth/_Background';

export const ContainerMain = styled(Background)``;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div``;
export const DivRadio = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  height: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 13%;
  justify-content: space-between;
  label {
    font-size: 1.2rem;
    color: var(--gray);
    margin-left: 15px;
  }
`;

export const CancelButton = styled(Button)`
  margin-top: 15px;
`;
