import { useState, useEffect, useCallback } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { readUploadedFile } from 'helpers/upload';
import { useToast } from 'hooks/toast';
import { IoIosReturnLeft } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  Title,
  ContentInput,
  Clip,
  Alert,
  ImportFile,
  CancelButton,
  ProceedButton,
  TitleProof,
  ContainerMain,
  Main,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IDocumentsProps {
  document: File | null;
  controlUpload: boolean;
}

interface IDocuments {
  cpf: IDocumentsProps;
  documentWithPhoto: IDocumentsProps;
  proofAdress: IDocumentsProps;
  proofIncome: IDocumentsProps;
}

interface ISelectedFiles {
  base64: string;
}

interface IFilesUploaded {
  cpf: ISelectedFiles;
  documentWithPhoto: ISelectedFiles;
  proofAdress: ISelectedFiles;
  proofIncome: ISelectedFiles;
}

export function DocumentsData(): JSX.Element {
  const [documents, setDocuments] = useState<IDocuments>({} as IDocuments);
  const [selectedFiles, setSelectedFiles] = useState<IFilesUploaded>(
    {} as IFilesUploaded,
  );
  const { addSuccess, addError } = useToast();
  const history = useHistory();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setDocuments({
      cpf: {
        document: null,
        controlUpload: false,
      },
      documentWithPhoto: {
        document: null,
        controlUpload: false,
      },
      proofAdress: {
        document: null,
        controlUpload: false,
      },
      proofIncome: {
        document: null,
        controlUpload: false,
      },
    });

    setSelectedFiles({
      cpf: {
        base64: '',
      },
      documentWithPhoto: {
        base64: '',
      },
      proofAdress: {
        base64: '',
      },
      proofIncome: {
        base64: '',
      },
    });
  }, []);

  useEffect(() => {
    const handleProcess = async () => {
      try {
        if (documents.cpf?.document && documents.cpf?.controlUpload) {
          const base64 = await readUploadedFile(documents.cpf?.document);
          setSelectedFiles({ ...selectedFiles, cpf: { base64 } });
          setDocuments({
            ...documents,
            cpf: {
              document: documents.cpf?.document,
              controlUpload: false,
            },
          });
        }
        if (
          documents.documentWithPhoto?.document &&
          documents.documentWithPhoto?.controlUpload
        ) {
          const base64 = await readUploadedFile(
            documents.documentWithPhoto?.document,
          );
          setSelectedFiles({ ...selectedFiles, documentWithPhoto: { base64 } });
          setDocuments({
            ...documents,
            documentWithPhoto: {
              document: documents.documentWithPhoto?.document,
              controlUpload: false,
            },
          });
        }
        if (
          documents.proofAdress?.document &&
          documents.proofAdress?.controlUpload
        ) {
          const base64 = await readUploadedFile(
            documents.proofAdress?.document,
          );
          setSelectedFiles({ ...selectedFiles, proofAdress: { base64 } });
          setDocuments({
            ...documents,
            proofAdress: {
              document: documents.proofAdress?.document,
              controlUpload: false,
            },
          });
        }
        if (
          documents.proofIncome?.document &&
          documents.proofIncome?.controlUpload
        ) {
          const base64 = await readUploadedFile(
            documents.proofIncome?.document,
          );
          setSelectedFiles({ ...selectedFiles, proofIncome: { base64 } });
          setDocuments({
            ...documents,
            proofIncome: {
              document: documents.proofIncome?.document,
              controlUpload: false,
            },
          });
        }
      } catch (err) {
        addError('Erro ao preocessar os arquivos');
      }
    };
    if (documents) handleProcess();
  }, [addError, addSuccess, selectedFiles, documents]);

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, prop: keyof IDocuments): void => {
      if (e.target.files) {
        const { files } = e.target;
        const file = files[0];

        setDocuments({
          ...documents,
          [prop]: { document: file, display: true },
        });
      }
    },
    [documents],
  );

  function handleSandForm() {
    if (
      documents.cpf.document &&
      documents.documentWithPhoto.document &&
      documents.proofAdress
    ) {
      addSuccess(`${messages[key].successMessage}`);
      history.push('/');
    } else addError(`${messages[key].errorMessage}`);
  }

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <Title>{messages[key].text} </Title>
            <ContentInput theme={{ display: 'flex' }}>
              <ImportFile>
                <label htmlFor="fileCpf">
                  {documents.cpf?.document
                    ? documents.cpf.document?.name
                    : messages[key].cpf}
                </label>
                <input
                  name="fileCpf"
                  type="file"
                  id="fileCpf"
                  onChange={e => handleFileChange(e, 'cpf')}
                />
              </ImportFile>
              <Clip />
            </ContentInput>
            <ContentInput
              theme={{
                display: documents.cpf?.document ? 'flex' : 'none',
              }}
            >
              <ImportFile>
                <label htmlFor="documentPhoto">
                  {documents.documentWithPhoto?.document
                    ? documents.documentWithPhoto.document?.name
                    : messages[key].documentWithPhoto}
                </label>
                <input
                  name="documentPhoto"
                  type="file"
                  id="documentPhoto"
                  onChange={e => handleFileChange(e, 'documentWithPhoto')}
                />
              </ImportFile>
              <Clip />
            </ContentInput>
            <TitleProof
              theme={{
                display: documents.documentWithPhoto?.document
                  ? 'block'
                  : 'none',
              }}
            >
              {messages[key].textProofs}{' '}
            </TitleProof>
            <ContentInput
              theme={{
                display: documents.documentWithPhoto?.document
                  ? 'block'
                  : 'none',
              }}
            >
              <ImportFile>
                <label htmlFor="voucherAddress">
                  {documents.proofAdress?.document
                    ? documents.proofAdress.document?.name
                    : messages[key].adressProof}
                </label>
                <input
                  name="voucherAddress"
                  type="file"
                  id="voucherAddress"
                  onChange={e => handleFileChange(e, 'proofAdress')}
                />
                <Clip />
              </ImportFile>
            </ContentInput>
            <ContentInput
              theme={{
                display:
                  documents.proofAdress?.document !== null ? 'flex' : 'none',
              }}
            >
              <ImportFile>
                <label htmlFor="voucherIncome">
                  {documents.proofIncome?.document
                    ? documents.proofIncome.document?.name
                    : messages[key].incomeProof}
                </label>
                <input
                  name="voucherIncome"
                  type="file"
                  id="voucherIncome"
                  onChange={e => handleFileChange(e, 'proofIncome')}
                />
              </ImportFile>
              <Clip />
            </ContentInput>
            <Alert>{messages[key].requiredItens}</Alert>
            <ProceedButton
              backgroundColor="primary"
              size="regular"
              theme={{
                display:
                  documents.proofAdress?.document !== null ? 'block' : 'none',
              }}
              onClick={() => handleSandForm()}
            >
              {messages[key].proceed}
            </ProceedButton>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
