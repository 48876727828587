import styled from 'styled-components';
import Background from 'pages/Auth/_Background';

export const Container = styled(Background)``;

export const Content = styled.div`
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  margin-top: 10%;
  @media (max-height: 675px) {
    margin-top: 5%;
  }
  @media (max-height: 600px) {
    margin-top: 0%;
  }
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const SoonContent = styled.div`
  display: flex;
  flex: 0.5 1 400px;
  min-height: 20vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 500px;
  div.earth-logo {
    display: flex;
    justify-content: center;
    padding: 4rem;
  }
`;

export const Soon = styled.h1`
  color: #fff;
  padding-bottom: 10px;
`;

export const Slogan = styled.h3`
  color: #fff;
  z-index: 99999999999;
`;

export const LoginContent = styled.div`
  label {
    margin-left: 10px;
    margin-bottom: 3px;
    display: block;
    font-size: 1.2rem;
  }

  h2 {
    font-weight: normal;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  div.buttons-container {
    margin-top: 25px;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    margin: 1.5rem 0 0.8rem;
    font-size: 0.9rem;
    font-weight: thin;
    text-align: center;
  }
`;
