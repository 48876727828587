import { useEffect, useState } from 'react';
import { Input } from 'components/Input';
import { useToast } from 'hooks/toast';
import { Button } from 'components/Button';
import { usePageSettings } from 'hooks/pagesSettings';
import { IMessage } from 'interfaces/pagesSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  Title,
  Content,
  Text,
  ContainerValue,
  OverlaySuccess,
  ContainerButton,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function RedemptionRequest(): JSX.Element {
  const [value, setValue] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const { addError } = useToast();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleRequestRedemption() {
    if (Number(value) < 500) {
      addError(`${messages[key].error}`);
      return;
    }
    setShowOverlay(true);
  }

  return (
    <Container>
      <Title>{messages[key].title}</Title>
      <Content>
        <Text>{messages[key].limit}</Text>
        <ContainerValue>
          <div className="containerInput">
            <Input
              name="value"
              type="currency"
              prefix="R$"
              placeholder={messages[key].placeholder}
              value={value}
              onValueChange={v => {
                if (v) {
                  setValue(v);
                } else {
                  setValue('');
                }
              }}
            />
          </div>
          <span>{messages[key].minValue}</span>
        </ContainerValue>
        <ContainerButton>
          <Button size="regular" onClick={() => handleRequestRedemption()}>
            {messages[key].request}
          </Button>
        </ContainerButton>
      </Content>
      {showOverlay && (
        <OverlaySuccess>
          <span>{messages[key].success}</span>
          <Button size="regular" onClick={() => setShowOverlay(false)}>
            OK
          </Button>
        </OverlaySuccess>
      )}
    </Container>
  );
}
