import { VideoCallSocket } from 'components/VideoCallSocket';
import { AppProvider } from 'hooks';
import { Routes } from './routes';
import { GlobalStyles } from './styles/global';

export function App(): JSX.Element {
  return (
    <>
      <AppProvider>
        <VideoCallSocket>
          <Routes />
        </VideoCallSocket>
      </AppProvider>
      <GlobalStyles />
    </>
  );
}
