import { useState, useEffect } from 'react';
import { ContainerSignUp } from 'components/ContainerSignUp';
import { Button } from 'components/Button';
import { IoIosReturnLeft } from 'react-icons/io';
import { MdDeleteForever } from 'react-icons/md';
import { useToast } from 'hooks/toast';
import { colors } from 'styles/colors';
import { useHistory } from 'react-router-dom';
import { usePageSettings } from 'hooks/pagesSettings';

import { IMessage } from 'interfaces/pagesSettings';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setLanguagesData } from 'store/slices/signupSlice';
import { messages, LanguageTranslated } from './translate';

import {
  Content,
  Title,
  Select,
  CancelButton,
  LanguagesList,
  ContainerMain,
  Main,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

export function LanguagesData(): JSX.Element {
  const [languages, setLanguages] = useState<Array<string>>([]);
  const [userLanguages, setUserLanguages] = useState<Array<string>>([]);
  const { addError, addSuccess } = useToast();
  const history = useHistory();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const signup = useAppSelector(state => state.signup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setUserLanguages(signup.metadata.languages);
  }, [signup]);

  useEffect(() => {
    setLanguages(['Português', 'Inglês', 'Espanhol', 'Francês', 'Alemão']);
  }, []);

  function handleRemoveUserLanguage(value: string): void {
    setUserLanguages(userLanguages.filter(obj => obj !== value));
  }

  function sendForm() {
    if (!userLanguages.length) {
      addError(`${messages[key].errorMessage}`);
    } else {
      addSuccess(`${messages[key].successMessage}`);
      dispatch(setLanguagesData(userLanguages));
      history.push('caracteristicas-pessoais');
    }
  }

  return (
    <ContainerMain>
      <Main>
        <ContainerSignUp>
          <Content>
            <IoIosReturnLeft
              fill="#fff"
              size={30}
              style={{ marginLeft: '90%', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />
            <Title>{messages[key].languages}</Title>
            <Select
              name="languages"
              placeholder="Selecione as linguagens"
              theme={{
                border: userLanguages.length ? colors.lightGray : colors.danger,
              }}
              onChange={e => {
                if (userLanguages.indexOf(e.target.value) === -1)
                  setUserLanguages([...userLanguages, e.target.value]);
              }}
            >
              {languages.map(userLanguage => {
                return <option value={userLanguage}>{userLanguage}</option>;
              })}
            </Select>

            <LanguagesList>
              {userLanguages.map(userLanguage => {
                return (
                  <li>
                    <span>{userLanguage}</span>
                    <MdDeleteForever
                      fill="#fff"
                      size={25}
                      cursor="pointer"
                      onClick={() => handleRemoveUserLanguage(userLanguage)}
                    />
                  </li>
                );
              })}
            </LanguagesList>

            <Button
              backgroundColor="primary"
              size="regular"
              onClick={() => sendForm()}
            >
              {messages[key].proceed}
            </Button>
            <CancelButton backgroundColor="secondary" size="small">
              {messages[key].cancel}
            </CancelButton>
          </Content>
        </ContainerSignUp>
      </Main>
    </ContainerMain>
  );
}
