import styled from 'styled-components';

export const ContainerUserInputs = styled.div`
  background-color: var(--graphite);
  border: solid 2px var(--dark-gray);
  border-radius: 25px;
  padding: 2rem;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-y: scroll;

  @media (max-width: 1800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 950px) {
    height: 25rem;
  }

  @media (max-height: 815px) {
    height: 20rem;
  }
  @media (max-height: 740px) {
    height: 15rem;
  }

  @media (max-width: 650px) {
    width: 90%;
    padding: 2rem 0.5rem;
  }
`;

export const Select = styled.select`
  appearance: none;
  position: relative;
  margin-bottom: 1rem;
  border: none;
  width: 100%;
  height: 2.64rem;
  color: var(--light-gray);
  border-bottom: 0.0625rem solid ${props => props.theme.border};
  background-color: var(--graphite);
  padding-left: 0.5rem;
  font-size: 1.2rem;

  ::placeholder {
    color: var(--light-gray);
  }

  option {
    color: var(--graphite);
    background-color: var(--light-gray);

    ::selection {
      background-color: black;
    }
  }
`;

export const ModalUserContainerInput = styled.div`
  display: flex;
  align-items: center;
  width: 25rem;

  @media (max-width: 1800px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 400px) {
    width: 15rem;
  }

  span {
    font-size: 1.5rem;
    color: var(--dark-gray);
    margin-right: 1rem;
    width: 25rem;

    @media (max-width: 1200px) {
      width: 15rem;
    }
  }
`;
