import { IMessage } from 'interfaces/pagesSettings';

export interface LanguageTranslated {
  balance: string;
  buyCoins: string;
  minValue: string;
  paymentMethods: string;
  creditCard: string;
  ticket: string;
  lowBalance: string;
  finallyCall: string;
  cantOpenWebcam: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    balance: 'Saldo: ',
    paymentMethods: 'Opções de pagamento',
    minValue: 'Valor mínimo 50 e valor máximo 2000',
    buyCoins: 'Comprar Suggar moedas',
    ticket: 'Boleto',
    creditCard: 'Cartão de crédito',
    lowBalance:
      'Seu saldo está baixo. Compre mais Suggar moedas para que a chamada não seja finalizada repentinamente.',
    finallyCall: 'Sua chamada será finalizada por falta de saldo',
    cantOpenWebcam: 'Não foi possível abrir a webcam',
  },
  'en-US': {
    balance: 'Balance:',
    paymentMethods: 'Payment methods',
    minValue: 'minumum value 50 and maximum value 2000',
    buyCoins: 'Buy Suggar coins',
    ticket: 'ticket',
    creditCard: 'Credit card',
    lowBalance: `Your balance is low. Buy more Suggar coins so the call doesn't end suddenly.`,
    finallyCall: 'Your call will be terminated for lack of balance',
    cantOpenWebcam: 'could not open the webcam',
  },
};
