import { useState, createContext, useContext, useEffect } from 'react';
import { LANGUAGE, CURRENCY } from 'constants/environment';

interface PageSettingsContextData {
  language: string;
  currency: string;
  handleSetLanguage: (language: string) => void;
  handleSetCurrency: (currency: string) => void;
}

interface IPageSettingsProviderProps {
  children: JSX.Element | JSX.Element[];
}

const PageSettingsContext = createContext({} as PageSettingsContextData);

const PageSettingsProvider = ({
  children,
}: IPageSettingsProviderProps): JSX.Element => {
  const [language, setLanguage] = useState('pt-BR');
  const [currency, setCurrency] = useState('BRL');

  useEffect(() => {
    const languageDefault = localStorage.getItem(LANGUAGE);
    const currencyDefault = localStorage.getItem(CURRENCY);
    if (languageDefault) {
      setLanguage(languageDefault);
      document.documentElement.lang = languageDefault === 'pt-BR' ? 'pt' : 'en';
    } else {
      localStorage.setItem(LANGUAGE, 'pt-BR');
      document.documentElement.lang = 'pt';
    }
    if (currencyDefault) setCurrency(currencyDefault);
  }, []);

  function handleSetLanguage(userLanguage: string) {
    setLanguage(userLanguage);
    document.documentElement.lang = userLanguage === 'pt-BR' ? 'pt' : 'en';
    localStorage.setItem(LANGUAGE, userLanguage);
  }

  function handleSetCurrency(userCurrency: string) {
    setCurrency(userCurrency);
    localStorage.setItem(CURRENCY, userCurrency);
  }

  return (
    <PageSettingsContext.Provider
      value={{
        language,
        currency,
        handleSetLanguage,
        handleSetCurrency,
      }}
    >
      {children}
    </PageSettingsContext.Provider>
  );
};

function usePageSettings(): PageSettingsContextData {
  const context = useContext(PageSettingsContext);

  if (!context) {
    throw new Error(
      'UsePageSettings must be used within a PageSettingsProvider',
    );
  }

  return context;
}

export { PageSettingsProvider, usePageSettings };
